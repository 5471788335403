.country-picker-dropdown {
    background-color: #eeeeee;
    border: 2px solid #eeeeee;
    height: 45px;
}

.country-select {
    width: 100%;
}

.passwordError {
    font: normal normal normal 12px/14px var(--fontArial);
    color: var(--clr-danger);
    margin: 5px 0 0 10px;
}

.input-cancel-cta {
    font: normal normal normal 12px/12px var(--fontArial);
    color: var(--clr-red-98);
    border: none;
    background-color: transparent;
    position: absolute;
    top: 9px;
    right: 25px;
}

/* Login screen UI  */
.loginView--container {
    /* background-image: url("../assets/images/Auth/auth-bg.png"); */
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    width: 100%;
    height: 94vh;
    min-height: 700px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.loginView-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.loginView-wrapper .title {
    font: normal normal 600 24px/34px var(--fontArial);
    color: var(--clr-blue);
}
.loginView-wrapper .title span {
    color: var(--white-fc);
}

.loginView-wrapper .credentials-wrapper {
    height: 100%;
    /* padding: 20px 0 0px 50px; */
}

.loginView-wrapper .credentials-wrapper-inner {
    padding: 0px 50px 0;
    height: 100%;
    background: var(--grey-ee);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    /* border-radius: 400px 0px 0px 20px; */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.loginView-wrapper .credentials-content {
    max-width: 450px;
    margin: 0 auto;
    width: 100%;
}

.loginView-wrapper .credentials-content form {
    width: 100%;
}

.credentials-content .forget-password a {
    font: normal normal 600 14px/22px var(--fontArial);
    color: var(--greyc-32);
}

.credentials-content .field-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 14px;
}

.credentials-content .field-group label {
    font: normal normal normal 15px/18px var(--fontArial);
    color: var(--clr-blue);
    margin-bottom: 5px;
}

.credentials-content .field-group input {
    font: normal normal normal 14px/20px var(--fontArial);
    width: 100%;
    color: var(--clr-blue);
    border: 1px solid var(--grey-99);
    border-radius: 5px;
    outline: none;
    padding: 6px 16px;
    height: 40px;
    background-color: var(--grey-ee);
}

.credentials-content .field-group input:focus {
    background-color: var(--blue-d9);
    outline: 2px solid var(--clr-blue);
    border: none;
}

.credentials-content .field-group input.error:focus {
    background-color: var(--blue-d9);
    outline: 2px solid var(--clr-danger);
    border: none;
}

/* .credentials-content .field-group input::placeholder {
      color: rgba(153, 155, 158, 0.8);
      opacity: 1;
  } */
.credentials-content .login-btn,
.credentials-content .login-btn:hover {
    font: normal normal normal 14px/18px var(--fontArial);
    -webkit-box-shadow: -2px 4px 10px #105d8d33;
    box-shadow: -2px 4px 10px #105d8d33;
    background: var(--clr-red-98);
    color: var(--white-fff);
    border-radius: 100px;
    height: 45px;
    width: 100%;
    margin: 5px 0;
}

.credentials-content .login-btn:focus {
    -webkit-box-shadow: 0px 0px 30px -15px rgba(238, 238, 238, 0.4);
    box-shadow: 0px 0px 30px -15px rgba(238, 238, 238, 0.4);
}

.login-forget-cta {
    font: normal normal 600 14px/18px var(--fontArial);
    color: var(--clr-blue);
    border: none;
    background-color: transparent;
    margin-bottom: 8px;
    padding: 0;
    -webkit-text-decoration: underline 2px var(--clr-blue);
    text-decoration: underline 2px var(--clr-blue);
}

.login-forget-cta:hover {
    color: var(--clr-red-98);
    -webkit-text-decoration: underline 2px var(--clr-red-98);
    text-decoration: underline 2px var(--clr-red-98);
}

.loginView-wrapper .or-seperator p::after {
    content: "";
    display: block;
    position: absolute;
    width: calc(100% - 245px);
    height: 1px;
    background-color: var(--grey-99);
    top: 12px;
    right: 0px;
}

.loginView-wrapper .or-seperator p::before {
    content: "";
    display: block;
    position: absolute;
    width: calc(100% - 245px);
    height: 1px;
    background-color: var(--grey-99);
    top: 12px;
    left: 0px;
}

.or-seperator p {
    font: normal normal normal 16px/20px var(--fontArial);
    color: var(--grey-99);
    text-align: center;
    margin-bottom: 20px;
}

.login-cta-outline {
    width: 100%;
    border: 2px solid var(--clr-red-98) !important;
    border-radius: 50px !important;
    height: 45px;
    margin: 5px 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.credentials-content .platform-wrapper button {
    font: normal normal normal 16px/20px var(--fontArial);
    width: 100%;
    margin-bottom: 10px;
    border-radius: 100px;
    border: 1px solid var(--clr-blue);
}
.credentials-content .platform-wrapper button:hover {
    background-color: var(--clr-blue) !important;
    color: var(--white-fc) !important;
}
.credentials-content .platform-wrapper button svg path {
    padding-right: 5px;
}
.credentials-content .platform-wrapper button:hover svg path {
    fill: var(--white-fc);
}

.credentials-content .platform-wrapper .googleBtnWrapper .btn {
    font: normal normal normal 16px/20px var(--fontArial);
    width: 100%;
    margin-bottom: 10px;
    border-radius: 100px;
    border: 1px solid var(--clr-blue);
}
.credentials-content .platform-wrapper .googleBtnWrapper:hover .btn {
    font: normal normal normal 16px/20px var(--fontArial);
    width: 100%;
    margin-bottom: 10px;
    border-radius: 100px;
    border: 1px solid var(--clr-blue);
    background-color: var(--clr-blue) !important;
    color: var(--white-fc) !important;
}
.credentials-content .platform-wrapper .googleBtnWrapper .btn svg path {
    padding-right: 5px;
}
.credentials-content .platform-wrapper .googleBtnWrapper:hover .btn svg path {
    fill: var(--white-fc);
}

.loginView-wrapper .signUp-content {
    background-image: linear-gradient(
            to right bottom,
            rgba(235, 245, 252, 0.3),
            rgba(235, 245, 252, 0.3)
        ),
        url("../assets/images/Auth/login-hover.png");
    /* #CDCFD5 */
    background-position: 90%;
    background-size: cover;
    height: 100%;
    padding: 0 !important;
}

/* .loginView-wrapper .signUp-content::after {
      content: "";
      background-image: url("../assets/images/Auth/auth-bg.png");
      background-size: cover;
      background-position: right;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transition: 0.6s ease-out all;
  }
  
  .loginView-wrapper .signUp-content:hover::after {
      width: 0;
  }
  .loginView-wrapper .signUp-content:hover {
      background-position: 72% 0;
  } */

.loginView-wrapper .signUp-content-inner {
    position: relative;
    z-index: 1;
    padding: 5px 30px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 25%;
    background: rgba(238, 238, 238, 0.4);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(10px);
    border-top: 1px solid var(--grey-d6);
    border-bottom: 1px solid var(--grey-d6);

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translate(0%, -50%);
}

.loginView-wrapper .signUp-content p {
    text-align: left;
    font: normal normal normal 14px/18px var(--fontArial);
    color: var(--greyc-32);
}

.loginView-wrapper .signUp-content p span {
    font-weight: bold;
}

.loginView-wrapper .signUp-content .sign-up-cta {
    width: 100%;
    border: 2px solid var(--clr-blue);
    border-radius: 100px;
    text-align: center;
    margin-top: 10px;
    max-width: 350px;
}
.loginView-wrapper .signUp-content .sign-up-cta:hover {
    background-color: var(--clr-blue);
}
.loginView-wrapper .signUp-content .sign-up-cta a {
    font: normal normal normal 14px/18px var(--fontArial);
    color: var(--clr-blue);
    width: 100%;
    display: block;
    padding: 10px;
}
.loginView-wrapper .signUp-content .sign-up-cta:hover a {
    color: var(--white-fc);
}

.no-account-text-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 0 3px;
}

.no-account-text-wrapper .pos-rel {
    font-weight: 600 !important;
}

.curved-border {
    position: absolute;
    top: 25px;
    right: 8px;
    width: 42px;
}

/*  SignUp  screen UI */
.signinView--container {
    background-color: var(--grey-ee);
    width: 100%;
    height: 955px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.signinView-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.signinView-wrapper .credentials-wrapper .header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 45px;
    margin-bottom: 20px;
}

.signinView-wrapper .title {
    font: normal normal 700 24px/28px var(--fontArial);
    color: var(--clr-blue);
    margin-top: 0;
}

.signinView-wrapper .credentials-wrapper .header p {
    font: normal normal normal 12px/14px var(--fontArial);
    background-color: var(--blue-d9);
    display: block;
    border-radius: 20px;
    padding: 10px 15px;
}

.signinView-wrapper .subtitle {
    font: normal normal normal 14px/16px var(--fontArial);
    color: var(--greyc-32);
    margin: 8px auto 20px;
}

.signinView-wrapper .credentials-wrapper {
    height: 100%;
    /* padding: 30px 50px 0px 0; */
}

.signinView-wrapper .credentials-wrapper-inner {
    height: 100%;
    /* border-left: 1px solid var(--white-fff); */
    /* background: rgba(238, 238, 238, 0.4); */
    /* backdrop-filter: blur(5px); */
    /* -webkit-backdrop-filter: blur(5px); */
    /* border-radius: 0px 500px 20px 0px; */
}

.signinView-wrapper .credentials-content {
    max-width: 430px;
    margin: 0 auto;
    padding: 20px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
}

.signinView-wrapper .credentials-content form {
    width: 100%;
}

.credentials-content .forget-password a {
    font: normal normal normal 14px/22px var(--fontArial);
    color: var(--greyc-32);
}

.credentials-content .login-btn:disabled {
    opacity: 0.5;
    color: var(--grey-d6);
}

.signinView-wrapper .signUp-content {
    background-image: url("../assets/images/Auth/signin-hover.png");
    background-position: 0;
    background-size: cover;
    height: 100%;
    padding: 0 !important;
    -webkit-transition: 0.6s 0.05s ease-out all;
    -o-transition: 0.6s 0.05s ease-out all;
    transition: 0.6s 0.05s ease-out all;
}

/* .signinView-wrapper .signUp-content::after {
      content: "";
      background-image: url("../assets/images/Auth/auth-bg.png");
      background-size: cover;
      background-position: left;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      transition: 0.6s ease-out all;
  }
  .signinView-wrapper .signUp-content:hover::after {
      width: 0;
  }
  .signinView-wrapper .signUp-content:hover {
      background-position: 10%;
  } */

.signinView-wrapper .signUp-content-inner {
    position: relative;
    z-index: 1;
    height: 20%;
    background: rgba(238, 238, 238, 0.4);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 4px 30px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translate(0%, -50%);
}

.signinView-wrapper .signUp-content p {
    font: normal normal normal 14px/18px var(--fontArial);
    color: var(--greyc-32);
}

.signinView-wrapper .signUp-content p span {
    font-weight: bold;
}

.signinView-wrapper .signUp-content .sign-up-cta {
    width: 100%;
    border: 2px solid var(--clr-blue);
    border-radius: 100px;
    text-align: center;
    margin-top: 15px;
}

.signinView-wrapper .signUp-content .sign-up-cta:hover {
    background-color: var(--clr-blue);
}

.signinView-wrapper .signUp-content .sign-up-cta a {
    font: normal normal normal 14px/18px var(--fontArial);
    color: var(--clr-blue);
    width: 100%;
    display: block;
    padding: 10px;
}
.signinView-wrapper .signUp-content .sign-up-cta:hover a {
    color: var(--white-fc);
}

.signinView-wrapper .term-condition {
    font: normal normal normal 12px/15px var(--fontArial);
    text-align: center;
    color: var(--clr-blue);
}

.signinView-wrapper .term-condition span {
    padding-left: 3px;
}

.signinView-wrapper .term-condition a {
    font-weight: 600;
    text-decoration: underline;
}

.signup-password-specs {
    padding-left: 20px;
    margin-bottom: 14px;
}

.signup-password-specs .checklist {
    margin: 5px 0;
}

.signup-password-specs p {
    font: normal normal normal 15px/18px var(--fontArial);
    color: var(--clr-blue);
    margin-bottom: 10px;
}

.signup-password-specs .checklist span {
    font: normal normal normal 15px/18px var(--fontArial);
    color: var(--clr-blue);
    padding-left: 10px;
}

.signup-country select {
    font: normal normal normal 14px/20px var(--fontArial);
    width: 100%;
    background: #eee;
    color: var(--clr-blue);
    border: 1px solid var(--grey-99);
    border-radius: 5px;
    outline: none;
    padding: 6px 15px;
    height: 40px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url("../assets/images/svg/chevronDown.svg");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 52%;
    cursor: pointer;
}

.signup-success--container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: fixed;
    left: 50%;
    top: 50%;
    background-color: var(--greyc-32);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 2;
}

.signup-success--wrapper {
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 270px;
    max-width: 640px;
    margin: auto;
}

.signUp-modal-close {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 5px;
    top: 15px;
}

.signUp-modal-close img {
    width: 20px;
}

/* .signup-success--wrapper button {
      background-color: transparent;
      border: none;
      position: absolute;
      right: 5px;
      top: 15px;
  }
  .signup-success--wrapper button img {
      width: 20px;
  } */
.signup-success--container .img-wrapper {
    display: block;
    height: 100%;
}

.signup-success--container .img-wrapper img {
    display: block;
    height: 100%;
    width: 400px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: revert;
    object-position: revert;
    border-radius: 10px 0 0 10px;
}

.signup-success--container .content-wrapper {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: var(--grey-ee);
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 10px 30px;
    border-radius: 0 10px 10px 0;
    width: 410px;
}

.signup-success--container .content-wrapper h2 {
    font: normal normal 600 22px/28px var(--fontArial);
    color: var(--clr-blue);
    text-align: center;
}

.signup-success--container .content-wrapper p {
    font: normal normal normal 15px/18px var(--fontArial) !important;
    color: var(--clr-blue);
    text-align: left;
    margin-bottom: 0;
}

.signup-success--container .content-wrapper p span {
    line-height: 35px;
    font-weight: 600;
}

.signup-success--container .content-wrapper .content-wrapper-congrats {
    position: relative;
    width: 100%;
    text-align: center;
}

.signup-success--container .content-wrapper .content-wrapper-congrats img {
    position: absolute;
    bottom: 36px;
    left: 70px;
    display: block;
    width: 130px;
    height: 100%;
}

/* Recover password modal */
.login-forget--container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: fixed;
    left: 50%;
    top: 50%;
    background-color: var(--greyc-32);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 2;
}

.login-forget--wrapper {
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 270px;
    max-width: 670px;
    margin: auto;
}

.login-forget--container .img-wrapper {
    display: block;
    position: relative;
    height: 100%;
}

.login-forget--container .img-wrapper .bg-image {
    display: block;
    height: 100%;
    width: 320px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: left;
    object-position: left;
    border-radius: 0 10px 10px 0;
}

.login-forget--container .content-wrapper {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: var(--grey-ee);
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 10px 30px;
    border-radius: 10px 0 0 10px;
}

.login-forget--container .content-wrapper form {
    width: 300px;
}

.resend-email-cta {
    border: none;
    padding: 0;
    background: none;
    font-weight: bold;
    color: var(--greyc-32);
}

.resend-email-cta:hover {
    text-decoration: underline;
    color: var(--clr-red-98);
}

.login-forget--wrapper .close-button img {
    width: 20px;
}

.login-forget--wrapper .close-button {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 5px;
    top: 15px;
}

.login-forget--container .content-wrapper h2 {
    font: normal normal 600 18px/22px var(--fontArial);
    color: var(--clr-blue);
    text-align: center;
}

.login-forget--container .content-wrapper p {
    font: normal normal normal 14px/22px var(--fontArial);
    color: var(--clr-blue);
    text-align: left;
    margin: 10px 0;
}

.forget-recover-cta {
    font: 600 15px/16px var(--fontArial);
    color: var(--dark-blue);
    border: 1px solid var(--grey-b7) !important;
    border-radius: 50px;
    width: 100%;
    border: none;
    display: block;
    padding: 12px 10px;
    text-align: center;
}

.forget-recover-cta:disabled {
    opacity: 0.5;
}

.forget-input-container input {
    display: block;
    width: 100%;
    height: 35px;
    padding: 10px;
    font: normal normal normal 13px/14px var(--fontArial);
    color: var(--clr-blue);
    background-clip: padding-box;
    border: 1px solid var(--grey-99);
    border-radius: 5px;
    background-color: transparent;
}

.forget-input-container input:focus {
    background-color: var(--blue-d9);
    outline: 2px solid var(--clr-red-98);
    border: none;
}

.email-gif {
    height: 50px;
    margin: 0 auto 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

/* change password modal */
.change-password--container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: fixed;
    left: 50%;
    top: 50%;
    background-color: var(--greyc-32);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 5;
}

.change-password--wrapper {
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.change-password--wrapper .content-wrapper {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: var(--grey-ee);
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 20px 20px 25px;
    border-radius: 5px;
}

.change-password--wrapper .content-wrapper form {
    width: 330px;
}

.change-password--wrapper .content-wrapper h2 {
    font: normal normal 600 20px/22px var(--fontArial);
    color: var(--clr-blue);
    text-align: center;
}

.change-password-input-container {
    margin: 20px 0;
}

.change-password-input-container input {
    display: block;
    width: 100%;
    height: 35px;
    padding: 10px;
    font: normal normal normal 13px/14px var(--fontArial);
    color: var(--clr-blue);
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid var(--grey-99);
    border-radius: 5px;
}

.change-password-input-container input:focus {
    background-color: var(--blue-d9);
    outline: 2px solid var(--clr-red-98);
    border: none;
}

.change-password--wrapper .close-button img {
    width: 20px;
}

.change-password--wrapper .close-button {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 5px;
    top: 15px;
}

.change-password-specs .checklist {
    margin: 5px 0;
}

.change-password-specs {
    margin-bottom: 20px;
}

.change-password-specs p {
    font: normal normal normal 15px/20px var(--fontArial);
    color: var(--clr-blue);
    margin-bottom: 10px;
}

.change-password-specs .checklist span {
    font: normal normal normal 14px/18px var(--fontArial);
    color: var(--clr-blue);
    padding-left: 10px;
}

.forget-input-container {
    margin-bottom: 15px;
}

/* Dashboard Main conatainer */
.map-main-parent--container {
    width: 100%;
    height: auto;
    position: relative;
    overflow-x: hidden;
}

/* -------------------------- */
/* Dasboard Progress container */
/* -------------------------- */
.dashboard-gauges-wrapper {
    position: absolute;
    top: 80px;
    z-index: 4;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: 0.5s ease all;
    -o-transition: 0.5s ease all;
    transition: 0.5s ease all;
}

.dashboard-map-menu--conatiner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: 0.5s ease all;
    -o-transition: 0.5s ease all;
    transition: 0.5s ease all;
    -webkit-box-shadow: 1px 1px 10px 0px #105d8d80;
    box-shadow: 1px 1px 10px 0px #105d8d80;
    background-color: var(--grey-ee);
}

.dashboard-map-menu--item {
    position: relative;
    text-align: center;
    padding: 0px 8px 0px 7px;
    height: 40px;
    background-color: transparent;
    border: none;
}
.dashboard-map-menu--item:hover {
    background-color: var(--clr-red-98) !important;
}
.dashboard-map-menu--item:hover p {
    color: var(--white-fff) !important;
}

.dashboard-map-menu--item.active {
    background-color: var(--clr-red-98);
}
.dashboard-map-menu--item.active p {
    background-color: var(--clr-red-98);
    color: var(--white-fff) !important;
}

.dashboard-map-menu--item p {
    font: 500 13px/16px var(--fontArial) !important;
    margin: 0;
    position: relative;
    text-transform: capitalize;
    letter-spacing: 0.7px;
    color: var(--grey-99) !important;
}

.dashboard-map-menu--item h4 span {
    position: absolute;
    margin-left: 5px;
}

.dashboard-map-menu--item h4 img {
    max-width: 15px;
}

.dashboard-map-menu--item p {
    font: normal normal normal 12px/18px var(--fontArial);
    margin-top: -2px;
    color: var(--clr-blue);
    margin-bottom: 2px;
}

.semicircle-container svg circle {
    width: 50px;
}

.semicircle-container svg circle:first-child {
    stroke: #d47957;
    -webkit-box-shadow: -8px 1px 16px -10px var(--light-blue);
    box-shadow: -8px 1px 16px -10px var(--light-blue);
    stroke-width: 8px;
}

.semicircle-percent-value {
    bottom: unset;
    top: 40%;
    height: 20px;
    font: normal normal normal 16px/24px Lato-Regular, sans-serif;
    color: var(--clr-blue);
}

.signup-phone-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 1rem;
    position: relative;
}

.signup-phone-wrapper input:first-child,
.signup-phone-wrapper input:nth-child(2) {
    width: 70px;
}

/* .signup-phone-wrapper::before {
    content: "";
    display: block;
    width: 15px;
    height: 3px;
    background-color: var(--grey-99);
    position: absolute;
    top: 20px;
    left: 80px;
}
.signup-phone-wrapper::after {
    content: "";
    display: block;
    width: 15px;
    height: 3px;
    background-color: var(--grey-99);
    position: absolute;
    top: 20px;
    left: 184px;
} */

/* speedomater d3 component */
.dashboard-map-menu--item .speedometer {
    position: relative;
    margin-top: -12px;
}

.dashboard-map-menu--item .speedometer .label .segment-value {
    display: none;
    -webkit-transform: translate(0, 0) !important;
    -ms-transform: translate(0, 0) !important;
    transform: translate(0, 0) !important;
}

.dashboard-map-menu--item .speedometer .current-value {
    font: normal normal normal 16px/18px var(--fontArial) !important;
    color: var(--clr-blue);
}

.dashboard-map-menu--item .speedometer g:nth-child(3) {
    -webkit-transform: translate(65px, 30px);
    -ms-transform: translate(65px, 30px);
    transform: translate(65px, 30px);
}

.dashboard-map-menu--item .guage-image {
    width: 92px;
    position: absolute;
    left: 19px;
    top: 42px;
    z-index: 1;
}

.dashboard-map-menu--item svg text[alignment-baseline="middle"] {
    display: none;
}

.marker-info-gauge svg text[alignment-baseline="middle"] {
    display: none;
}

.speedometer-view svg text[alignment-baseline="middle"] {
    display: none;
}

.listCurve-wrapper {
    height: 20px;
    margin-top: 10px;
    background-color: var(--white-fc);
}

/* Dashboard maps progress bar */
.maps-progress-bar-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 12px;
    overflow: hidden;
    position: absolute;
    width: 100%;
    left: 50%;
    top: 16px;
    background-color: var(--white-fa);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    /* box-shadow: 0px 7px 14px 1px rgba(0, 0, 0, 0.3); */
    z-index: 1;
    /* box-shadow: inset -3px 3px 10px #105d8d50, inset 3px -3px 7px #fff;
    -webkit-box-shadow: inset -3px 3px 10px #105d8d50, inset 3px -3px 7px #fff; */
}

.maps-progress-bar-container .upgrade-cta {
    background-color: var(--white-fff);
    text-transform: uppercase;
    text-decoration: underline;
}

.maps-progress-bar-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    -webkit-transition: width 0.6s ease;
    -o-transition: width 0.6s ease;
    transition: width 0.6s ease;
    font: normal normal 600 12px/12px var(--fontArial);
    color: var(--clr-blue);
    background-color: var(--white-fff);
    border-radius: 2px;
    max-width: 100% !important;
}

/* -------------------------- */
/* Dashbaord Map Api btns Background */
/* -------------------------- */
.dashboard-bg-map {
    position: relative;
    width: 100%;
    min-height: 100vh;
    max-width: 100%;
}

/* #google_map_container {
    height: clamp(91vh, 91vh, 91vh);
    min-height: 100vh;
    height: 100vh;
    max-height: 100vh;
} */

/* Public View Styling */
.publicView-bg-map {
    position: relative;
    width: 100%;
    height: 90vh;
    max-width: 100%;
}
.shareLocationView-bg-map {
    position: relative;
    width: 100%;
    height: 100vh;
    max-width: 100%;
}
.shareLocationView-bg-map .google-map-container {
    height: clamp(100vh, 100vh, 100vh) !important;
}

.publicView-search-box {
    position: absolute;
    z-index: 1;
    top: 20px;
    right: 35px;
    -webkit-backdrop-filter: saturate(180%) blur(10px);
    backdrop-filter: saturate(180%) blur(10px);
    background: hsla(0, 0%, 93%, 0.8);
    border-radius: 10px;
    min-width: 270px;
    padding: 10px;
}

.publicView-search-box .close-button {
    border: none;
    background-color: transparent;
    position: absolute;
    right: 10px;
}

.publicView-search-box .title-image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0 10px;
}

.publicView-search-box .title-image img {
    width: auto;
    height: 50px;
}

.publicView-search-box .title-image p {
    color: var(--clr-blue);
    font: normal normal normal 14px/22px var(--fontArial);
    margin: 0 0;
    max-width: 150px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;
}

.publicView-search-input input {
    border-radius: 20px;
    border: 2px solid rgba(50, 55, 60, 0.2);
    color: var(--clr-blue);
    outline: none;
    background-color: var(--grey-ee);
    font: normal normal normal 14px/22px var(--fontArial);
    height: 30px;
    padding: 4px 10px;
    width: 100%;
    margin-top: 10px;
}

/* .publicView-search-input ::placeholder {
      color: rgba(153, 155, 158, 0.8);
      opacity: 1;
  } */
.publicView-search-input img {
    margin-left: -30px;
    width: 20px;
}

.unlisted-publicview-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.unlisted-publicview-wrapper h6 {
    font: normal normal normal 40px/50px var(--fontArial);
    color: var(--clr-blue);
}

.password-publicview-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100vh;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.password-publicview-wrapper input {
    background-color: var(--white-fa);
    border: 1px solid var(--grey-99);
    border-radius: 5px;
    color: var(--clr-blue);
    font: normal normal normal 14px/20px var(--fontArial);
    height: 30px;
    outline: none;
    padding: 6px 16px;
    margin-top: 30px;
    width: 100%;
}

.password-publicview-wrapper input:focus {
    outline: 2px solid var(--clr-red-98);
    background: var(--blue-d9);
    border: none;
}

.view-cta {
    color: var(--white-fff);
    border: 2px solid var(--clr-red-98);
    background-color: var(--clr-red-98);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font: normal normal normal 14px/18px var(--fontArial);
    padding: 10px;
    width: 100%;
    max-width: 100px;
    margin: 15px auto;
    border-radius: 25px;
}

/* -------------------------- */
/* MyMapList component */
/* -------------------------- */
.myMapList-container {
    position: absolute;
    top: 110px;
    width: var(--width-mapList);
    left: 0px;
    -webkit-transition: 0.5s ease all;
    -o-transition: 0.5s ease all;
    transition: 0.5s ease all;
    z-index: 7;
}

.mapList-search-header {
    /* background: transparent linear-gradient(180deg, #6197ba 0%, #105d8d 100%); */
    background-color: #2675a0;
    -webkit-box-shadow: 0px 3px 5px #105d8d66;
    box-shadow: 0px 3px 5px #105d8d66;
    padding: 10px 10px 5px;
}

/* .mapList-btns-header {
    background: rgba(238, 238, 238, 0.8);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    margin-top: 5px;
} */

/* .mapList-btns-header div {
      min-width: 100px;
  } */
.mapList-btns-header a,
.mapList-btns-header button {
    display: block;
    border: none;
    background: #2675a0;
    font: normal normal normal 12px/20px var(--fontArial);
    color: var(--clr-blue);
    padding: 4px 10px;
    min-width: 70px;
    text-align: center;
}

.mapList-btns-header a:hover,
.mapList-btns-header button:hover {
    text-decoration: underline;
}

.listCurve-wrapper img {
    height: 21px;
}

.mapList-btns-header .click-wrappers {
    background: #2675a0;
}

.mapList-search-fav {
    background: transparent -o-linear-gradient(top, #6197ba 0%, #4e6573 100%) 0% 0% no-repeat padding-box;
    background: transparent -o-linear-gradient(top, #6197ba 0%, #4e6573 100%) 0% 0% no-repeat padding-box;
    background: transparent -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#6197ba),
            to(#4e6573)
        ) 0% 0% no-repeat padding-box;
    background: transparent -o-linear-gradient(top, #6197ba 0%, #4e6573 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #6197ba 0%, #4e6573 100%) 0% 0% no-repeat
        padding-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 8px 0;
    width: 100%;
    padding: 15px 20px;
    border-radius: 8px 8px 0 0;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.mapList-search-sec {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.mapList-search-sec input {
    border: none;
    color: var(--blk-70);
    outline: none;
    background-color: transparent;
    font: normal normal normal 12px/18px var(--fontArial);
    height: 30px;
    padding: 0 10px;
    width: 100%;
}

.mapList-search-sec input::-webkit-input-placeholder {
    color: var(--blk-70);
}

.mapList-search-sec input::-moz-placeholder {
    color: #90b4cd;
}

.mapList-search-sec input:-ms-input-placeholder {
    color: var(--blk-70);
}

.mapList-search-sec input::-ms-input-placeholder {
    color: var(--blk-70);
}

.mapList-search-sec input::placeholder {
    color: var(--blk-70);
}

.mapList-search-sec img {
    --size: 20px;
    width: var(--size);
    height: var(--size);
    cursor: pointer;
    margin-right: 5px;
}

.mapList-search-sec::after {
    content: "";
    display: block;
    height: 28px;
    width: 1px;
    background-color: var(--grey-ee);
    position: absolute;
    right: 26px;
}

.fav-map-indicator {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.fav-map-indicator p {
    color: #eee;
    margin: 0;
}

.fav-map-indicator span {
    color: #eee;
    margin-right: 5px;
    font-size: 10px;
    line-height: 20px;
}

.myMapList-close {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 12px;
    font-size: 18px;
    color: rgba(238, 238, 238, 0.5);
}

.myMapList-data-conatiner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.myMap-list-img {
    --size: 55px;
    width: var(--size);
    height: var(--size);
    border-radius: 10px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}

.myMapList-data-container {
    margin-top: -1px;
    max-height: 360px;
    overflow-y: auto;
    overflow-x: unset;
    -webkit-box-shadow: 0px 0px 18px var(--clr-box-shadow);
    box-shadow: 0px 0px 18px var(--clr-box-shadow);
    /* position: static; */
    padding: 0;
    list-style-type: none;
    z-index: -2;
}

.myMapList-data-item {
    position: relative;
    cursor: pointer;
    -webkit-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    border-bottom: 1px solid var(--grey-d6);
}

.myMapList-data-item:hover .myMapList-data-item-inner {
    background: var(--white-fc);
}

.myMapList-data-item-inner {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 5px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background: rgba(238, 238, 238, 0.8);
    padding: 5px 10px;
    cursor: pointer;
}

.myMapList-data-item-inner.active {
    background: var(--white-fc);
}

.accordion-icon {
    font-size: 16px;
    cursor: pointer;
    color: #eeeeee;
}

.myMap-img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: 5px;
}

.myMap-img .list-input-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 15px;
}

.myMap-img span {
    font-size: 12px;
    color: var(--grey-99);
}

.myMap-img .fav-star {
    max-width: 12px;
}

.mapList-data {
    min-width: 210px;
}

.mapList-title {
    font: normal normal 600 13px/16px var(--fontArial);
    color: var(--clr-blue);
    margin: 0;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    max-width: var(--mapList-characters);
}

.myGroup-tooltip--container {
    position: relative;
    height: 100%;
    z-index: 5;
}

/* .myGroup-tooltip-icon {
    position: absolute;
    left: 5px;
    top: 5px;
    display: none;
}
.myGroup-tooltip--container:hover .myGroup-tooltip-icon {
    display: block;
}
.myGroup-tooltip-icon .tooltiptext {
    position: absolute;
    width: 185px;
    background: var(--white-fc);
    font: normal normal normal 11px/13px var(--fontArial);
    color: var(--clr-blue);
    border-radius: 5px;
    padding: 5px 10px;
    top: 24px;
    left: -4px;
}
.myGroup-tooltip-icon .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 8px;
    margin-left: 0;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #fcfcfc transparent;
} */
.myMapList-people-shared-excerpt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.myMapList-people-shared {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 2px 0;
    gap: 0 2px;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.user-initials-circle {
    --size: 24px;
    position: relative;
    height: var(--size);
    width: var(--size);
    background-color: var(--grey-99);
    border-radius: 50%;
    font: normal normal normal 9px/11px var(--fontArial);
    color: var(--white-fff);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-transform: uppercase;
}

.myMapList-people-shared img {
    --size: 24px;
    height: var(--size);
    width: var(--size);
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
}

.myMapList-people-shared .owner {
    border: 1px solid var(--red-90);
    margin-top: 3px;
}

.myMapList-people-maxcount {
    background-color: var(--grey-99);
    --size: 20px;
    height: var(--size);
    width: var(--size);
    position: relative;
    /* margin-left: -2px; */
    border: none;
    border-radius: 50%;
    display: -ms-grid;
    display: grid;
    justify-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.no-shared-user-wrapper {
    --size: 25px;
    width: var(--size);
    height: var(--size);
    position: relative;
    margin: 2px 0;
}

.excerpt-share-add-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0 30px;
    margin-top: 8px;
    border-top: 2px solid var(--grey-b7);
    padding: 10px 0 5px;
}

.excerpt-share-add-wrapper button {
    background: none;
    border: none;
    font: normal normal normal 14px/16px var(--fontArial);
    color: var(--clr-blue);
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0 5px;
}

.excerpt-share-add-wrapper button a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0 5px;
}

.excerpt-share-add-wrapper button img {
    max-width: 14px;
}

.myMapList-people-maxcount span {
    color: var(--white-fff);
    font-size: 9px;
}

.myMapList-views {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    gap: 0 10px;
}

.myMapList-views button {
    background-color: transparent;
    font: normal normal normal 10px/10px var(--fontArial);
    color: var(--clr-blue);
    border: none;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.myMapList-views button:hover {
    text-decoration: underline;
}

.myMapList-views button:hover,
.myMapList-views button:hover svg path {
    fill: var(--clr-blue);
}
.myMapList-views button svg:hover {
    transform: scale(1.1);
}

.shared_mapList_btn {
    background-color: transparent;
    font: normal normal normal 10px/10px var(--fontArial);
    color: var(--clr-blue);
    border: none;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.myMap-config button img {
    cursor: pointer;
    width: 14px;
}

.myMap-config button span {
    font: normal normal normal 12px/14px var(--fontArial);
    color: var(--clr-blue);
}

.myMap-config a img {
    width: 16px;
}

.myMap-config button {
    background-color: transparent;
    border: none;
}

.myMap-config button svg {
    width: 10px;
}
.myMap-config button:hover svg > path {
    fill: var(--clr-blue);
}

.three-dot-wrapper .myMap-config.options button {
    width: 100%;
    padding: 5px 10px;
}

.three-dot-wrapper .myMap-config.options button:hover span {
    color: var(--clr-blue);
}

.three-dot-wrapper .myMap-config.options button:last-child:hover svg > path {
    fill: var(--red-90);
}
.three-dot-wrapper .myMap-config.options button:last-child:hover span {
    color: var(--red-90);
}

.MyGroup-title-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0 10px;
}

.MyGroup-title-wrapper p {
    background-color: transparent;
    font: normal normal normal 12px/14px var(--fontArial);
    color: var(--clr-blue);
    margin: 0;
}

.MyGroup-title-wrapper p span {
    font-weight: 600;
    text-transform: capitalize;
}

/* -------------------------- */
/* myMapSharedList component */
/* -------------------------- */
.myMapSharedUserList-container {
    position: absolute;
    display: block;
    top: 135px;
    left: calc(var(--width-mapList) + 10px);
    min-width: 300px;
    z-index: 2;
}

.myMapShared-title {
    background-color: var(--grey-ee);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    height: 45px;
    padding: 2px;
    border-radius: 2px 2px 0 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    box-shadow: 0px 9px 6px -4px black;
}

.myMapShared-title .horizontal-bar {
    height: 24px;
    background-color: var(--grey-99);
    width: 1px;
}

.myMapShared-title .sharedMap-countInfo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 5px;
}

.myMap-shared-img {
    height: 60px;
    width: 60px;
    border-radius: 10px;
    margin-right: 10px;
}

.myMapShared-title h5 {
    font: normal normal normal 12px/20px var(--fontArial);
    margin: 2px 0;
    color: #fff;
}

.myMapShared-title p {
    font: normal normal normal 12px/18px var(--fontArial);
    color: #fff;
    margin: 0;
}

.myMap-sharedList--items {
    background: rgba(238, 238, 238, 0.9);
    max-height: 275px;
    min-width: 300px;
    overflow-y: auto;
    border-radius: 0 0 2px 2px;
}

.myMapShared-list {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 2px solid var(--grey-d6);
    padding: 5px 10px;
}

.myMapShared-list:hover {
    background: var(--white-fc);
}

.myMapShared-list .myMap-config {
    display: none;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: 0 5px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background: var(--grey-ee);
    padding: 5px 0;
    position: absolute;
    top: 0;
    right: 30px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 1;
    -webkit-box-shadow: -5px 5px 5px #105d8d33, 5px -5px 7px #105d8d33;
    box-shadow: -5px 5px 5px #105d8d33, 5px -5px 7px #105d8d33;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.myMapShared-list .myMap-config button:hover {
    background-color: var(--white-fc);
    font-weight: 600;
}

.myMapShared-list .myMap-config button:hover a {
    font-weight: 600;
}

.myMapShared-list .user-email {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.myMapShared-list .user-email img {
    --size: 22px;
    border-radius: 50%;
    height: var(--size);
    margin-right: 6px;
    width: var(--size);
    -o-object-fit: cover;
    object-fit: cover;
}

.myMapShared-list .user-email h5 {
    font: normal normal bold 12px/14px var(--fontArial);
    color: var(--clr-blue);
    margin: 2px 0;
}

.myMapShared-list .user-email p {
    font: normal normal normal 12px/14px var(--fontArial);
    color: var(--clr-blue);
    margin: 3px 0;
    text-transform: lowercase;
}

.three-dot-wrapper button {
    background-color: transparent;
    border: none;
    padding: 0;
}

.three-dot-wrapper:hover svg {
    color: var(--clr-red-98) !important;
    fill: var(--clr-red-98) !important;
}

.myMapList-data-item .myMap-config {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.myMap-config a,
.myMap-config button a {
    color: var(--clr-blue);
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.three-dot-wrapper .myMap-config button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/* MyMapsGroup component */
.myMapGroupList-container {
    position: absolute;
    display: block;
    top: var(--top-mapGroupList);
    left: var(--width-mapList);
    width: var(--width-mapGroupList);
}

.myMapGroupList-container.secondPane {
    left: calc(var(--width-mapList) + var(--width-mapGroupList));
    top: calc(var(--top-mapGroupList) + 35px);
}

.myMapGroupList-title {
    /* background-color: var(--blue-8d); */
    background-color: var(--grey-ee);
    color: var(--clr-blue);
    /* background-color: transparent linear-gradient(180deg, #6197ba 0%, #105d8d 100%); */
    border: 1px solid #00000000;
    opacity: 1;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 100%;
    padding: 5px 10px;
    border-radius: 2px 2px 0 0;
    position: relative;
    border-bottom: 2px solid var(--grey-d6);
}

.myMapGroups-data-container {
    max-height: 290px;
    min-height: 70px;
    overflow-y: auto;
    border-radius: 0 0 4px 4px;
    -webkit-box-shadow: 0px 0px 18px var(--clr-box-shadow);
    box-shadow: 0px 0px 18px var(--clr-box-shadow);
}

.myMapGroups-title-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0 5px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.myMapGroups-title-wrapper h5 {
    font: normal normal normal 13px/16px var(--fontArial);
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    max-width: var(--mapList-characters);
    margin: 0;
}

.myMapGroups-title-wrapper p {
    color: var(--clr-blue);
    font: normal normal normal 13px/16px var(--fontArial);
    margin: 0;
    margin-right: 20px;
}

.myMapGroups-title-wrapper p span {
    font-weight: 600;
    text-transform: capitalize;
    text-decoration: underline;
}

.myMapGroupList-title .mapTitle {
    font: 700 13px/16px var(--fontArial);
    color: var(--clr-blue);
    text-align: left;
    margin: 3px 0;
}

.myMapGroupList-title img {
    position: absolute;
    top: 50%;
    right: 5px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 20px;
    cursor: pointer;
}

.myMapGroups-data-item {
    border-bottom: 1px solid #fafafa;
    cursor: pointer;
    position: relative;
    -webkit-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
}

.myMapGroups-data-item:hover .myMapGroups-data-item-inner,
.myMapGroups-data-item.active .myMapGroups-data-item-inner {
    background: var(--white-fc);
}

.myMapGroups-data-item-inner {
    background: rgba(238, 238, 238, 0.8);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    padding: 5px 10px;
    position: relative;
}

.myMapGroups-img {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 10px;
}

.myMapGroups-img .fav-star {
    width: 14px;
}

.myMapGroups-list-img {
    --size: 55px;
    border-radius: 10px;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    max-width: var(--size);
    max-height: var(--size);
}

.myMapGroups-data {
    min-width: 210px;
}

.myMapGroups-share-count {
    font: normal normal normal 12px/14px var(--fontArial);
    color: var(--clr-blue);
    margin: 0;
}

.myMapGroups-share-count span {
    text-decoration: underline;
}

.myMapGroups-people-shared {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.myMapGroups-people-shared img {
    border-radius: 50%;
    height: 16px;
    -o-object-fit: cover;
    object-fit: cover;
    width: 16px;
}

.myMapGroups-data-item .myMap-config {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.myMapGroups-data-item .myMap-config button {
    width: 100%;
}

.three-dot-wrapper:hover .myMap-config {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
s .myMap-config button:hover svg path {
    fill: var(--clr-red-98);
}

.three-dot-wrapper {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

/* -------------------------- */
/* MyMapExcerpt component */
/* -------------------------- */
.myMap-excerpt--container {
    position: absolute;
    top: 0px;
    right: 10px;
    background: rgba(238, 238, 238, 0.8);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    padding: 15px 20px 10px;
    min-width: 240px;
    border-radius: 10px;
    margin: 10px;
    -webkit-box-shadow: 0px 0px 5px 0px #105d8d40;
    box-shadow: 0px 0px 5px 0px #105d8d40;
}

.myMap-excerpt--container ol {
    margin-top: 10px;
    font-size: 14px;
    font-family: "SourceSansPro-Regular";
    padding-left: 20px;
}

.createNewMap-cta,
.createNewMap-cta:hover {
    position: relative;
    display: block;
    background-color: var(--clr-red-98);
    color: white;
    padding: 8px;
    min-width: 150px;
    text-align: center;
    border-radius: 10px;
    margin-bottom: 15px;
    text-decoration: none;
    font-size: 14px;
    border: none;
}

.myMap-excerpt-details .myMapList-people-shared-excerpt {
    margin-top: 5px;
}

.myMap-excerpt-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 0;
}

.myMap-excerpt-title .add-hide-map {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.myMap-excerpt-title .add-hide-map button {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 2px 0;
}

.myMap-excerpt-title .add-hide-map button img {
    width: 18px;
}

.myMap-excerpt-title h5 {
    font: normal normal normal 15px/20px var(--fontArial);
    margin: 2px 0;
    color: var(--clr-blue);
    font-size: 16px;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    max-width: var(--mapList-characters);
}

.myMap-excerpt-title p {
    margin: 0;
    color: var(--clr-blue);
    font-size: 14px;
    text-transform: capitalize;
    max-width: 150px;
}

.myMap-excerpt-title p span {
    color: #818181;
    margin-left: 5px;
    font-size: 10px;
    line-height: 20px;
}

.fav-map-color {
    color: #ffaa00 !important;
}

.check-icon {
    width: 15px !important;
}

/* -------------------------- */
/* Map Pin info container */
/* -------------------------- */
.mapPinDetail--container {
    font-family: SourceSansPro-Regular;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 280px;
}

.mapPinDetail--container .header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: var(--grey-ee);
    box-shadow: 0px 0px 5px 0px var(--clr-box-shadow);
}

.mapPinDetail--container h4 {
    text-transform: capitalize;
    font: normal normal 600 16px/16px var(--fontArial);
    color: var(--clr-blue);
    margin-bottom: 0px;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-width: 36ch;
    white-space: nowrap;
}

.mapPinDetail--container .pin-address-full {
    text-transform: capitalize;
    font: normal normal normal 13px/16px var(--fontArial);
    color: var(--clr-blue);
    margin-top: 0;
    margin-bottom: 10px;
    /* padding-left: 20px; */
}

.mapPinDetail--container div img {
    width: 20px;
}

.mapPinDetail--container .mapPinInfo {
    max-height: 200px;
    width: 350px;
    overflow: scroll;
    padding: 0 15px;
    backdrop-filter: blur(5px);
    /* background: rgba(238, 238, 238, 0.9); */
    background: var(--grey-d6);
}

.mapPinInfo p {
    /* display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center; */
    font: normal normal normal 14px/18px var(--fontArial);
    color: var(--greyc9-32);
    margin-top: 5px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.mapPinInfo p img {
    max-width: 15px;
}

.mapPinInfo p span {
    padding-left: 5px;
    color: var(--clr-blue);
}

.markerbox-preview-container {
    border: 2px solid var(--grey-d6);
    border-radius: 4px;
}

.markerbox-preview-container .marker-box-header {
    border-bottom: 2px solid var(--grey-d6);
    padding: 6px 14px;
}
.markerbox-preview-container h4 {
    font: normal normal 600 17px/22px var(--fontArial);
    color: var(--blk-96);
    text-transform: capitalize;
    margin-bottom: 0;
}

.markerbox-preview-container p {
    font: normal normal normal 12px/14px var(--fontArial);
    color: var(--blk-70);
    margin: 0;
}

.markerbox-preview-container .marker-box-body {
    padding: 10px 14px;
}
.markerbox-preview-container ul {
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 5px;
    list-style: none;
    margin-bottom: 0;
    margin-top: 5px;
}

.markerbox-preview-container ul li {
    font: 600 14px / 18px var(--fontArial);
    color: var(--blk-96);
    word-break: break-word;
}

.markerbox-preview-container ul li span {
    font: 500 14px / 16px var(--fontArial) !important;
    color: var(--blk-70);
}

.markerPlace-note {
    font: normal normal normal 14px/18px var(--fontArial);
    color: var(--clr-blue);
    margin-bottom: 20px;
    padding-left: 15px;
    margin-top: 0;
}

/* Add class .dashboard-bg-map if somethinf went wrong, becaouse pin modal UI casuing issues in create map before .gm-style-iw */
.gm-style-iw.gm-style-iw-c,
.publicView-bg-map .gm-style-iw.gm-style-iw-c {
    background: transparent;
    border-radius: 3px;
    opacity: 1;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 0;
    overflow: unset !important;
    box-shadow: 0 2px 7px 1px rgb(0 0 0 / 10%);
    -webkit-box-shadow: 0 2px 7px 1px rgb(0 0 0 / 10%);
}

.gm-style-iw.gm-style-iw-c .gm-style-iw-d,
.publicView-bg-map .gm-style-iw.gm-style-iw-c .gm-style-iw-d {
    overflow: auto !important;
    max-height: unset !important;
}

.gm-style-iw.gm-style-iw-c button.gm-ui-hover-effect span {
    display: none !important;
}

/* Marker InfoBox Container */
.marker-infobox--container {
    background-color: rgba(255, 255, 255, 0.9);
    display: block;
    color: var(--clr-blue);
    border-radius: 5px;
    padding: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 250px;
    -webkit-transition: 0.5s ease all;
    -o-transition: 0.5s ease all;
    transition: 0.5s ease all;
    overflow-x: hidden;
}

.marker-infobox--container p {
    margin: 5px;
    text-transform: capitalize;
    font: normal normal normal 12px/18px var(--fontArial);
}

/* Share Map component */
.shareMap-modal--container {
    pointer-events: auto;
    width: 100%;
    max-width: 350px;
    position: absolute;
    left: 364px;
    top: 135px;
}

.shareMap-modal-wrapper {
    position: relative;
    display: block;
    background: #eee;
    border-radius: 2px;
    z-index: 2;
    width: 100%;
    -webkit-box-shadow: 0px 0px 18px var(--clr-box-shadow);
    box-shadow: 0px 0px 18px var(--clr-box-shadow);
}
/* //commment the Box Shadow Here */
.shareMap-header {
    position: relative;
    padding: 5px 10px 7px;
    margin-bottom: 5px;
}

.shareMap-maps-container {
    max-height: 200px;
    min-height: 200px;
    padding: 10px;
    overflow-y: auto;
}

.shareMap-modal--container .load-more-wrap {
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    bottom: 54px;
    left: 50%;
    z-index: 9;
    width: 100%;
    height: 30px;
    text-align: center;
}

.shareMap-modal--container .load-more-wrap button {
    border: none;
    font: normal normal normal 16px/18px var(--fontArial);
    color: var(--clr-red-98);
    background-color: var(--grey-ee);
}

/* Move Pin/Marker componenet */
.moveMarker-modal--container {
    pointer-events: auto;
    width: 100%;
    max-width: 350px;
    min-width: 350px;
    position: absolute;
    left: calc(100% + 10px);
    bottom: 0;
}

.moveMarker-modal-wrapper {
    position: relative;
    display: block;
    background: #eee;
    border-radius: 2px;
    z-index: 1;
    width: 100%;
}

.moveMarker-close {
    border: none;
    padding: 0;
}

.moveMarker-close img {
    width: 22px;
}

.moveMarker-check {
    --size: 15px;
    position: absolute;
    width: 15px;
    height: auto;
    right: var(--size);
    top: var(--size);
}

.moveMarker-check img {
    width: 15px;
}

.moveMarker-header {
    position: relative;
    padding: 5px 10px 7px;
    border-bottom: 2px solid var(--grey-d6);
    -webkit-box-shadow: 0px 3px 5px #105d8d33;
    box-shadow: 0px 3px 5px #105d8d33;
    margin-bottom: 5px;
}

.moveMarker-header h6,
.shareMap-header h6 {
    font: normal normal 600 18px/20px var(--fontArial);
    color: var(--clr-blue);
    text-align: center;
}
.shareMap-header h5 {
    font: normal normal 600 14px / 16px var(--fontArial);
    color: var(--clr-blue);
    text-align: center;
    margin-bottom: 7px;
}

.moveMarker-modal--footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: flex-end;
    padding: 10px 20px;
}

.move_margin {
    margin-right: 1rem;
}

.cta-filled-blue-auto,
.cta-filled-blue-auto:hover {
    display: inline-block;
    background-color: var(--clr-red-98);
    color: white;
    font: normal normal normal 14px/20px var(--fontArial);
    border-radius: 50px;
    width: auto;
    min-height: 43px;
    border: none;
    min-width: 120px;
    -webkit-box-shadow: -2px 4px 10px #105d8d33;
    box-shadow: -2px 4px 10px #105d8d33;
}

.cta-filled-blue-auto[disabled] {
    opacity: 0.6;
}

.cta-border-blue-auto {
    display: inline-block;
    background-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;
    vertical-align: middle;
    border: 2px solid var(--clr-red-98);
    color: var(--clr-red-98);
    font: normal normal normal 16px/24px var(--fontArial);
    border-radius: 50px;
    width: auto;
    min-width: 120px;
    min-height: 43px;
}
/* #contact .right .form-actions .cta-border-blue-auto {
    border: 2px solid var(--clr-blue) !important;
    color: var(--clr-blue) !important;
}
#contact .right .form-actions .cta-border-blue-auto:hover {
    background-color: var(--clr-blue) !important;
    border: 2px solid var(--clr-blue) !important;
    color: var(--white-fc) !important;
} */
.cta-border-red-auto {
    display: inline-block;
    background-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;
    vertical-align: middle;
    border: 2px solid var(--clr-red-98);
    color: var(--clr-red-98);
    font: normal normal normal 16px/24px var(--fontArial);
    border-radius: 50px;
    width: auto;
    min-width: 120px;
    min-height: 43px;
}

.close-icon-custom {
    background-color: transparent;
    color: var(--grey-99);
    border: none;
    position: absolute;
    right: 5px;
    top: 12px;
    width: 18px;
    border: none;
    padding: 0;
}

.movePin-search-box {
    position: relative;
    text-align: center;
}

.movePin-search-box img {
    position: absolute;
    width: 25px;
    right: 25px;
    top: 5px;
}

.movePin-search-box input {
    font: normal normal normal 13px/18px var(--fontArial);
    border: 1px solid var(--grey-99);
    color: var(--clr-blue);
    border-radius: 50px;
    outline: none;
    background-color: transparent;
    height: 35px;
    padding: 0 10px;
    width: 90%;
}

.movePin-search-box input:focus {
    outline: 2px solid var(--clr-red-98);
    background: var(--blue-d9);
    border: none;
}

.movePin-search-box span {
    margin-left: -20px;
    color: var(--grey-99);
}

.moveMarker-maps-container {
    max-height: 200px;
    min-height: 200px;
    padding: 10px 20px;
    overflow-y: auto;
}

.moveMarker-maps-container .no-user-indication {
    font: normal normal normal 16px/22px var(--fontArial);
    color: var(--clr-blue);
    text-align: center;
}

.moveMarker-maps-container .no-user-indication span {
    color: var(--clr-red-98);
}

.moveMarker-map-item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
}

.moveMarker-map-item:hover {
    background-color: var(--white-fc);
}

.moveMarker-map-item--inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0 8px;
    padding: 2px 10px;
    cursor: pointer;
    width: 100%;
}

.moveMarker-map-img .map-image {
    --size: 35px;
    width: var(--size);
    height: var(--size);
    border-radius: 5px;
    -o-object-fit: cover;
    object-fit: cover;
}

.shareMap-user-img img {
    --size: 30px;
    width: var(--size);
    height: var(--size);
    border-radius: 50px;
    -o-object-fit: cover;
    object-fit: cover;
}

.movePin-disabled {
    pointer-events: none;
    cursor: not-allowed !important;
    opacity: 0.5;
}

.moveMarker-map-detail .title {
    font: normal normal normal 14px/16px var(--fontArial);
    color: var(--clr-blue);
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    max-width: 120px;
    margin-top: 0;
    margin-bottom: 0;
}

.moveMarker-map-detail .count {
    font: normal normal normal 12px/16px var(--fontArial);
    color: var(--greyc9-32);
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.pulse {
    background: #93d1f7;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -12px 0 0 -12px;
    animation: pulse 1.5s infinite;
}

.marker-image {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transform: translate(-50%, -50%) rotate(45deg);
    border-radius: 50%;
    border: 2px solid white;
}
/* -------------------------- */
/* Anonymous down arrow showing on  click */
/* -------------------------- */
.gm-style-iw-tc::after {
    height: 0 !important;
    width: 0 !important;
}

.mapPinClicks {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    padding: 10px 15px;
    opacity: 1;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 0 0 10px 10px;
    background-color: var(--grey-ee);
}

.mapPinClicks.hide {
    display: none;
}

.mapPinClicks button,
.mapPinClicks button a {
    border: none;
    background-color: transparent;
    padding: 0;
}

.mapPinClicks button svg > path {
    fill: var(--grey-99);
}
.mapPinClicks button:hover svg > path {
    fill: var(--clr-blue) !important;
}

.mapPinClicks .trash-hide-share:hover svg > path {
    fill: var(--red-90) !important;
}

.mapPinClicks p {
    margin: 0;
    cursor: pointer;
}

.mapPinClicks span {
    font-size: 12px;
}
.mapPinClicks .markerInfo-pagination {
    margin-left: 5rem;
    display: flex;
    gap: 4px;
}

.duplicated_marker_Label {
    background-image: url("../assets/images/svg/multiLocation\ 1.svg");
    background-position: center center;
    background-size: contain;
    --size: 20px;
    width: var(--size);
    height: var(--size);
    text-align: center;
    border-radius: 10px;
    font: 500 9px / 9px var(--fontArial) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -15px;
    right: -11px;
}
.markerInfo-noUse {
    font: normal normal normal 13px/18px var(--fontArial);
    color: var(--clr-blue);
    background: #fcfcfc;
    border-radius: 5px;
    margin: 0;
    padding: 5px;
    position: absolute;
    bottom: -30px;
    right: -20px;
}

.marker-tooltip-icon {
    width: 15px !important;
    margin-left: 2px;
}

/* -------------------------- */
/* Map Pin Bar Group */
/* -------------------------- */
.mapData-tabular-parent-container {
    /* position: absolute;
    bottom: -16px;
    background-color: var(--grey-b7); */
}
.mapPinBar-group-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 10px;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background: rgba(238, 238, 238, 0.8);
    -webkit-box-shadow: 0px 0px 18px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 18px 5px rgba(0, 0, 0, 0.2);
    padding-inline: 20px;
    position: absolute;
    width: 100%;
    /* bottom: 194px; */
    bottom: 0px;
    z-index: 1;
}

.mapPinBar-group-content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font: normal normal normal 14px/20px var(--fontArial);
}

.mapPinBar-group-content img {
    width: auto;
    max-width: 12px;
    height: auto;
}

.mapPinBar-group-content button {
    font-size: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
}

.mapPinBar-group-content button span {
    background-color: var(--grey-d6);
    padding: 5px 10px;
    border-radius: 30px;
}

.mapPinBar-group-container .mapPinBar_changes_btn {
    display: flex;
    gap: 0.8rem;
}
.mapPinBar-group-container .mapPinBar_changes_btn .mapPinbar_btn {
    background-color: transparent;
    font: 600 13px / 15px var(--fontArial);
    border: none;
    padding: 3px 6px;
    gap: 0.6rem;
    border-radius: 5px;
    transition: all 0.3s ease-in;
    border: 2px solid var(--grey-b7);
    color: var(--greyc9-32);
}
.mapPinBar-group-container .mapPinBar_changes_btn .mapPinbar_btn.save {
    width: 65px;
}
.mapPinBar-group-container .mapPinBar_changes_btn .mapPinbar_btn .spinner-container {
    min-height: 20px;
}
.mapPinBar-group-container
    .mapPinBar_changes_btn
    .mapPinbar_btn
    .spinner-container
    .loading-spinner {
    width: 10px;
    height: 10px;
}
.mapPinBar-group-container .mapPinBar_changes_btn .mapPinbar_btn:hover {
    color: var(--clr-red-98);
}

.mapPinBar-group-container .mapPinBar_changes_btn .mapPinbar_btn svg path {
    transition: all 0.3s ease-in;
}
.mapPinBar-group-container .mapPinBar_changes_btn .mapPinbar_btn:hover svg path {
    fill: var(--clr-red-98);
}

.mapPinBar-group {
    min-height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
}

.mapPinBar-group .group-bar-items {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: -webkit-max-content 7px 1fr;
    -ms-grid-columns: max-content 7px 1fr;
    grid-template-columns: -webkit-max-content 1fr;
    grid-template-columns: max-content 1fr;
    place-items: center;
    cursor: pointer;
    gap: 7px;
    padding: 5px;
}

.mapPinBar-group span {
    color: var(--clr-blue);
    font: normal normal 400 14px/20px var(--fontArial);
    display: inline-block;
    text-transform: capitalize;
}

.overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    /* min-width: 6ch; */
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 10ch;
}

.mapPinBar-group-list {
    cursor: pointer;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-transform: capitalize;
}
#rotate-group-icon {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}

.mapPinBar-group-list span {
    color: var(--clr-blue);
    font: normal normal 400 16px/24px var(--fontArial);
}

.mapPinBar-group-list-content {
    padding-left: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    /* bottom: 10px; */
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
    list-style: none;
    display: none;
    background-color: var(--grey-ee);
    color: #000;
    -webkit-box-shadow: 1px -1px 10px 1px var(--blue-90), -1px 1px 10px 1px var(--blue-90);
    box-shadow: 1px -1px 10px 1px var(--blue-90), -1px 1px 10px 1px var(--blue-90);
}

.mapPinBar-group-list-content.active {
    overflow-y: scroll;
    max-height: 500px;
    display: -ms-grid;
    display: grid;
}

.mapPinBar-group-list-content li {
    padding: 10px 15px;
}

.mapPinBar-group-list-content li:is(:active, :focus, :focus-within, :focus-visible, :hover),
.mapPinBar-group-list-content li:is(:active, :focus, :focus-within, :focus-visible, :hover) a {
    background-color: #fff;
    color: var(--clr-red-98);
}

.pin-hide-effect {
    border-radius: 15px;
    border: 1px solid var(--clr-blue);
}

/* -------------------------- */
/* Map Data Tabular functionlaity section */
/* -------------------------- */

.mapData-tabular-functionality {
    background: var(--grey-ee);
    -webkit-box-shadow: 0px 2px 0px var(--grey-99), 0px -3px 0px var(--grey-99);
    box-shadow: 0px 2px 0px var(--grey-99), 0px -3px 0px var(--grey-99);

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 20px;
    opacity: 1;
    gap: 12px;
}

@media (max-width: 1000px) {
    .mapData-tabular-functionality {
        flex-direction: column;
        align-items: center;
        flex-wrap: nowrap;
        gap: 12px; /* Change flex direction to column for smaller screens */
    }
}

.mapData-tabular-functionality > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.mapData-tabular-functionality .tabular-seacrhBar {
    position: relative;
    min-width: 320px;
    margin: auto 20px;
    border: 1px solid var(--grey-99);
    border-radius: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 15px;
}

.mapData-tabular-functionality .tabular-seacrhBar input,
.mapData-tabular-functionality .tabular-seacrhBar input:focus {
    font: normal normal normal 14px/20px var(--fontArial);
    color: var(--grey-99);
    height: 30px;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    caret-color: var(--grey-99);
}

.mapData-tabular-functionality .tabular-seacrhBar input::-webkit-input-placeholder,
.mapData-tabular-functionality .tabular-seacrhBar input::-moz-placeholder,
.mapData-tabular-functionality .tabular-seacrhBar input:-ms-input-placeholder,
.mapData-tabular-functionality .tabular-seacrhBar input::-ms-input-placeholder,
.mapData-tabular-functionality .tabular-seacrhBar input::placeholder {
    color: var(--grey-99);
}

.tabular-copydata {
    position: relative;
    display: flex;
    align-items: center;
    gap: 7px;
    height: 35px;
    margin-bottom: 0;
    background-color: transparent;
    /* border: 1px solid var(--grey-99); */
    border-radius: 50px;
    justify-content: space-between;
    cursor: pointer;
}

.tabular-copydata p {
    font: 500 16px / 20px var(--fontArial);
    color: var(--clr-blue);
}
.tabular-copydata svg {
    width: 20px;
    height: 35px;
    fill: var(--clr-blue);
}
.tabular-copydata svg path {
    fill: var(--clr-blue);
}

.tabular-copydata .copy-data-type-box {
    position: absolute;
    display: none;
    background-color: var(--grey-ee);
    left: 94px;
    top: -40px;
    z-index: 1;
    padding: 5px 0px;
    border-radius: 5px;
    box-shadow: -6px 1px 10px -6px #105d8d33, 0px 0px 15px -8px #000;
}
.tabular-copydata:hover .copy-data-type-box {
    display: block;
}
.tabular-copydata .copy-data-type-box button {
    font: 500 16px / 18px var(--fontArial);
    border: none;
    width: 145px;
    height: 32px;
    text-align: left;
    padding: 0px 15px;
    color: var(--clr-blue);
    background-color: var(--grey-ee);
}
.tabular-copydata .copy-data-type-box button:hover {
    background-color: var(--white-fa) !important;
}

/* .tabular-copydata:hover{} */

/* .tabular-copydata::before {
    position: absolute;
    content: "";
    height: 34px;
    width: 32px;
    left: 0px;
    bottom: -1px;
    background-color: transparent;
    border-radius: 20px 0 0px 20px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    border: 1px solid var(--clr-red-98);
} */
/* .tabular-copydata.active::before {
    -webkit-transform: translateX(calc(100% + 6px));
    -ms-transform: translateX(calc(100% + 6px));
    transform: translateX(calc(100% + 6px));
    border-radius: 0 20px 20px 0;
} */

/* button.tabular-copydata {
    font: normal normal normal 14px/20px var(--fontArial);
    color: #fff;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 20px;
    opacity: 1;
    padding: 0 10px;
    width: 140px;
    height: 34px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0 5px;
}

button.tabular-copydata span {
    padding-right: 10px;
} */

.tabular-paginationBar,
.tabular-sortBar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 4px;
}

.tabular-paginationBar:not(:last-child) {
    margin-right: 15px;
}

.tabular-paginationBar label,
.tabular-sortBar label {
    font: normal normal normal 14px/20px var(--fontArial);
    color: var(--clr-blue);
    min-width: 120px;
    text-align: right;
    margin-bottom: 0;
}

.tabular-paginationBar select,
.tabular-sortBar select {
    display: inline-block;
    width: 100%;
    margin-right: 5px;
    padding: 5px 24px 5px 4px;
    font: normal normal normal 14px/20px var(--fontArial);
    color: var(--clr-blue);
    background: url(/src/assets/images/svg/chevronDown.svg);
    background-repeat: no-repeat;
    background-position: right;
    vertical-align: middle;
    background-color: var(--grey-ee);
    border-radius: 0.55rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    cursor: pointer;
}

.tabular-paginationBar select:focus-visible,
.tabular-sortBar select:focus-visible {
    outline: none;
    border: none;
}

.tabular-paginationBar .btns-group {
    margin-left: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 5px;
}

.tabular-paginationBar .arrow-icon {
    text-decoration: none;
    cursor: pointer;
    color: #fff;
    background-color: transparent;
    -webkit-transition: 0.25s;
    -o-transition: 0.25s;
    transition: 0.25s;
    padding: 0;
    border: none;
    border-radius: 50%;
}

.tabular-paginationBar .arrow-icon svg {
    width: 18px;
    color: #fff;
    background-color: transparent;
    border-radius: 50%;
}

.tabular-paginationBar .arrow-icon:disabled {
    opacity: 0.3;
}

.table-sortPagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* position: absolute;
    right: 3px;
    top: 16px; */
}

/* -------------------------- */
/* MapsTableData Tabular component */
/* -------------------------- */
.mapTabular-TableView--container {
    /* padding: 0 10px; */
    margin-bottom: 20px;
    margin-left: 5px;
    background-color: #eee;
    -webkit-box-shadow: -2px 4px 4px 1px #105d8d33;
    box-shadow: -2px 4px 4px 1px #105d8d33;
    border-radius: 0 0 10px 10px;
    /* overflow-x: auto; */
    max-height: 450px;
    position: relative;
}

.mapTabular-TableView--container .table-container {
    overflow-x: auto;
    max-height: 450px;
}
/* #google_map_container {
    height: clamp(100vh, 100vh, 100vh);
} */
.mapData-tabular--table {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 5px !important;
}

.mapData-tabular--table thead {
    position: relative;
    background-color: transparent;
}

.mapData-tabular--table th {
    font: normal normal 600 15px/20px var(--fontArial);
    color: var(--clr-blue);
    padding: 10px !important;
    text-transform: capitalize;
    border: 1px solid var(--grey-d6);
    border-bottom: none;
    min-width: 100px;
}

.mapData-tabular--table tr th:first-child {
    border-radius: 10px 0 0 0;
    /* border: none;
    min-width: 60px; */
}

.mapData-tabular--table.create tr th:first-child {
    border: 1px solid var(--grey-d6);
}

/* .mapData-tabular--table tr td:first-child {
    border: none;
    width: 0;
} */
.mapData-tabular--table.create tr td:first-child {
    border: 1px solid var(--grey-d6);
    width: 0;
}

.mapData-tabular--table th:last-child {
    border-radius: 0 10px 0 0;
}

.mapData-tabular--table td {
    vertical-align: middle !important;
    border: 1px solid var(--grey-d6);
    color: var(--greyc-32);
    font: normal normal normal 14px/18px var(--fontArial);
    padding: 10px !important;
    min-width: 130px;
}

.mapData-tabular--table input {
    border: none;
    background-color: transparent;
    text-transform: none !important;
    /* cursor: pointer; */
}

.EditMap-TableView--container .rdg {
    background: #eeeeee 0% 0% no-repeat padding-box;
    -webkit-box-shadow: -3px 3px 10px #105d8d4d;
    box-shadow: -3px 3px 10px #105d8d4d;
    border-radius: 10px;
    max-height: 300px;
    font-family: SourceSansPro-Regular;
}

.EditMap-TableView--container .rdg .rdg-row .rdg-cell {
    background-color: #eeeeee;
    color: var(--greyc-32);
    text-transform: none !important;
}

.EditMap-TableView--container .rdg .rdg-row .rdg-cell:first-child {
    text-align: right;
    padding-right: 20px;
}

.EditMap-TableView--container .rdg .rdg-header-row .rdg-cell {
    background-color: var(--light-blue);
    color: #eeeeee;
    border: 0;
    text-transform: none !important;
}

.editmap-colorGrids {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: auto 10px auto;
    grid-template-columns: auto auto;
    gap: 10px;
}

/* MapsTableData Grid component */
.mapTabular-gridView--container {
    display: block;
    margin-bottom: 10px;
    padding: 0 10px;
    position: relative;
    background-color: #cdcfd5;
    /* -webkit-box-shadow: -3px 3px 5px #105d8d33;
    box-shadow: -3px 3px 5px #105d8d33; */
}

.mapTabular-gridView--container .header {
    height: 40px;
    background-color: var(--light-blue);
    border-radius: 10px 10px 0 0;
    position: relative;
}

.mapTabularGridView--item {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 10px;
    /* -webkit-box-shadow: 4px 4px 12px 1px #105d8d33;
    box-shadow: 4px 4px 12px 1px #105d8d33; */
    width: auto !important;
    border-radius: 10px;
    padding-right: 1rem;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
    background-color: var(--grey-ee);
}

.mapTabularGridView--item.active {
    background-color: var(--white-fc);
}

.gridViewItem-details {
    padding: 10px;
}

.mapTabularGridView--item .color-block {
    display: block;
    height: 100%;
    width: 16px;
    min-width: 10px;
    border-radius: 5px 0 0 5px;
    min-height: 120px;
}

.gridViewItem-details h5 {
    font: normal normal 600 16px/18px var(--fontArial);
}

.gridViewItem-details p,
.gridViewItem-details h6 {
    font: normal normal normal 14px/20px var(--fontArial);
    color: var(--clr-blue);
    margin: 0 0 3px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    max-width: 36ch;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
    gap: 10px;
}

.gridViewItem-details p img,
.gridViewItem-details h6 img {
    width: 12px;
}

.mapTabular-gridView-details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow-x: scroll;
    max-height: 175px;
}

.mapTabular-gridView--container .icon {
    background: #fff;
    border: none;
    outline: none;
    border-radius: 50%;
    cursor: pointer;

    padding: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    --size: 2rem;
    width: var(--size);
    height: var(--size);
}

.mapTabular-gridView--container .icon.left {
    -webkit-transform: rotate(-180deg) translateY(50%);
    -ms-transform: rotate(-180deg) translateY(50%);
    transform: rotate(-180deg) translateY(50%);
    left: 0.5rem;
}

.mapTabular-gridView--container .icon.right {
    right: 0.5rem;
}

/* -------------------------- */
/* Table List Grid Buttons */
/* -------------------------- */
.listGrid-btn--table {
    position: absolute;
    right: 10px;
    top: 2px;
}

.listGrid-btn--table img {
    max-width: 35px;
}

.listGrid-btn--table button {
    background: transparent;
    border: none;
    color: white;
}

/* -------------------------- */
/* Edit My Map Component */
/* -------------------------- */
/* EditMap Warning Modal */
/* //////////bulk MOdal Css */

/* .edit-modal-wrapper {
    position: relative;
    background-color: var(--grey-ee);
    border-radius: 5px;
    padding: 10px 15px 20px;
    pointer-events: auto;
    min-width: 400px;
}

.edit-modal-wrapper p {
    margin: 0;
    font: 500 17px/24px var(--fontArial);
}

.edit-modal-wrapper .edit-modal-wrapper-title {
    font: normal normal 600 20px/24px var(--fontArial);
    color: var(--clr-blue);
    text-align: center;
    margin-bottom: 10px;
}
.edit-modal-wrapper .edit-modal-scroll-container {
    margin: 15px 0;
    max-height: 100px;
    overflow: auto;
}

.edit-modal-wrapper .content {
    font: 500 15px/24px var(--fontArial);
    margin-top: 13px;
}
.edit-modal-wrapper .edit-modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 24px;
}

.edit-modal-wrapper .share-close-icon {
    text-align: right;
}
.edit-modal-wrapper h5 {
    margin-top: -10px;
}
.edit-modal-wrapper .share-close-icon {
    text-align: right;
}

.edit-modal-wrapper .share-close-icon button {
    color: var(--grey-b7);
    cursor: pointer;
    z-index: 999999;
    border: none;
}

.del-edit-outline-btn {
    background-color: transparent;
    color: var(--red-90) !important;
    font: normal normal normal 16px/24px var(--fontArial);
    width: auto;
    border: 2px solid var(--red-90) !important;
    padding: 6px 30px;
    -webkit-box-shadow: none;
    border-radius: 50px;
    box-shadow: none;
    min-height: 40px;
    min-width: 120px;
} */

/* //////////edit MOdal Css */
/* EditMap Warning Modal */
.editMap--container {
    background: #eeeeee 0% 0% no-repeat padding-box;
}

.component-title {
    font-size: 28px;
    letter-spacing: 0px;
    color: #eeeeee;
}

.form-control-custom {
    background: #eeeeee33 0% 0% no-repeat padding-box;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    border-radius: 2px;
    color: #eee;
    display: block;
    width: 100%;
    height: calc(1.5em + 1.2rem + 0.0625rem);
    padding: 0.6rem 0.75rem;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.form-control-custom:focus-visible {
    outline: 1px solid var(--clr-red-98);
    -webkit-box-shadow: -3px 3px 10px #105d8d;
    box-shadow: -3px 3px 10px #105d8d;
}

.createMap-formgroup .formgroup-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: 10px;
    position: relative;
}

.createMap-formgroup .formgroup-flex label input:checked ~ span {
    color: var(--clr-blue);
}

.editMap-fields-wrapper .link-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 30px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.editMap-fields-wrapper .info-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 5px;
}

.editMap-fields-wrapper .link-wrapper button {
    border: none;
    background-color: transparent;
    padding: 0;
    position: absolute;
    right: 10px;
}

.editMap-fields-wrapper .link-wrapper button img {
    width: 20px;
}

.editMap-fields-wrapper .info-wrapper {
    margin-left: 15px;
}

.editMap-fields-wrapper .info-wrapper span {
    color: var(--clr-blue);
    font: normal normal normal 12px/12px var(--fontArial);
}

/* Customized checkbox Mark styling*/
.custom-label {
    display: block;
    position: relative;
    color: var(--clr-blue);
    padding-left: 24px;
    margin-bottom: 15px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    --size: 16px;
    position: absolute;
    top: 0;
    left: 0;
    height: var(--size);
    width: var(--size);
    background-color: #eee;
    border: 1px solid var(--grey-99);
    border-radius: 2px;
}

.createMap-formgroup input span {
    font-weight: 600;
}

.custom-label input:checked ~ .checkmark {
    background-color: var(--clr-blue);
    border: none;
}

.custom-label .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.custom-label input:checked ~ .checkmark:after {
    display: block;
}

.custom-label .checkmark:after {
    left: 5px;
    top: 0px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

/* end of checkbox mark style customized */

.edit-map-form label {
    font-size: 14px;
    line-height: 20px;
    color: #eeeeeecc;
    margin-bottom: 0;
}

.form-check input[type="radio"] + .form-check-label::before,
.form-check input[type="radio"] + .form-check-label::after {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 2px solid var(--grey-99);
}

.form-check input[type="radio"]:checked + .form-check-label::after {
    background-color: var(--clr-red-98);
    border: 3px solid var(--clr-red-98);
}

.form-check input[type="radio"]:checked + .form-check-label::before {
    background-color: TRANSPARENT;
    border: 2px solid var(--clr-red-98);
}

.form-check .form-check-label {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-left: 1.375rem;
    padding-top: 3px;
    font: normal normal normal 14px/16px var(--fontArial);
    color: var(--clr-blue);
    margin-bottom: 0;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.create-map-radiocheck span {
    font: normal normal normal 12px/16px var(--fontArial);
}

.form-check .form-check-label strong {
    color: var(--clr-blue);
}

.eye-password-toggle {
    position: absolute;
    top: 8px;
    right: 5px;
    margin-top: 0 !important;
    cursor: pointer;
}

.form-check .form-check-label span {
    margin-top: 10px;
}

.edit-map-radiocheck label {
    font-size: 16px !important;
    line-height: 22px !important;
    font-weight: 600 !important;
    letter-spacing: 0.02px;
    color: #eeeeee !important;
    padding-left: 30px !important;
}

.edit-mapViewShow-contain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
}

.context-map-options {
    display: none;
    position: absolute;
    z-index: 99999999;
}

.mapRecords-container {
    display: -ms-grid;
    display: grid;
    grid-auto-flow: row;
    gap: 5px;
    margin-bottom: 10px;
    margin-left: 10px;
}

.mapRecords-container .records {
    font-size: 14px;
    font-family: var(--fontArial);
    background-color: var(--blue-d9);
    height: 5px;
}
.mapRecords-container .map_record_files_text {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mapRecords-container .result {
    background: var(--clr-green);
    height: 5px;
}

.map-record-counter--wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: start;
}

.records-error button {
    display: flex;
    align-items: center;
    gap: 2px;
    border: none;
    padding: 0;
    margin: 4px 0;
    background-color: transparent;
    text-decoration: underline;
    color: var(--clr-warning-dark);
    font: normal normal normal 14px/22px var(--fontArial);
}
.records-error button svg {
    height: 15px;
}
.records-error button svg Path {
    fill: var(--clr-warning-dark);
}

.failedRecords-row--wrapper {
    background-color: #eee;
    border-radius: 10px;
    -webkit-box-shadow: -2px 4px 8px 2px #105d8d33, 2px -4px 8px 2px #105d8d33;
    box-shadow: -2px 4px 8px 2px #105d8d33, 2px -4px 8px 2px #105d8d33;
    max-height: 500px;
    width: 100%;
    max-width: 420px;
    position: absolute;
    right: 0;
    top: 80px;
    padding: 20px;
}

.failedRecords-row--wrapper .content {
    max-height: 200px;
    overflow: auto;
}

.failedRecords-row--wrapper .header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.failedRecords-row--wrapper .header div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 15px;
}

.failedRecords-row--wrapper .header div h5 {
    font: normal normal 600 14px/24px var(--fontArial);
    color: var(--clr-blue);
}

.failedRecords-row--wrapper .header button {
    border: none;
    padding: 0;
    position: absolute;
    right: 5px;
    top: 5px;
    background-color: transparent;
}

.failedRecords-row--wrapper .content p {
    font: normal normal normal 14px/18px var(--fontArial);
    color: var(--clr-blue);
}

.failedRecords-row--wrapper .footer p {
    font: normal normal normal 11px/16px var(--fontArial);
    color: var(--clr-blue);
    margin-top: 10px;
}

.failedRecords-row--wrapper .footer button {
    border: none;
    padding: 0;
    background-color: transparent;
    text-decoration: underline;
    color: var(--clr-blue);
    font: normal normal 600 11px/16px var(--fontArial);
}

.failedRecords-row--wrapper .footer button:hover,
.failedRecords-row--wrapper .footer button.active,
.failedRecords-row--wrapper .footer button:hover svg path,
.failedRecords-row--wrapper .footer button.active svg path {
    color: var(--clr-red-98);
    fill: var(--clr-red-98);
}

.failedRecords-row--wrapper .footer a:hover {
    text-decoration: none;
}

.edit-map-viewShow {
    position: relative;
    display: block;
    height: 520px;
}

.grid-twoPerRow {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 20px 1fr;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 20px;
}

.color-box-show {
    width: 15px;
    height: 15px;
    display: block;
    border-radius: 3px;
}

.editmap-colorGrids .group-color-show {
    font: normal normal normal 14px/20px var(--fontArial);
    color: var(--clr-blue);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    gap: 0px 5px;
    text-transform: capitalize;
    background-color: transparent;
    border: 2px solid var(--grey-99);
    border-radius: 4px;
    padding: 5px;
    width: auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.map-colorPicker {
    position: relative;
    margin-bottom: 15px;
    margin-left: 10px;
}

.hue-picker {
    width: auto !important;
}

#standard-options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: var(--grey-ee);
    width: 330px;
}

.editMap-shapes {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    gap: 15px;
    margin-bottom: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.editMap-shapes .box-shadow-simple {
    width: 55px;
    height: auto;
    padding: 0;
    aspect-ratio: 1 / 1;
}

.editMap-markerOptions .createMap-marker-box {
    margin-bottom: 16px;
}

.editMap-markerOptions h6 {
    font-size: 20px;
    line-height: 28px;
    color: var(--greyc-32);
    margin-bottom: 20px;
}

.edit-map-icon {
    margin-right: 15px;
}

.edit-map-icon img {
    width: 70px;
}

.mapStyle-Btn {
    border: 2px solid var(--grey-d6);
    width: 60px;
    padding: 0;
    border-radius: 7px;
    margin: 0 0 5px 0;
    background-color: transparent;
}

.mapStyle-Btn.active,
.mapMarker-Btn.active,
.mapStyle-Btn:hover,
.mapMarker-Btn:hover {
    border: 3px solid var(--clr-red-98);
}

.group-color-show.active {
    border: 2px solid var(--clr-red-98);
}
.group-color-show:hover {
    background-color: var(--grey-d6);
}

.createMap-marker-box {
    width: 350px;
}

.createMap-marker-box > h4 {
    font-size: 20px;
    line-height: 28px;
    color: var(--clr-blue);
    margin-bottom: 20px;
}

.createMap-marker-box h6 {
    font: normal normal 600 15px/20px var(--fontArial);
    color: var(--blk-96);
    margin-bottom: 5px;
    margin-top: 0;
}

.mapStyle-Btn img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mapMarker-Btn {
    border: 2px solid var(--grey-d6);
    border-radius: 6px;
    --size: 60px;
    height: var(--size);
    width: var(--size);
    padding: 0;
}

.mapMarker-Btn img {
    max-width: 50px;
    max-height: 50px;
    padding: 10px;
}

.grid-fourPerRow {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 00px 1fr 00px 1fr;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px 0;
    margin-bottom: 0px;
}

.EditMap-TableView--container input {
    background: #eeeeee 0% 0% no-repeat padding-box;
    border: 1px solid var(--greyc-32);
}

.EditMap-TableView--container input:focus-visible {
    background: #d2dde4 0% 0% no-repeat padding-box;
    outline: 1px solid var(--clr-red-98);
}

.icon-upload-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 0.0625rem solid transparent;
    padding: 12px 10px;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 5px;
    -webkit-box-shadow: 3px 3px 6px #b8b9be, -3px -3px 6px #ffffff;
    box-shadow: 3px 3px 6px #b8b9be, -3px -3px 6px #ffffff;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    margin: 0 10px;
}

.icon-upload-btn > input {
    display: none;
}

.icon-upload-btn label {
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    cursor: pointer;
}

.upload-button {
    cursor: pointer;
    text-align: center;
}

.hue-horizontal {
    border-radius: 20px !important;
}

.markerOption-collapse-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: 10px;
    gap: 20px;
    padding-left: 15px;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--grey-d6);
}

.markerOption-collapse {
    font: normal normal 600 16px/22px var(--fontArial);
    color: var(--grey-99);
    border: none;
    padding: 10px 10px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent;
}

.markerOption-collapse.active,
.markerOption-collapse:hover {
    color: var(--clr-red-98);
}

.markerOption-collapse.active::after {
    content: "";
    display: block;
    position: absolute;
    height: 4px;
    width: 130px;
    background: var(--clr-red-98);
    border-radius: 4px 4px 0px 0px;
    bottom: 0px;
    left: 50%;
    -webkit-transform: translate(-50%) rotate(0deg);
    -ms-transform: translate(-50%) rotate(0deg);
    transform: translate(-50%) rotate(0deg);
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.editMap-markerOption--container {
    position: absolute;
    background-color: var(--grey-ee);
    z-index: 1;
    bottom: 0px;
    top: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 20px 0 15px;
    /* height: calc(100% - 70px); */
    margin-bottom: 40px;
}

.map-options-container {
    position: relative;
}

.markerOption-collapse p {
    text-decoration: underline;
    margin: 0;
}

.markerOption-collapse span {
    padding-left: 20px;
    color: #32373c4d;
    font-size: 22px;
}

.editMap-title-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0 10px;
    padding-left: 10px;
    margin-bottom: 10px;
}

.editMap-title-bar img {
    width: 50px;
    cursor: pointer;
}

.editMap-title-bar h4 {
    font: normal normal 600 16px/20px var(--fontArial);
    color: var(--clr-blue);
}

/* -------------------------- */
/* Create Map component */
/* -------------------------- */
.createMap--container {
    /* margin-right: 10px; */
}

.createMap-nav {
    position: sticky;
    width: 100%;
    top: 0px;
    background-color: #eee;
    z-index: 3;
    margin-bottom: 10px;
    padding-bottom: 10px;
    margin-left: 10px;
    padding-top: 20px;
}

.createMap-nav .fa-ban {
    padding-right: 10px;
}

.createMap-nav h5 {
    font: normal normal normal 18px/26px var(--fontArial);
    color: var(--clr-blue);
    cursor: pointer;
}

.createMap-nav .fa-arrow-left {
    padding-right: 15px;
    color: var(--grey-99);
}

.createMap-nav button.cancel {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: var(--clr-blue);
    border: none;
    text-decoration: underline;
    font: normal normal normal 16px/24px var(--fontArial);
    width: 110px;
}

.createMap-nav button.cancel:hover {
    border: 2px solid var(--clr-blue);
    border-radius: 50px;
    text-decoration: none;
}

.createMap-nav button.save,
.createMap-nav button.save:focus {
    background-color: var(--clr-red-98);
    border-radius: 20px;
    color: #fff;
    font: normal normal normal 15px/24px var(--fontArial);
    padding: 8px 20px;
    margin: auto 0 auto 10px;
    width: 120px;
    border: 2px solid var(--clr-red-98);
    -webkit-box-shadow: none;
    box-shadow: -2px 4px 10px #105d8d33;
}
.createMap-nav button.save.update-loc {
    width: 165px !important;
}

.dropClass55 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    /* min-height: 220px; */
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    border-radius: 5px;
    /* background-color: var(--grey-99); */
    overflow: hidden;
    padding: 10px;
    position: relative;
    border: 3px dotted var(--grey-99);
    /* border: 2px solid var(--clr-red-98); */
    -webkit-transition: all ease-in-out 0.5s;
    -o-transition: all ease-in-out 0.5s;
    transition: all ease-in-out 0.5s;
    max-height: 600px;
    /* max-width: 1200px;
      margin: 0 auto; */
}

/* .dropClass55:hover {
    border: 2px solid var(--clr-red-98);
} */

.dropClass55:hover .hoverClass {
    opacity: 1 !important;
}

.createMap-table {
    width: 100%;
    border: 1px solid transparent;
    white-space: nowrap;
    border-collapse: collapse;
}

.createMap-table thead th {
    border-bottom: 1px solid rgb(183, 28, 28);
    border-right: 1px solid #333;
    text-transform: capitalize;
    font-size: 14px;
    height: 30px;
}

.createMap-table tbody td {
    border: 1px solid #333;
    height: 40px;
    padding: 5px;
    overflow: hidden;
    max-width: 170px;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    vertical-align: middle;
    font-size: 12px;
    height: 24px;
}

.hoverClass {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    opacity: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.3);

    -webkit-box-shadow: inset -10px 3px 40px #134960;
    box-shadow: inset -10px 3px 40px #134960;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    /* border: 10px solid var(--grey-99); */
}

.hoverClass p {
    font: 500 normal normal 18px/26px var(--fontArial);
    color: var(--white-fff);
}
.hoverClass span {
    font: 500 normal normal 16px/22px var(--fontArial);
    color: var(--white-fff);
}

.createMapMapMarkers--modal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #eee;
    width: 100%;
    max-width: 410px;
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 10px;
}

.createMapMapMarkers--modal img {
    width: 30px;
}

.createMapMapMarkers--modal a {
    font-weight: 600;
    text-decoration: underline;
}

.createMapMapMarkers--modal .cta-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 30px;
}

.createMap-csvBox {
    position: relative;
}
.createMap-csvBox > section {
    margin: 0 16px;
}

.createMap-textarea {
    width: 100%;
    height: 270px;
    overflow: auto;
    background: rgb(255, 255, 255);
    /* outline: 2px solid var(--light-blue); */
    border-radius: 10px;
}

.createMap-Instructions {
    font: normal normal normal 18px/24px var(--fontArial);
    color: var(--clr-blue);
    text-align: center;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.createMap-Instructions button {
    border: none;
    padding: 0;
    background-color: transparent;
    font-weight: 600;
    text-decoration: underline;
    margin-left: 5px;
    margin-right: 5px;
}

.createMap-Instructions a {
    font: normal normal normal 14px/18px var(--fontArial);
}

/* .createMap-Instructions a:hover, */
.createMap-Instructions button:hover,
.createMap-Instructions button:hover svg path {
    text-decoration: underline;
    color: var(--clr-red-98);
    fill: var(--clr-red-98);
}

.createMap-formgroup {
    position: relative;
    margin-bottom: 20px;
}

.createMap-formgroup > div {
    min-width: 150px;
}

.createMap-formgroup label {
    font: normal normal normal 14px/18px var(--fontArial);
    color: var(--grey-99);
}

.createMap-formgroup label.clr-light {
    color: var(--grey-99);
}

.custom-label.clr-greyc {
    color: var(--greyc-32);
}

.createMap-formgroup span.info {
    font: normal normal normal 12px/14px var(--fontArial);
    padding-left: 15px;
}

.createMap-formgroup select {
    font: normal normal normal 12px/18px var(--fontArial);
    color: var(--blk-96);
    text-transform: capitalize;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid var(--grey-99);
    height: 44px;
}

.createMap-formgroup select,
.userprofile-container .form-group select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url("../assets/images/svg/chevronDown.svg");
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 52%;
    cursor: pointer;
    text-transform: unset;
    font: normal normal normal 14px/20px var(--fontArial);
    color: var(--blk-96);
}

.createMap-formcontrol,
.createMap-formcontrol:focus-visible {
    display: block;
    width: 100%;
    height: 40px;
    padding: 0 50px 0 15px;
    font: normal normal normal 14px/18px var(--fontArial);
    color: var(--clr-blue);
    background-color: #eeeeee;
    border: 1px solid var(--grey-99);
    border-radius: 5px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    outline: none;
}

.createMap-video--container {
    background: transparent -o-linear-gradient(234deg, #d6d7d855 0%, #d6d7d8 100%);
    background: transparent linear-gradient(216deg, #d6d7d855 0%, #d6d7d8 100%);
    border-radius: 10px;
    margin-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 380px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.createMap-video--container img {
    width: 90px;
}

.createMap-video--container p {
    font: normal normal normal 14px/18px var(--fontArial);
    color: var(--white-fff);
}

.createMap-tabs-wrapper {
    display: -ms-grid;
    display: grid;
    grid-auto-flow: column;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    /* grid-template-columns: repeat(auto-fit, minmax(330px, max-content)); */
    gap: 30px;
    padding: 0px 0px 0px 10px;
}

.vanishIn {
    -webkit-animation-name: vanishIn;
    animation-name: vanishIn;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
}

@-webkit-keyframes vanishIn {
    0% {
        opacity: 0;
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-transform: scale(2, 2);
        transform: scale(2, 2);
        -webkit-filter: blur(90px);
        filter: blur(90px);
    }

    100% {
        opacity: 1;
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        -webkit-filter: blur(0px);
        filter: blur(0px);
    }
}

@keyframes vanishIn {
    0% {
        opacity: 0;
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
        -webkit-filter: blur(90px);
        filter: blur(90px);
    }

    100% {
        opacity: 1;
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-filter: blur(0px);
        filter: blur(0px);
    }
}

/* -------------------------- */
/* SaveMapModal Component */
/* -------------------------- */
.create-map-radiocheck--inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 15px;
    gap: 30px;
}

.create-map-radiocheck--inner div {
    margin-right: 15px;
    min-width: 175px;
}

.create-map-radiocheck--inner .map-mode--wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.create-map-radiocheck--inner .map-mode-img {
    width: 100%;
    max-width: 120px;
    height: 90px;
    margin-top: 10px;
    border-radius: 5px;
    -o-object-fit: cover;
    object-fit: cover;
}

.ReactModal__Overlay--after-open {
    z-index: 99;
    background-color: var(--greyc80-32) !important;
}

.ReactModal__Content--after-open {
    width: 570px !important;
    padding: 0 !important;
}

.saveMapModal--container .header {
    background-color: var(--light-blue);
    display: block;
    padding: 10px;
}

.saveMapModal--container .header h4 {
    background-color: var(--light-blue);
    font: normal normal normal 22px/26px var(--fontArial);
    color: #fff;
}

.saveMapModal-Cta {
    text-align: center;
    background: rgb(238, 241, 242);
    padding: 15px;
}

.createMap-box-title {
    font: normal normal 600 16px/22px var(--fontArial);
    color: var(--dark-blue);
}

.create-map-radiocheck {
    margin: 20px 0;
}

.create-map-radiocheck h5 {
    font: normal normal normal 18px/22px var(--fontArial);
    margin: 25px 0 15px 0;
    color: var(--clr-blue);
}

.saveMapModal--form {
    padding: 0 20px;
    margin-bottom: 20px;
}

.saveMapModal--form p {
    color: var(--clr-blue);
}

.saveMapModal--form div {
    margin-bottom: 10px;
}

.saveMapModal--form div label {
    margin-bottom: 3px;
    color: var(--grey-99);
}

#my_google_map .gm-style > div:nth-child(6) .gmnoprint {
    display: none;
}

.resize-container {
    position: absolute;
    bottom: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    z-index: 2;
}

.resize-wrapper {
    z-index: 1;
    border: 1px solid var(--grey-b7);
    border-bottom: none;
    background-color: var(--grey-ee);
    padding: 0 10px;
    border-radius: 5px 5px 0 0;
    cursor: row-resize;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.resize-wrapper img {
    width: 20px;
    pointer-events: none;
}

.streetView-custom--btn {
    background: #ffffff;
    -webkit-box-shadow: 0px 0px 5px #105d8d66;
    box-shadow: 0px 0px 5px #105d8d66;
    position: absolute;
    bottom: 28px;
    right: 80px;
    padding: 8px;
}

/* CUSTOM DRAWING TOOL BTNS CSS */
.drawing-manager-wrapper {
    border-radius: 6px;
    margin-top: 10px;
    -o-transition: 0.2s all;
    transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -webkit-box-shadow: 0px 0px 18px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 18px 5px rgba(0, 0, 0, 0.2);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.drawing-manager-wrapper.active {
    -webkit-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    transform: translateY(-50px);
}
.drawing-manager-wrapper .RulerDivBtns {
    background-color: rgba(238, 238, 238, 0.9);
    display: flex;
    padding-right: 5px;
    border-radius: 0px 4px 4px 0px;
    flex-direction: column;
}

.drawing-manager-wrapper .RulerDivBtns button {
    margin-top: 2px;
    /* position: absolute;
    top: 0px; */
}
.drawing-manager-wrapper .RulerDivBtns button:first-child {
    z-index: 1;
}
.drawing-manager-wrapper .RulerDivBtns button:last-child {
    position: absolute;
    top: 0;
}

.custom-drawing-tool--btn {
    background-color: rgba(238, 238, 238, 0.9);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: var(--clr-bg);
    cursor: pointer;
    padding: 0;
    border: 1px solid rgba(238, 238, 238, 0.1);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    padding: 3px;
    margin: 0 -1px;
}

.custom-drawing-tool--btn:hover svg path:last-child {
    fill: var(--clr-red-98);
}

.drawing-manager-wrapper > button:first-child {
    border-radius: 5px 0 0 5px;
    padding: 5px 5px 5px 15px;
}

.drawing-manager-wrapper > button:nth-child(8) {
    border-radius: 0 5px 5px 0;
    padding: 5px 15px 5px 5px;
}

.draw-manager-collapse {
    position: absolute;
    left: 50%;
    top: 100%;
    -webkit-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
}

.draw-manager-collapse button {
    background-color: rgba(238, 238, 238, 0.9);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: none;
    border-radius: 0 0 5px 5px;
    width: 35px;
    -webkit-box-shadow: 0px 0px 18px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 18px 5px rgba(0, 0, 0, 0.2);
}

.draw-manager-collapse button img {
    width: 12px;
}

.custom-drawing-tool--btn.active svg {
    background-color: var(--white-fff);
}

.custom-drawing-tool--btn.active svg path:last-child,
.custom-drawing-tool--btn.active svg g #Triangulation-2,
.custom-drawing-tool--btn.active svg g #Path_1780 {
    fill: var(--clr-red-98);
}

.custom-drawing-tool--btn:last-child.active svg path:first-child {
    fill: var(--clr-red-98);
}

.custom-drawing-tool--btn svg {
    --size: 30px;
    width: var(--size);
    height: var(--size);
    padding: 3px;
}

/* DRAWING DETAIL */
.selectedMarkerError,
.drawing-detail {
    padding: 5px 20px;
    background-color: var(--clr-success);
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
    position: absolute;
    bottom: 40px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 3;
}

.selectedMarkerError h3,
.drawing-detail h3,
.drawing-detail p {
    font: normal normal normal 16px/24px var(--fontArial);
    color: var(--white-fa);
}

.selectedMarkerError {
    background-color: var(--clr-danger);
    border-radius: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 10px;
}

.cirleTool-radius-box {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    font-size: 16px;
    pointer-events: none;
    padding: 3px;
}

/* CREATE GROUP POPUP */
.create-group-container {
    background-color: var(--greyc-32);
    position: fixed;
    inset: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 18;
}

.create-group-container form {
    border-radius: 5px;
    padding: 10px 20px 20px;
    background-color: var(--grey-ee);
    width: 340px;
}

.create-group-wrapper {
    position: relative;
}

.create-group-wrapper .title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.create-group-wrapper .title h5 {
    font: normal normal 600 22px/28px var(--fontArial);
    color: var(--clr-blue);
    text-align: center;
}
.create-group-container .create-group-wrapper .create-group-note {
    padding-top: 1px;
    font: 400 12px/15px var(--fontArial);
    text-align: center;
}
.create-group-container .create-group-wrapper .create-group-note p {
    font: 400 12px/15px var(--fontArial) !important;
}
.create-group-container .create-group-wrapper .create-group-note a {
    text-decoration: underline;
}

.create-group-note {
    padding-top: 1px;
    font: 400 12px/15px var(--fontArial);
    text-align: center;
}

.create-group-note p {
    font: 400 11px/15px var(--fontArial) !important;
}
.create-group-note p a {
    text-decoration: underline;
}

.moveMarker-modal--footer label,
.create-group-wrapper .markerCount,
.create-group-wrapper .area,
.create-group-wrapper .input-box label {
    font: normal normal normal 15px/20px var(--fontArial);
    color: var(--clr-blue);
    margin: 5px 0;
    text-align: center;
}

.moveMarker-modal--footer .input-box {
    width: 100%;
    margin-bottom: 20px;
}

.moveMarker-modal--footer .input-box > div,
.create-group-wrapper .input-box > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.moveMarker-modal--footer .input-box > div span,
.create-group-wrapper .input-box > div span {
    font: normal normal normal 12px/18px var(--fontArial);
    color: var(--grey-99);
}

.moveMarker-modal--footer .input-box input,
.create-group-wrapper .input-box input {
    font: normal normal normal 12px/20px var(--fontArial);
    color: var(--greyc-32);
    width: 100%;
    margin: 0 0;
    padding: 5px 10px;
    border: 1px solid var(--grey-99);
    border-radius: 4px;
    height: 35px;
    background-color: transparent;
}

.moveMarker-modal--footer .input-box input:focus,
.create-group-wrapper .input-box input:focus {
    outline: 2px solid var(--clr-red-98);
    background: var(--blue-d9);
    border: none;
}

.create-group-wrapper .form-actions {
    margin-top: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
}

.create-group-wrapper .form-actions .save,
.create-group-wrapper .form-actions .save:hover {
    background-color: var(--clr-red-98);
    color: white;
    font: normal normal normal 16px/24px var(--fontArial);
    border-radius: 20px;
    width: auto;
    padding: 8px 40px;
    border: none;
    min-width: 120px;
}

.create-group-wrapper .form-actions .cancel {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    font: normal normal normal 14px/20px var(--fontArial);
    color: var(--clr-blue);
    min-width: 120px;
}

.create-group-toggle-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border: 2px solid var(--grey-d6);
    border-radius: 3px;
    padding: 6px;
    cursor: pointer;
    margin: 20px auto 10px;
    height: 40px;
}

.create-group-toggle-wrapper::before {
    position: absolute;
    content: "";
    height: 40px;
    width: 150px;
    left: 8px;
    /* bottom: 2px; */
    background-color: var(--blue-d9);
    border: 2px solid var(--clr-red-98);
    border-radius: 3px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    -webkit-transform: translateX(calc(100% - 10px));
    -ms-transform: translateX(calc(100% - 10px));
    transform: translateX(calc(100% - 10px));
}

.create-group-toggle-wrapper.active::before {
    -webkit-transform: translateX(calc(100% - 159px));
    -ms-transform: translateX(calc(100% - 159px));
    transform: translateX(calc(100% - 159px));
}

/* .create-group-toggle-wrapper button {
      border: none;
      background-color: transparent;
      padding: 4px 10px;
      font: normal normal normal 14px/20px SourceSansPro-Regular, sans-serif;
      color: var(--grey-99);
  } */
.create-group-toggle-wrapper button:first-child,
.create-group-toggle-wrapper.active button:nth-child(2) {
    border: none;
    background-color: transparent;
    font: normal normal normal 14px/20px var(--fontArial);
    color: var(--grey-99);
    width: 140px;
}

.create-group-toggle-wrapper.active button:first-child,
.create-group-toggle-wrapper button:nth-child(2) {
    border: none;
    background-color: transparent;
    font: normal normal normal 14px/20px var(--fontArial);
    color: var(--clr-red-98);
    width: 140px;
    z-index: 1;
}

.form-group select {
    text-transform: capitalize;
}

/* Dashboard Shared Map List */

.sharedMap-user-img {
    --size: 50px;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
}

.sharedMap-user--modal {
    /* position: absolute;
    top: 40px;
    left: 50px; */

    position: fixed;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);

    /* background-color: rgba(238, 238, 238, 0.8); */
    background-color: var(--grey-ee);
    -webkit-box-shadow: -2px 4px 4px 1px #105d8d33;
    box-shadow: -2px 4px 4px 1px #105d8d33;
    -webkit-backdrop-filter: saturate(180%) blur(5px);
    backdrop-filter: saturate(180%) blur(5px);

    padding-top: 10px;
    border-radius: 6px;
    min-width: 180px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 2;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.sharedMap-user--modal .user-img {
    text-align: center;
}

.sharedMap-user--details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: var(--grey-d6);
    position: relative;
    padding: 8px 0;
    border-radius: 0 0px 6px 6px;
}

.sharedMap-user--modal .user-name {
    margin: 10px 0 5px 0;
    font: normal normal 600 14px/16px var(--fontArial);
    color: var(--clr-blue);
    text-transform: capitalize;
    text-align: center;
}

.sharedMap-user--modal .user-post {
    margin: 0 0 5px 0;
    font: normal normal normal 12px/14px var(--fontArial);
    color: var(--clr-blue);
    text-transform: capitalize;
    text-align: center;
}

.sharedMap-user--details .info-icons {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: auto auto auto;
    grid-template-columns: auto auto auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
}

.sharedMap-user--details .info-icons a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 5px 0;
}

.sharedMap-user--details .info-icons a span {
    font: normal normal normal 10px/12px var(--fontArial);
    color: var(--clr-blue);
}

.sharedMap-user--details .info-icons a img {
    width: 15px;
    min-height: 20px;
}

.sharedMap-user--modal .close-modal {
    background-color: transparent;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    cursor: pointer;
}

.sharedMap-sender {
    font: normal normal normal 12px/18px var(--fontArial);
    color: var(--clr-blue);
    margin: 0;
    text-transform: capitalize;
}

.sharedMap-sender img {
    width: 16px;
}

.sharedMap-sender button {
    font: normal normal normal 12px/12px var(--fontArial);
    color: var(--clr-blue);
    border: none;
    background-color: transparent;
    text-transform: capitalize;
}

.sharedMap-sender button:hover {
    text-decoration: underline;
    color: var(--clr-red-98);
}

.sharedMap-modal--container {
    /* position: relative; */
}

.sharedMap-user--modal .close-button {
    border: none;
    background-color: transparent;
    position: absolute;
    left: 2px;
    top: 4px;
    width: 30px;
}

.shareMap-user-item-wrapper label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
}

.shareMap-user-item-wrapper .checkmark {
    left: unset;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto 0;
}

.dim {
    opacity: 0.4;
}

.null-data-response {
    background: rgba(238, 238, 238, 0.8);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    font: normal normal normal 14px/20px var(--fontArial);
    color: var(--clr-blue);
    margin: 0;
    padding: 5px 10px;
    text-align: center;
    border-radius: 0 0 4px 4px;
}

/* Edit Info Marker Modal */
.infoMarker-modal--contanier {
    position: absolute;
    display: block;
    top: 78px;
    background: #eee;
    backdrop-filter: saturate(180%) blur(15px);
    -webkit-backdrop-filter: saturate(180%) blur(15px);
    z-index: 10;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-height: 665px;
    overflow-y: auto;
    padding: 10px 20px 20px;
    -webkit-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.2), 1px 1px 15px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.2), 1px 1px 15px 0px rgba(0, 0, 0, 0.2);
}
.infoMarker-modal--contanier .title {
    border-bottom: 1px solid var(--grey-d6);
    margin-bottom: 10px;
}

.infoMarker-modal--contanier .hero-image {
    max-height: 200px;
}

.infoMarker-modal--contanier .hero-image img {
    width: 100%;
    height: 150px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}

.infoMarker-modal--contanier .marker-details-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: auto 30px auto;
    grid-template-columns: 400px auto;
    gap: 30px;
}

.infoMarker-modal--contanier .marker-details-container .mapName-wrapper p {
    font: normal normal normal 14px/14px var(--fontArial);
    color: var(--clr-blue);
    margin: 5px 0 0 0;
}

.infoMarker-modal--contanier .marker-details-container .mapName-wrapper div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;
}

.infoMarker-modal--contanier .marker-details-container .mapName-wrapper div button {
    border: none;
    padding: 0;
}

.infoMarker-modal--contanier .marker-details-container .mapName-wrapper div h6 {
    font: 600 15px/13px var(--fontArial);
    color: var(--clr-blue);
}

.infoMarker-modal--contanier .marker-details-container .checkinout-wrapper div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 12px;
    /* margin-bottom: 3px; */
}

.infoMarker-modal--contanier .marker-details-container .checkinout-wrapper .pin-number-wrapper {
    margin-left: 10px;
    font: normal normal normal 14px/14px var(--fontArial);
}

.pin-number-wrapper .pin-number-box {
    font: normal normal normal 14px/14px var(--fontArial);
    color: var(--blk-70);
    border: 1px solid var(--blk-70) !important;
    width: 60px;
    height: 20px;
    padding: 4px;
}

.checkinout-wrapper .pin-number-wrapper input {
    width: 60px;
    background-color: transparent;
    border: 1px solid var(--grey-99);
    color: var(--clr-blue);
}

.checkinout-wrapper .pin-number-wrapper input:focus-visible {
    outline: none;
}

.infoMarker-modal--contanier .marker-details-container .checkinout-wrapper div img {
    width: 15px;
}

.marker-details-container .checkinout-wrapper .checkinout-item input[type="datetime-local"] {
    background-color: transparent;
    border: none;
    font: normal normal normal 13px/16px var(--fontArial);
    color: var(--clr-blue);
    width: 150px;
    text-transform: uppercase;
}

.input-date-wrapper .checkinout-date-format {
    position: absolute;
    font-size: 14px;
    z-index: -1;
    color: var(--grey-99);
}

.marker-details-container .checkinout-wrapper .checkinout-item .input-date-wrapper {
    position: relative;
}

.marker-details-container
    .checkinout-wrapper
    .checkinout-item
    input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    background-image: url("../assets/images/chevronlight-left-grey.png");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: right;
    color: transparent;
    cursor: pointer;
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
}

.marker-details-container .checkinout-wrapper .checkinout-item .label-info {
    width: 95px;
}

.infoMarker-modal--contanier .edit-icon {
    width: 14px;
    margin-left: 10px;
}

.markerInfo-formgroup label {
    font: normal normal normal 14px/20px var(--fontArial);
    color: var(--clr-blue);
}
.markerInfo-formgroup .address-label {
    width: 100%;
    position: relative;
    display: flex;
    align-items: end;
}
.markerInfo-formgroup .address-label span {
    position: absolute;
    right: 1%;
}
.markerInfo-formgroup .address-label span svg {
    height: 12px;
    cursor: pointer;
}

.markerInfo-formgroup .input-field {
    border: 1px solid var(--grey-99);
    border-radius: 5px;
    background-color: transparent;
    color: var(--clr-blue);
    display: block;
    font: normal normal normal 13px/18px var(--fontArial);
    height: 30px;
    outline: none;
    padding: 0 15px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    width: 100%;
}
.markerInfo-formgroup .input-field:disabled {
    border: 1px solid var(--grey-b7);
    /* border-radius: 5px; */
    background-color: var(--grey-ee);
    color: var(--grey-b7);
    /* display: block;
    font: normal normal normal 13px/18px var(--fontArial);
    height: 30px;
    outline: none;
    padding: 0 15px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    width: 100%; */
}

.markerInfo-formgroup .input-field.opportunity {
    height: 30px;
    /* background-image: url("../assets/images/svg/dollarSign.svg"); */
    background-repeat: no-repeat;
    background-position: 3%;
    padding-left: 25px;
    position: relative;
}

.markerInfo-formgroup .opputunity-label {
    position: relative;
}

.markerInfo-formgroup .opputunity-label::before {
    content: "$";
    position: absolute;
    left: 13px;
    top: 32px;
    color: var(--clr-blue);
    font: normal normal normal 15px/18px var(--fontArial);
}
.markerInfo-formgroup .opputunity-label::placeholder {
    color: var(--clr-blue);
}

.markerInfo-formgroup .input-field.textarea {
    height: unset;
    padding: 15px;
}

.markerInfo-formgroup .address-sub-field {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0 5px;
    margin-top: 5px;
}

.infoMarker-modal--contanier .title h3 {
    font: normal normal 600 20px/28px var(--fontArial);
    color: var(--clr-blue);
    text-transform: capitalize;
}

.markerInfo-formgroup {
    margin-bottom: 15px;
}

.infoMarker-modal--contanier .marker-details-container select {
    font: normal normal normal 13px/18px var(--fontArial);
    color: var(--clr-blue);
    -webkit-box-shadow: none;
    box-shadow: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url("../assets/images/svg/chevronDown.svg");
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 52%;
    cursor: pointer;
    border: 1px solid var(--grey-99);
    height: 30px;
    padding: 0 15px;
}

/* .infoMarker-modal--contanier .marker-details-container select {
    color: var(--grey-99) !important;
} */

.infoMarker-modal--contanier .marker-details-container select option {
    color: var(--clr-blue);
}

.infoMarker-modal--contanier .header {
    font: normal normal 600 16px/20px var(--fontArial);
    color: var(--clr-blue);
    padding: 0;
    margin: 5px 0;
}

.infoMarker-modal--contanier .limit-wrapper {
    font: normal 14px/20px var(--fontArial);
    color: var(--grey-99);
}

.infoMarker-modal--contanier .basic-info p {
    font: normal normal normal 16px/22px var(--fontArial);
    color: var(--clr-blue);
    margin: 0 0 15px;
    max-width: 150px;
}

.infoMarker-modal--contanier .detail-selectors .field-group input {
    font: normal normal normal 16px/20px var(--fontArial);
    color: var(--grey-99);
}

/* .detail-selectors .calender-container {
    margin-top: 50px;
} */
.detail-selectors .calender-wrapper {
    border: 1px solid var(--grey-99);
    border-radius: 5px;
    padding: 8px 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 20px;
}

.detail-selectors .calender-wrapper label {
    width: 100%;
    font: normal normal normal 13px/13px var(--fontArial);
    position: relative;
}

.detail-selectors .calender-wrapper label .custom-calander-icon-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0 5px;
}

.detail-selectors .calender-wrapper label input {
    opacity: 0;
}

.detail-selectors .calender-wrapper input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    color: transparent;
    cursor: pointer;
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
}

.detail-selectors .calender-wrapper label span:last-child {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: -1;
    font-weight: 600;
}

/* .detail-selectors .note-count-show{
    display: flex;
    align-items: center;
    justify-content: space-between;
} */

.box-shadow-box {
    display: block;
    width: 100%;
    border-radius: 0.55rem;
    -webkit-box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #ffffff;
    box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #ffffff;
    background-color: #eeeeee;
    background-clip: padding-box;
    border: 0.0625rem solid #d1d9e6;
    padding: 10px;
}

/* .shadow-all-blue {
    -webkit-box-shadow: -3px 3px 5px #105d8d33, 3px -3px 7px #105d8d33 !important;
    box-shadow: -3px 3px 5px #105d8d33, 3px -3px 7px #105d8d33 !important;
} */

.box-shadow-simple {
    display: block;
    width: 100%;
    border-radius: 10px;
    -webkit-box-shadow: -3px 3px 5px #105d8d33, 3px -3px 7px #fff;
    box-shadow: -3px 3px 5px #105d8d33, 3px -3px 7px #fff;
    background-color: #eeeeee;
    background-clip: padding-box;
    padding: 10px 10px 15px;
}

.createMap-shadow-simple {
    display: block;
    width: 100%;
    border-radius: 5px;
    padding: 15px;
}

.marker-info-modal-img {
    width: 140px;
}

/* Route Optimization */
.route-optimization-component--container button {
    position: relative;
    display: block;
    background-color: var(--clr-red-98);
    color: white;
    padding: 8px;
    min-width: 100%;
    text-align: center;
    border-radius: 10px;
    text-decoration: none;
    border: none;
    font-size: 14px;
    line-height: 16px;
    font-family: var(--fontArial);
}

/* Map View Dashboard bar (settings, history, layers etc) */
.mapView-options--container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    bottom: 56px;
    z-index: 10;
    -webkit-transition: 0.3s ease all;
    -o-transition: 0.3s ease all;
    transition: 0.3s ease all;
}

.mapView-options--wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px); */
    border: 10px;
    width: 152px;
    -webkit-transition-property: width;
    -o-transition-property: width;
    transition-property: width;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-box-shadow: 1px 1px 10px 0px #105d8d80;
    box-shadow: 1px 1px 10px 0px #105d8d80;
}

.mapData-tabular--container {
    position: relative;
    bottom: 0px;
    z-index: 1;
}

.mapView-options--container button svg {
    width: 30px;
    border-radius: 50%;
    padding: 3px;
}

.mapView-options--container button.active svg > path {
    fill: var(--clr-red-98);
}
.mapView-options--container button:hover svg > path {
    fill: var(--clr-red-98);
}

.mapView-options--container button.history-cta.active svg g path:nth-child(2) {
    fill: var(--clr-red-98);
}

.layers-options button.clickTabs,
.settings-options button.clickTabs,
.history-options button.clickTabs,
.filter-options button.clickTabs {
    position: relative;
    border: none;
    background-color: rgba(238, 238, 238, 0.9);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 5px 4px;
}

.settings-options button {
    border-radius: 2px 0 0 2px;
}

.filter-options button.clickTabs {
    border-radius: 0 5px 5px 0;
}

.mapView-options--wrapper button.active {
    background-color: var(--white-fff);
}

.mapView-layer-list-box,
.mapView-settings-list-box {
    position: absolute;
    bottom: 105px;
    left: 0;
    z-index: 10;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    min-width: 190px;
    background: rgba(238, 238, 238, 0.9);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    box-shadow: 3px -3px 10px var(--clr-box-shadow), -3px 3px 10px var(--clr-box-shadow);
    -webkit-box-shadow: 3px -3px 10px var(--clr-box-shadow), -3px 3px 10px var(--clr-box-shadow);
}

#layer-setting-box {
    bottom: 47px !important;
    z-index: 10 !important;
}

.mapSettings-title-box {
    border-bottom: 1px solid var(--grey-99);
    padding: 5px 15px;
}

.mapSettings-title-box h6 {
    font: normal normal 600 15px/16px var(--fontArial);
    color: var(--clr-blue);
    margin-top: -5px;
}

.mapSettings-title-box span {
    font: normal normal normal 12px/22px var(--fontArial);
    color: var(--grey-99);
    cursor: pointer;
}

.mapSettings-title-box .close-wrap {
    text-align: right;
}

.mapSettings-title-box img {
    width: 20px;
    cursor: pointer;
}

.mapView-layer-list-box ul {
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 5px 5px;
    margin-bottom: 0;
    gap: 0 10px;
}

.mapView-layer-list-box ul li {
    cursor: pointer;
}

.mapView-layer-list-box ul li:hover figure img {
    border: 3px solid var(--clr-red-98) !important;
    padding: 4px;
    background-color: var(--white-fff);
}

.mapView-layer-list-box ul li:hover figcaption {
    color: var(--clr-red-98) !important;
}

.mapView-layer-list-box ul li img {
    --size: 50px;
    height: var(--size);
    width: var(--size);
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    border-radius: 5px;
    -webkit-transition: 0.1s ease all;
    -o-transition: 0.1s ease all;
    transition: 0.1s ease all;
}

.mapView-layer-list-box ul li figure {
    position: relative;
    border: 2px solid transparent;
}

.active-border-blue figure img {
    border: 3px solid var(--clr-red-98) !important;
    border-radius: 10px;
    padding: 4px;
    background-color: var(--white-fff);
}

.active-border-blue figcaption {
    color: var(--clr-red-98) !important;
}

.mapView-layer-list-box ul li figcaption {
    font: normal normal normal 12px/20px var(--fontArial);
    color: var(--clr-blue);
    text-align: center;
    display: block;
    width: 100%;
}

.mapView-settings-list-box ul {
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 10px 0;
    margin-bottom: 0;
}

.mapView-settings-list-box ul.disable {
    pointer-events: none;
}

.mapView-settings-list-box ul li {
    font: normal normal normal 14px/28px var(--fontArial);
    margin: 0px 8px;
    padding: 0px 2px 0px 4px;
    cursor: pointer;
}

.mapView-settings-list-box ul li span {
    padding-left: 5px;
}

.mapView-settings-list-box ul li:is(:hover, :focus, :focus-within, .active, :active) {
    background-color: var(--white-fc);
    /* color: var(--); */
}

.mapView-settings-list-box ul li:is(:hover, :focus, :focus-within, .active, :active) svg path {
    fill: var(--grey-99);
    color: var(--grey-99);
    /* fill: var(--clr-red-98);
    color: var(--clr-red-98); */
}

.mapfilter-settings .group-popup {
    position: absolute;
    left: calc(100% + 10px);
    bottom: 0;
    width: 330px;
    padding: 10px 15px 15px;
    background-color: var(--grey-ee);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    box-shadow: 3px -3px 10px var(--clr-box-shadow), -3px 3px 10px var(--clr-box-shadow);
    -webkit-box-shadow: 3px -3px 10px var(--clr-box-shadow), -3px 3px 10px var(--clr-box-shadow);
}

.mapfilter-settings .group-popup .content-wrapper {
    max-height: 130px;
    overflow-y: auto;
}
.mapfilter-settings .group-popup .content-wrapper-line {
    background-color: var(--grey-b7);
    height: 2px;
    margin: 8px 0px;
}

.mapfilter-settings .group-popup h5 {
    font: normal normal 600 18px/24px var(--fontArial);
    color: var(--clr-blue);
    text-align: center;
    margin-bottom: 10px;
}

.mapfilter-settings .group-popup label span {
    color: var(--greyc9-32);
}

.mapfilter-settings .group-popup label {
    margin-bottom: 10px;
}

.mapfilter-settings .group-popup .form-actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 15px;
}

.mapfilter-settings .group-popup .form-actions .cancel {
    background-color: initial;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: var(--clr-blue);
    font: normal normal 600 14px/20px var(--fontArial);
    padding: 6px 30px;
    width: auto;
    text-decoration: underline;
}
.mapfilter-settings .group-popup .form-actions .cancel:hover {
    border: 2px solid var(--clr-blue);
    border-radius: 20px;
    text-decoration: none;
}

.mapfilter-settings .group-popup .form-actions .save {
    background-color: var(--clr-red-98);
    border: none;
    border-radius: 20px;
    color: #fff;
    font: normal normal normal 14px/20px var(--fontArial);
    min-width: 120px;
    padding: 8px 40px;
    width: auto;
}

.mapfilter-settings ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.mapfilter-settings ul li:hover button {
    display: block;
}

.mapfilter-settings ul li button {
    background-color: transparent;
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
    padding: 0;
}

.mapfilter-settings ul li button svg {
    padding: 0;
}

.cursor-pointer.select-none {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.collapse-btn-guage {
    position: relative;
    background-color: var(--white-fa);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: none;
    color: var(--white-fa);
    font-size: 12px;
    width: 22px;

    height: 49px;
    top: 4px;
    border-radius: 0px 4px 4px 0px;
}
.collapse-btn-guage svg {
    transform: scale(1.7);
}
.collapse-btn-guage svg path {
    fill: var(--clr-red-98);
}
.collapse-btn-guage:hover svg path {
    fill: var(--clr-blue);
}

.collapse-btn-guage img {
    position: relative;
    width: 12px;
    height: 12px;
}

.collapse-btn-settings {
    position: relative;
    border: none;
    background-color: var(--grey-ee);
    padding: 3px 2px 6px 0px;
    color: var(--blue-8d);
    font-size: 12px;
    border-radius: 0 5px 5px 0;
}

.collapse-btn-settings img {
    height: 10px;
}

.collapsebar-active {
    -webkit-transform: translateX(var(--width-collapseBar));
    -ms-transform: translateX(var(--width-collapseBar));
    transform: translateX(var(--width-collapseBar));
    /* overflow-x: hidden; */
}

.settingsbar-active {
    -webkit-transform: translateX(-152px);
    -ms-transform: translateX(-152px);
    transform: translateX(-152px);
    overflow-x: hidden;
}

.settingsbar-active i,
.collapsebar-active img {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

/* Delete Map Modal */
.delete-modal-wrapper {
    position: relative;
    background-color: var(--grey-ee);
    border-radius: 5px;
    padding: 10px 15px 20px;
    pointer-events: auto;
    min-width: 400px;
}

.delete-modal-wrapper-title {
    font: normal normal 600 20px/24px var(--fontArial);
    color: var(--clr-blue);
    text-align: center;
    margin-bottom: 10px;
}
.delete-modal-wrapper-title.share_eta_title {
    margin-top: -6px;
}

.delete-modal-wrapper .content {
    font: normal normal normal 14px/24px var(--fontArial);
    text-align: left;
    max-width: 375px;
    margin: 8px 0;
}
.delete-modal-wrapper .content p {
    font: normal normal normal 14px/14px var(--fontArial);
    margin: 10px 0 0 0;
}
.delete-modal-wrapper .content.share_eta_content {
    font: normal normal normal 16px/24px var(--fontArial);
}
.delete-modal-wrapper .content .share_bold_title {
    font: 700 16px/16px var(--fontArial);
}

.delete-modal-actions .input-fields input,
.cancel-subscription-modal-wrapper .cancel-input-wrapper input {
    font: normal normal normal 14px/20px var(--fontArial);
    color: var(--clr-blue);
    width: 100%;
    margin: 5px 0;
    padding: 10px;
    background: transparent;
    border: 1px solid var(--grey-99);
    border-radius: 5px;
}

.delete-modal-actions .input-fields .share_eta_input {
    margin: -8px 0 8px 0;
}

.delete-modal-actions .input-fields input:focus,
.cancel-subscription-modal-wrapper .cancel-input-wrapper input:focus,
.cancel-subscription-modal-wrapper .feedback-input-wrapper textarea:focus-visible {
    background-color: var(--blue-d9);
    outline: 2px solid var(--clr-blue);
    border: none;
}

.delete-modal-actions .buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0 20px;
    margin-top: 14px;
}

.deleteMarker-modal-close {
    border: none;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
}

.markerInfo-modal-close {
    width: 22px;
    margin-right: -5px;
    cursor: pointer;
    background-color: transparent;
}

.login-load-outline-btn {
    width: 100%;
    border: 2px solid var(--grey-b7) !important;
    border-radius: 50px;
    /* margin: 30px auto; */
}

.del-text-btn {
    background-color: var(--clr-blue);
    color: var(--red-90);
    font: normal normal normal 16px/24px var(--fontArial);
    border-radius: 20px;
    width: auto;
    color: var(--white-fff);
    padding: 6px 30px;
    -webkit-box-shadow: none;
    box-shadow: none;
    min-height: 40px;
    min-width: 120px;
}
.del-outline-btn {
    background-color: transparent;
    color: var(--red-90);
    font: normal normal normal 16px/24px var(--fontArial);
    border: 2px solid var(--red-90);
    border-radius: 20px;
    width: auto;
    padding: 6px 30px;
    -webkit-box-shadow: none;
    box-shadow: none;
    min-height: 40px;
    min-width: 120px;
}
.del-outline-btn:hover {
    background-color: var(--red-90);
    color: var(--white-fc);
}

.delete-modal-actions .buttons .share-outline-btn {
    background-color: transparent;
    color: var(--clr-red-98);
    font: normal normal normal 16px/24px var(--fontArial);
    border-radius: 20px;
    width: auto;
    border: 2px solid var(--clr-red-98);
    padding: 6px 30px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.delete-modal-actions .buttons .border-cancel-btn {
    background-color: transparent;
    color: var(--clr-blue);
    font: normal normal normal 16px/24px var(--fontArial);
    width: auto;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    min-height: 40px;
    min-width: 120px;
    text-decoration: underline;
}

.delete-modal-actions .buttons .border-cancel-btn:hover {
    border: 2px solid var(--clr-blue);
    border-radius: 20px;
    padding: 6px 30px;
    text-decoration: none;
}

.no-border-cancel-btn {
    background-color: transparent;
    color: var(--clr-blue);
    font: normal normal normal 16px/24px var(--fontArial);
    width: auto;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.cancel-border-btn {
    display: inline-block;
    background-color: transparent;
    color: var(--clr-blue);
    font: normal normal normal 14px/20px var(--fontArial);
    min-width: 120px;
    min-height: 43px;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-decoration: underline;
    vertical-align: middle;
    border: none;
}

.cancel-border-btn:hover {
    border: 2px solid var(--clr-blue);
    border-radius: 50px;
    text-decoration: none;
}

.white-border-btn {
    display: inline-block;
    background-color: transparent;
    color: var(--white-fff);
    font: normal normal 600 16px/24px var(--fontArial);
    min-width: 120px;
    border: 2px solid var(--white-fff);
    padding: 5px 15px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 50px;
}

.grid-table-data-toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.table-toggle-switch {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 35px;
    margin-bottom: 0;
}

.table-toggle-switch input {
    display: none;
}

.table-toggle-switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    border: 1px solid #fff;
}

.table-toggle-switch .slider img {
    width: 30px;
}

.table-toggle-switch .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
}

.table-toggle-switch input:checked + .slider {
    background-color: transparent;
}

.table-toggle-switch input:checked + .slider:before {
    -webkit-transform: translateX(35px);
    -ms-transform: translateX(35px);
    transform: translateX(35px);
}

.gridBtn {
    display: none;
}

.gridBtn {
    color: white;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 25%;
}

.listBtn {
    color: white;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 75%;
}

.table-toggle-switch input:checked + .slider .gridBtn {
    display: block;
}

.table-toggle-switch input:checked + .slider .listBtn {
    display: none;
}

.slider.round {
    border-radius: 34px;
}

/* Custom Toggle Button for grid Tabular View */
.gridTtable-data-toggle {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 8px;
    height: 35px;
    margin-bottom: 0;
    background-color: transparent;
    border: 1px solid var(--grey-99);
    border-radius: 50px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    cursor: pointer;
}

.gridTtable-data-toggle svg {
    height: 26px;
    z-index: 1;
}

#Grid_view-on {
    padding-left: 3px;
}

.gridTtable-data-toggle::before {
    position: absolute;
    content: "";
    height: 34px;
    width: 32px;
    left: 0px;
    bottom: -1px;
    background-color: transparent;
    border-radius: 20px 0 0px 20px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    border: 1px solid var(--clr-red-98);
}

.gridTtable-data-toggle.active::before {
    -webkit-transform: translateX(calc(100% + 6px));
    -ms-transform: translateX(calc(100% + 6px));
    transform: translateX(calc(100% + 6px));
    border-radius: 0 20px 20px 0;
}

.gridTtable-data-toggle svg rect {
    fill: var(--clr-red-98);
}

.gridTtable-data-toggle.active svg path {
    fill: var(--clr-red-98);
}

.gridTtable-data-toggle.active svg rect {
    fill: var(--grey-99);
}

/* company-logo--container */
.company-logo--container {
    position: absolute;
    top: 10px;
    left: 0;
    background-color: rgba(238, 238, 238, 0.2);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

.company-logo--container img {
    width: 200px;
}

.custom-maps-container {
    position: relative;
    background-color: var(--grey-99);
}

.custom-maps-container .title {
    font: normal normal 600 13px/16px var(--fontArial);
    color: var(--white-fc);
    margin: 0;
    margin-bottom: 10px;
}
.custom-maps-container .navigate-btn {
    font: normal normal 600 13px/16px var(--fontArial);
    color: var(--white-fc);
    margin: 0;
    margin-bottom: 10px;
    background-color: transparent;
    border: none;
    text-decoration: underline;
}

.custom-maps-container .progress-wrapper {
    padding: 10px 20px;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.custom-maps-container .progress {
    margin: 0;
}

.incomplete-progress-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 0;
}

.incomplete-progress-info p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font: normal normal 400 12px/14px var(--fontArial);
    color: var(--white-fc);
}

.incomplete-progress-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: var(--grey-d6);
    border-radius: 10px;
    min-height: 8px;
    overflow: hidden;
}

.bg-light-blue {
    background-color: var(--clr-red-98);
}

.error-background {
    background-color: var(--clr-danger) !important;
}

.error-text {
    color: var(--clr-danger) !important;
}

.error-border {
    border: 1px solid var(--clr-danger) !important;
}

.createMap-formgroup .error-text {
    margin: 5px 0 0 18px;
    font-size: 12px;
    display: inline-block;
}

/* Export Map Modal */
.exportMap-modal-wrapper {
    position: relative;
    background-color: var(--grey-ee);
    border-radius: 5px;
    padding: 5px 15px 15px;
    pointer-events: auto;
    min-width: 320px;
}

.exportMap-modal-content .option-wrapper {
    margin-bottom: 20px;
}

.exportMap-modal-content .option-wrapper h4 {
    font: normal normal 600 16px/18px var(--fontArial);
    color: var(--clr-blue);
    margin-bottom: 10px;
    margin-top: 0;
}

.exportMap-modal-content .option-wrapper label {
    margin-bottom: 2px;
}

.exportMap-modal-actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0 20px;
    margin-top: 10px;
}

.radio-form-label-wrap {
    position: relative;
    display: block;
    padding-left: 0;
}

.googleBtnWrapper {
    position: relative;
    overflow: hidden;
}

#googleSignIn > div,
#googleSignIn {
    position: absolute !important;
    inset: 0 !important;
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
}

#googleSignIn > div {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    margin: 0 !important;
    height: 90% !important;
    opacity: 0;
    /* background-color: var(--clr-blue) !important;
    border-radius: 50px; */
}
/* #googleSignIn > div > div {
    background-color: var(--clr-blue) !important;
} */

@media screen and (min-width: 1600px) {
    .loginView--container {
        height: 95vh;
    }
}

@media print {
    html,
    body {
        page-break-after: avoid;
        page-break-before: avoid;

        height: 100vh;
        width: 100%;
        margin: 0 !important;
        padding: 0 !important;
    }
}

/* Billing Componnet */

.billing-main .billing-container {
    background-image: url("../assets/images/Billing/billing-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 1000px; */
}

.billing-main .billing-container .welcome-note {
    padding: 20px;
    margin-left: 40px;
}

.billing-main .billing-container .welcome-note p {
    font: normal normal normal 18px/24px var(--fontArial);
    color: var(--white-fff);
    margin: 0;
}

.billing-main .billing-container .welcome-note h2 {
    font: normal normal 600 30px/36px var(--fontArial);
    color: var(--white-fff);
    margin: 5px 0;
    text-transform: capitalize;
}

.billing-cards-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 30px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 20px;
}

.billing-cards-wrapper .card-wrapper {
    display: block;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    min-width: 300px;
    -webkit-transition: 0.3s ease all;
    -o-transition: 0.3s ease all;
    transition: 0.3s ease all;
}

.billing-cards-wrapper .card-wrapper.individual:hover,
.billing-cards-wrapper .card-wrapper.business:hover,
.billing-cards-wrapper .card-wrapper.enterprise:hover,
.billing-cards-wrapper .card-wrapper.free:hover {
    -webkit-transform: translateY(-25px);
    -ms-transform: translateY(-25px);
    transform: translateY(-25px);
}

.billing-cards-wrapper .card-wrapper .header {
    display: block;
    position: relative;
    text-align: center;
    padding: 15px 10px;
    border-radius: 10px 10px 0 0;
}

.billing-cards-wrapper .card-wrapper.free .header {
    background-color: var(--white-fff);
}

.billing-cards-wrapper .card-wrapper.individual .header {
    background-color: #906067;
}

.billing-cards-wrapper .card-wrapper.business .header {
    background-color: var(--clr-red-98);
}
.billing-cards-wrapper .card-wrapper.enterprise .header {
    background-color: var(--clr-blue);
}

.billing-popular-flag-indicator {
    display: inline-block;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: absolute;
    top: 20px;
    right: -22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* .billing-cards-wrapper .card-wrapper.enterprise .header {
    background: transparent -o-linear-gradient(top, #00ffba 0%, #00b0ff 100%) 0% 0% no-repeat padding-box;
    background: transparent -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#00ffba),
            to(#00b0ff)
        ) 0% 0% no-repeat padding-box;
    background: transparent -o-linear-gradient(top, #00ffba 0%, #00b0ff 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #00ffba 0%, #00b0ff 100%) 0% 0% no-repeat
        padding-box;
} */

.billing-cards-wrapper .card-wrapper .header h4 {
    font: normal normal 600 18px/24px var(--fontArial);
    color: var(--white-fff);
    margin: 0;
    z-index: 1;
    position: relative;
}

.billing-cards-wrapper .card-wrapper .header h6 {
    font: normal normal bold 32px/37px Arial;
    color: var(--white-fc);
    opacity: 0.25;
    text-transform: uppercase;
    position: absolute;
    top: 0px;
    right: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.billing-cards-wrapper .card-wrapper .content {
    display: block;
    background: rgba(255, 255, 255, 0.6);
    padding: 0 15px 15px;
    border-radius: 0 0 10px 10px;
    -webkit-box-shadow: 0px 0px 1px 0px #105d8dcc;
    box-shadow: 0px 0px 1px 0px #105d8dcc;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);

    /* background: #FFFFFF80;
    border: 1px solid #FFFFFF33;
    opacity: 1;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px); */
}

.billing-cards-wrapper .card-wrapper .content .pricing {
    text-align: center;
    position: relative;
}

.billing-cards-wrapper .card-wrapper .content .pricing h5 {
    font: normal normal 600 70px/80px Lato-Regular, sans-serif;
    color: var(--clr-blue);
    margin: 0;
}

.billing-cards-wrapper .card-wrapper .content .pricing h5 span {
    font: normal normal normal 20px/24px var(--fontArial);
    color: var(--greyc-32);
    position: absolute;
    top: 10px;
    left: 85px;
}

.billing-cards-wrapper .card-wrapper .content .pricing p {
    font: normal normal normal 14px/18px var(--fontArial);
    color: var(--clr-blue);
}

.billing-cards-wrapper .card-wrapper .content .pricing p span {
    color: var(--grey-99);
}

.billing-cards-wrapper .card-wrapper .button-wrapper {
    text-align: center;
}

.billing-cards-wrapper .card-wrapper .button-wrapper button {
    display: inline-block;
    background-color: var(--clr-red-98);
    color: var(--white-fff);
    font: normal normal normal 16px/18px var(--fontArial);
    border-radius: 50px;
    width: auto;
    padding: 12px 8px;
    border: none;
    min-width: 150px;
    margin: 5px 0;
    -webkit-box-shadow: -2px 4px 10px #105d8d33;
    box-shadow: -2px 4px 10px #105d8d33;
}

.billing-cards-wrapper .card-wrapper .button-wrapper button.active {
    background-color: transparent;
    border: 1px solid var(--blk-70);
    color: var(--clr-blue);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.billing-cards-wrapper .card-wrapper .button-wrapper button.active:hover {
    display: inline-block;
    background-color: var(--clr-red-98) !important;
    border: 1px solid var(--clr-red-98) !important;
    color: var(--white-fff) !important;
    -webkit-box-shadow: -2px 4px 10px #105d8d33 !important;
    box-shadow: -2px 4px 10px #105d8d33;
}

.billing-cards-wrapper .card-wrapper .content .specification-checklist {
    margin-top: 10px;
    margin-left: 40px;
}

.billing-cards-wrapper .card-wrapper .content .specification-checklist .checklist {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0 10px;
}

.payment-history-wrapper {
    background: #105d8d80;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    padding: 20px 20px 0;
    margin-top: 30px;
}

.payment-history-wrapper h2,
.transaction-history-wrapper h2 {
    color: var(--white-fff);
    font: normal normal normal 20px/24px var(--fontArial);
    margin-bottom: 30px;
}

.payment-cards-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0 20px;
}

.payment-cards-wrapper .payment-card-item {
    position: relative;
    display: block;
    width: var(--billingCard-width);
    height: 100%;
    -webkit-transition: 0.3s ease all;
    -o-transition: 0.3s ease all;
    transition: 0.3s ease all;
    cursor: pointer;
    margin-bottom: -20px;
}

.payment-cards-wrapper .payment-card-item .upSide {
    background-color: var(--grey-ee);
    border-radius: 5px 5px 0 0;
    padding: 8px 13px;
    text-align: left;
}

.payment-cards-wrapper .payment-card-item .upSide img {
    width: 40px;
}

.payment-cards-wrapper .payment-card-item .upSide p {
    color: var(--clr-blue);
    font: normal normal 600 13px/13px var(--fontArial);
    margin-bottom: 0;
}

.payment-cards-wrapper .payment-card-item .upSide span {
    display: inline-block;
    height: 6px;
    width: 6px;
    background-color: var(--grey-99);
    border-radius: 50%;
    margin-right: 2px;
}

.payment-cards-wrapper .payment-card-item .downSide {
    background-color: var(--grey-99);
    border-radius: 0 0 5px 5px;
    padding: 5px 13px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.payment-cards-wrapper .payment-card-item .downSide h6 {
    color: var(--white-fff);
    font: normal normal normal 11px/11px var(--fontArial);
    margin: 0;
    text-transform: uppercase;
}

.payment-cards-wrapper .payment-card-item .downSide p {
    color: var(--white-fff);
    font: normal normal 600 13px/13px var(--fontArial);
    margin-top: -5px;
}

.payment-cards-wrapper .payment-card-item .downSide span {
    font: normal normal normal 6px/15px SourceSansPro-Regular, sans-serif;
}

.new-payment-card-wrapper {
    background-color: var(--grey-ee);
    width: 170px;
    height: 95px;
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 0 5px;
    border: none;
    background-image: url("../assets/images/credit-card-icon.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 100px 0;
}

.new-payment-card-wrapper span {
    color: var(--clr-blue);
    font: normal normal 600 13px/13px var(--fontArial);
}

/* .billing-card-label-wrapper:hover{
    display: ;
} */

.payment-card-item-options {
    margin-top: 5px;
    padding: 4px;
    display: none;
    -webkit-transition: 0.2s ease all;
    -o-transition: 0.2s ease all;
    transition: 0.2s ease all;
}

.payment-cards-wrapper .payment-card-item .payment-card-item-wrapper.border {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
    border-radius: 10px;
    padding: 2px;
    border: 1px solid var(--white-fa);
}

/* .payment-cards-wrapper .payment-card-item .payment-card-item-wrapper:hover.border {
    
} */

.billing-card-label-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0 5px;
    padding: 5px;
}

.payment-cards-wrapper .payment-card-item:hover .payment-card-item-options {
    display: block;
}

.billing-card-label-wrapper span {
    color: var(--white-fff);
    font: normal normal normal 12px/12px var(--fontArial);
    margin-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0 4px;
    cursor: pointer;
}

.billing-card-label-wrapper img {
    width: 15px;
    height: 15px;
}

.billing-card-del-wrapper {
    position: absolute;
    top: -9px;
    right: 10px;
    background: red;
    border-radius: 50%;
    width: 22px;
    height: 22px;
}

.billing-card-del-wrapper svg {
    width: 10px;
    height: 10px;
    fill: var(--white-fff);
}

.payment-card-item .billing-card-del-wrapper button,
.payment-card-item .billing-card-del-wrapper button:hover {
    position: relative;
    background: transparent;
    color: var(--white-fff);
    font: normal normal normal 12px/12px var(--fontArial);
    border: none;
}

.payment-card-item .billing-card-del-wrapper button img {
    width: 10px;
}

.payment-cards-wrapper .payment-card-item .billing-card-del-wrapper:hover button {
    color: var(--clr-red-98);
}

.payment-cards-wrapper .payment-card-item .billing-card-del-wrapper:hover svg {
    fill: var(--clr-red-98) !important;
}

.billing-container .payment-method-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0 20px;
}

.payment-cards-wrapper .primary-card {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
}

.transaction-history-wrapper {
    background-color: var(--blue-8d);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    padding: 20px;
}

.trasnsaction-history-functionality-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background: var(--grey-ee);
    border-radius: 10px 10px 0 0;
    padding: 12px 30px;
    opacity: 0.9;
}

.trasnsaction-history-functionality-bar .searchbar {
    position: relative;
    min-width: 320px;
    margin: auto 0px;
    border: 2px solid var(--grey-99);
    border-radius: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 15px;
}

.trasnsaction-history-functionality-bar .searchbar input {
    font: normal normal normal 14px/20px var(--fontArial);
    color: var(--clr-blue);
    height: 30px;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    caret-color: var(--grey-99);
    display: block;
    -webkit-transition: 0.3s ease all;
    -o-transition: 0.3s ease all;
    transition: 0.3s ease all;
}

.transaction-paginationBar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.transaction-paginationBar label {
    font: normal normal normal 14px/20px var(--fontArial);
    color: var(--clr-blue);
    min-width: 120px;
    text-align: right;
    margin-bottom: 0;
}

.transaction-paginationBar select {
    display: inline-block;
    width: 100%;
    padding: 0 20px 0 10px;
    font: normal normal normal 13px/14px var(--fontArial);
    background: url("/src/assets/images/svg/sortDownGrey.svg");
    background-repeat: no-repeat;
    background-position: right;
    vertical-align: middle;
    background-color: transparent;
    border-radius: 0.55rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    cursor: pointer;
    outline: none;
}

.transaction-table {
    overflow-x: auto;
    border-radius: 0 0 10px 10px;
    opacity: 0.9;
}

.transaction-table table {
    display: table;
    width: 100%;
}

.transaction-table table thead {
    background-color: var(--clr-blue);
    padding: 15px;
}

.transaction-table table thead th {
    color: var(--white-fff);
    font: normal normal normal 14px/14px var(--fontArial);
    padding: 10px 15px;
}

.transaction-table table tbody {
    background-color: var(--grey-ee);
    border-radius: 0 0 10px 10px;
}

.transaction-table table tbody td {
    color: var(--clr-blue);
    font: normal normal normal 14px/14px var(--fontArial);
    padding: 10px 15px;
}

.transaction-table img {
    cursor: pointer;
    min-width: 15px;
}

.stripe-card-credentials-wrapper {
    padding: 30px 0;
}

.stripe-card-credentials-box {
    margin: 0 auto;
    background-color: white;
    border-radius: 4px;
    width: 50%;
    padding: 20px;
    -webkit-transition: 0.3s ease all;
    -o-transition: 0.3s ease all;
    transition: 0.3s ease all;
}

.stripe-card-credentials-box .callToActions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 0 30px;
}

.stripe-card-credentials-box .callToActions button {
    display: inline-block;
    background-color: var(--clr-red-98);
    color: var(--white-fff);
    font: normal normal normal 16px/24px var(--fontArial);
    border-radius: 50px;
    width: auto;
    padding: 8px;
    border: none;
    min-width: 120px;
    margin: 5px 0;
    -webkit-box-shadow: -2px 4px 10px #105d8d33;
    box-shadow: -2px 4px 10px #105d8d33;
}

.StripeElement--webkit-autofill {
    background: transparent !important;
}

.StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
}
.card-cvc-element .input-fields .StripeElement {
    padding: 11px 15px 11px 7px !important;
    border: 1px solid var(--clr-red-98);
    border-radius: 4px;
}

.subscribe-plan-modal-wrapper {
    background-color: var(--grey-ee);
    border-radius: 5px;
    min-width: 320px;
    max-width: 360px;
    padding: 15px;
    pointer-events: auto;
    position: relative;
}

.subscribe-plan-modal-wrapper .content {
    text-align: left;
}

.subscribe-plan-modal-wrapper .buttons button:nth-child(2) {
    width: 300px;
}

/* .subscribe-plan-modal-wrapper .footer-note a {
    font-family: var(--fontArial);
    text-decoration: underline;
} */
.subscribe-plan-modal-wrapper .footer-note a {
    font-family: var(--fontArial);
    text-decoration: underline;
}

.login-forget--wrapper .footer-note a {
    font-family: var(--fontArial);
    text-decoration: underline;
    font: normal normal 600 12px/16px var(--fontArial);
}

.login-forget--wrapper .footer-note p {
    font: normal normal 400 12px/16px var(--fontArial);
}

/* Manage Users module styaling starts */
.manage-users--container {
    margin: 0 25px;
    position: relative;
}

.manage-users--container .header-title {
    font: normal normal 600 22px/26px var(--fontArial);
    color: var(--clr-blue);
    margin: 25px 0px;
}

.manage-users-info-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0px 0 25px;
    -webkit-box-shadow: -2px 1px 5px rgba(0, 0, 0, 0.2), 2px 0px 5px rgba(0, 0, 0, 0.2);
    box-shadow: -2px 1px 5px rgba(0, 0, 0, 0.2), 2px 0px 5px rgba(0, 0, 0, 0.2);
    padding: 10px 20px;
    gap: 20px;
}

.manage-users-info-wrapper .boxes-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.manage-users-info-wrapper .boxes-wrapper div {
    display: inline-block;
    border-radius: 5px;
}

.manage-users-info-wrapper .boxes-wrapper p {
    font: normal normal 600 26px/36px Lato-Regular, sans-serif;
    color: var(--clr-blue);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
}

.manage-users-info-wrapper .boxes-wrapper p span {
    font: normal normal normal 14px/14px Lato-Regular, sans-serif;
}

.manage-users-info-wrapper .plan-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 100px;
}

.manage-users-info-wrapper .plan-wrapper .plan h4 {
    font: normal normal 600 24px/24px var(--fontArial);
    color: var(--clr-blue);
    margin: 0;
}

.manage-users-info-wrapper .plan-wrapper .plan p {
    font: normal normal 600 14px/14px var(--fontArial);
    color: var(--clr-blue);
    margin: 0 0 8px 0;
}

.manage-users-info-wrapper .plan-wrapper .make-call a,
.manage-users-info-wrapper .plan-wrapper .make-call button {
    font: normal normal 600 16px/20px var(--fontArial);
    color: var(--clr-blue);
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    gap: 5px;
}
.manage-users-info-wrapper .plan-wrapper .make-call button:hover {
    text-decoration: underline;
}
.manage-users-info-wrapper .plan-wrapper .make-call button:hover img > svg > path {
    fill: var(--clr-blue);
}

.manageUsers-search-wrapper {
    background-color: transparent;
    padding: 15px 20px;
}

.manageUsers-search-wrapper .manageUsers-search-sec {
    position: relative;
    width: 350px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid var(--greyc9-32);
    border-radius: 20px;
}

.manageUsers-search-sec input {
    border: none;
    color: var(--clr-blue);
    outline: none;
    background-color: transparent;
    font: normal normal normal 15px/18px var(--fontArial);
    height: 35px;
    padding: 0 5px 0 15px;
    width: 100%;
}

.manageUsers-search-sec img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 13px;
}

.invitation-search-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-shadow: -2px 1px 5px rgba(0, 0, 0, 0.2), 2px 0px 5px rgba(0, 0, 0, 0.2);
    box-shadow: -2px 1px 5px rgba(0, 0, 0, 0.2), 2px 0px 5px rgba(0, 0, 0, 0.2);
    margin-bottom: 25px;
    padding: 20px 20px;
}

.invitation-search-header form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.invitation-search-header form .form-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
}

.invitation-search-header form .img-wrapper {
    border-radius: 50px;
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.invitation-search-header .feild-box label {
    color: var(--clr-blue);
    font: normal normal normal 15px/18px var(--fontArial);
    display: block;
}

.invitation-search-header .input-fields-wrapper .feild-box input {
    color: var(--clr-blue);
    border: 1px solid #999b9e;
    border-radius: 5px;
    outline: none;
    background-color: transparent;
    font: normal normal normal 15px/18px var(--fontArial);
    height: 35px;
    padding: 0 5px 0 15px;
    width: 100%;
    max-width: 260px;
}

.manage-users-table--container {
    -webkit-box-shadow: -2px 1px 5px rgba(0, 0, 0, 0.2), 2px 0px 5px rgba(0, 0, 0, 0.2);
    box-shadow: -2px 1px 5px rgba(0, 0, 0, 0.2), 2px 0px 5px rgba(0, 0, 0, 0.2);
}

.manage-users-table--container table thead {
    font: normal normal 600 15px/18px var(--fontArial);
    color: var(--clr-blue);
    border-top: 1px solid var(--grey-d6);
    border-bottom: 1px solid var(--grey-d6);
}

.manage-users-table--container table td {
    vertical-align: middle;
    padding: 15px 20px;
    border: none;
}

.manage-users-table--container table tbody {
    border-radius: 0 0 10px 10px;
}

.manage-users-table--container table tbody tr {
    font: normal normal normal 15px/18px var(--fontArial);
    color: var(--clr-blue);
}
.manage-users-table--container table tbody tr:hover {
    background-color: var(--white-fc);
}

.manage-users-table--container table thead th {
    border: none;
    padding: 15px 20px;
}
.manage-users-table--container table tbody tr:hover .trash-icon svg > path {
    fill: var(--red-90);
}

.manage-users-table--container table tbody tr .user-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px;
    text-transform: capitalize;
}

.manage-users-table--container table tbody tr .user-dp {
    --size: 35px;
    width: var(--size);
    height: var(--size);
    border-radius: 50px;
}

.manage-users-table--container table tbody .form-check {
    display: inline-block;
    padding-right: 15px;
    padding-block: 5px;
}

.manage-users-table--container .pending-invite-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 0 10px;
    background-color: var(--blue-d9);
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    padding: 8px 15px;
    border-radius: 20px;
    font-weight: 600;
    border: none;
    gap: 5px;
}

.manageusers-call-modal--container {
    max-width: 500px;
    background-color: var(--grey-ee);
    border-radius: 5px;
    width: 100%;
    padding: 10px 15px 20px;
    pointer-events: auto;
    position: relative;
}

.manageusers-call-modal--container .content-wrapper p {
    font: 700 15px/17px var(--fontArial);
    color: var(--clr-blue);
}
.manageusers-call-modal--container .content-wrapper .consent-wrapper {
    margin-top: 15px;
}

.manageusers-call-modal--container select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url("../assets/images/svg/chevronDown.svg");
    background-position-x: 97%;
    background-position-y: 52%;
    background-repeat: no-repeat;
    border: 1px solid var(--grey-99);
    -webkit-box-shadow: none;
    box-shadow: none;
    color: var(--clr-blue);
    cursor: pointer;
    font: normal normal normal 13px/18px var(--fontArial);
    height: 40px;
    padding: 0 15px;
}

.manageusers-call-modal--container input {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid var(--grey-99);
}

.manageusers-call-modal--container input:focus {
    background-color: var(--blue-d9);
    border: none;
    outline: 2px solid var(--clr-red-98);
}

.manageusers-call-modal--container input::-webkit-input-placeholder {
    color: rgba(153, 155, 158, 0.8);
    opacity: 1;
}

.manageusers-call-modal--container input::-moz-placeholder {
    color: rgba(153, 155, 158, 0.8);
    opacity: 1;
}

.manageusers-call-modal--container input:-ms-input-placeholder {
    color: rgba(153, 155, 158, 0.8);
    opacity: 1;
}

.manageusers-call-modal--container input::-ms-input-placeholder {
    color: rgba(153, 155, 158, 0.8);
    opacity: 1;
}

.manageusers-call-modal--container input::placeholder {
    color: rgba(153, 155, 158, 0.8);
    opacity: 1;
}

.manageusers-call-modal--container .form-check-label {
    color: var(--clr-blue);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.manageusers-call-modal--container .content-wrapper span {
    font: normal normal normal 13px/15px var(--fontArial);
    color: var(--grey-99);
    margin-bottom: 10px;
}

.custom-toast--wrapper {
    display: block;
    background-color: var(--grey-ee);
    border-radius: 5px;
    -webkit-box-shadow: -2px 1px 5px #105d8d40, 2px 0px 5px #105d8d40;
    box-shadow: -2px 1px 5px #105d8d40, 2px 0px 5px #105d8d40;
    padding: 15px;
    position: fixed;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    bottom: 1rem;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: 0.3s ease all;
    -o-transition: 0.3s ease all;
    transition: 0.3s ease all;
}

.custom-toast--wrapper h5 {
    font: normal normal 600 24px/28px var(--fontArial);
    color: var(--clr-blue);
    text-align: center;
}

.custom-toast--wrapper p {
    font: normal normal normal 15px/17px var(--fontArial);
    color: var(--clr-blue);
}

/* history modal css */
.group-history-deatil-container {
    background-color: rgba(238, 238, 238, 0.9);
    z-index: 999999;
    border-radius: 5px;
    border-radius: 5px;
    padding: 6px 6px 6px 6px;
    margin: 0;
    position: fixed;
    min-width: 90px;
    min-height: 40px;
}
.group-history-deatil-container .group-history-deatil-wrapper .history-modal-title {
    display: flex;
    align-items: center;
}
.group-history-deatil-container .group-history-deatil-wrapper .history-modal-title span,
.group-history-deatil-container .group-history-deatil-wrapper .history-modal-title h5,
.group-history-deatil-container .group-history-deatil-wrapper p {
    margin: 0;
}

.group-history-deatil-container .group-history-deatil-wrapper p {
    font: 400 11px/15px var(--fontArial);
}
.group-history-deatil-container .group-history-deatil-wrapper .history-modal-title span {
    margin-bottom: 3px;
}

.group-history-deatil-container .group-history-deatil-wrapper .history-modal-title h5 {
    font: 700 12px/15px var(--fontArial);
    text-transform: capitalize;
    color: var(--clr-blue);
    margin: 0;
    form: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 13ch;
}

.page_refresh_btn {
    position: absolute;
    z-index: 5;
    left: 2%;
    top: 1.2rem;
}
.page_refresh_btn button {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    width: 130px;
    height: 38px;
    border-radius: 5px;
}

/* font: normal 14px/20px var(--fontArial);
    text-transform: capitalize;
    color: var(--clr-blue);
    margin: 0;
    form: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: var(--mapList-characters); */
/* .group-history-deatil-container .group-history-deatil-wrapper .history-modal-title span {
    margin: 0;
} */
/* history modal css */
