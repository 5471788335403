@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loading-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid var(--grey-d6); /* background color */
    border-top: 5px solid var(--clr-red-98); /* foreground color */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}
.spinner-container {
    height: 100%;
    min-height: 170px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
