.sidebar-title {
    background-color: var(--white-fc);
    /* padding: 21px 14px 21px 24px; */
}
.sidebar-title img {
    width: 95px;
    height: 75px;
}
.sidenav ul {
    list-style: none;
    padding: 0;
    text-align: center;
}
.sidenav ul li {
    list-style: none;
}
.sidenav ul li:hover svg path {
    fill: var(--clr-red-98);
}
.sidenav ul li:hover a {
    color: var(--clr-red-98) !important;
}

.sidenav {
    height: 100vh;
    width: auto;
    position: sticky;
    z-index: 11;
    top: 0;
    left: 0;
    background-color: var(--grey-ee);
}

.notification-circle {
    position: absolute;
    top: 18px;
    left: 38px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: var(--red-90);
    color: var(--white-fff);
    font: 500 10px/10px var(--fontArial);
}
/* .notification-circle {
    position: absolute;
    top: 14px;
    left: 39px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ff5a54;
    background-color: var(--clr-danger);
    color: var(--white-fff);
    font: 500 10px/10px var(--fontArial);
    border: 1px solid var(--white-fc);
} */
/* 
.sidenav ul li a.active::after {
    content: url("../../assets/images/svg/activeVerticalBar.svg");
    position: absolute;
    top: -5px;
    right: -20px;
} */
.sidenav ul li a.active {
    color: var(--clr-red-98);
}

.sidenav ul li a.active svg path {
    fill: var(--clr-red-98);
}

.sidenav-subfirst ul,
.sidenav-subtwo ul:first-child {
    position: relative;
    padding: 10px 5px;
    margin-bottom: 0;
}
.sidenav-subtwo ul:nth-child(2) {
    padding: 10px 5px;
    margin-bottom: 0;
}

.sidenav-subtwo ul:first-child::before {
    content: "";
    position: absolute;
    display: block;
    top: -5px;
    border-bottom: 2px solid var(--grey-d6);
    width: calc(100% - 25px);
    left: calc(100% - 75px);
}
.sidenav-subtwo ul:first-child::after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    border-bottom: 2px solid var(--grey-d6);
    width: calc(100% - 25px);
    left: calc(100% - 75px);
}

.sidenav a {
    font: normal normal normal 12px/16px var(--fontArial);
    color: var(--clr-blue);
    text-decoration: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 6px 0;
}
.sidenav a span {
    max-width: 90px;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.sidenav ul li {
    position: relative;
    padding: 10px 0;
}
.sidebar-menu-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    -webkit-box-shadow: 3px 0px 10px 3px rgba(0, 0, 0, 0.2);
    box-shadow: 3px 0px 10px 3px rgba(0, 0, 0, 0.2);
    overflow: hidden auto;
}

.sidebar-menu-container svg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
}
.sidebar-menu-container .profile img {
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
    width: 30px;
    height: 30px;
}
