.shareMap-modal--container .shareMap-modal-wrapper .share-close-icon {
    text-align: right;
}
.shareMap-modal--container .shareMap-modal-wrapper h6 {
    margin-top: -10px;
}
.shareMap-modal--container .shareMap-modal-wrapper .share-close-icon button {
    color: var(--grey-b7);
    cursor: pointer;
    z-index: 999999;
    border: none;
}

/* .shareMap-modal--container .shareMap-modal-wrapper .share-type-container {
    margin: 0;
} */
.share-type-container .share-type-wrapper .tabsList {
    margin: 0;
    display: flex;
    align-items: center;
    font: 700 14px/10px var(--fontArial);
    gap: 5px;
    list-style: none;
    padding: 0 8px 0 8px;
    box-shadow: 0 10px 10px -10px #105d8d33;
}

.shareMap-modal--container .shareMap-modal-wrapper .tabsList .tabsListItem {
    font: 700 14px/10px var(--fontArial);
    color: var(--grey-99);
    padding: 6px 12px;
    text-transform: capitalize;
    cursor: pointer;
    outline: none;
    transition: 0.1s ease all;
    background: transparent;
    border: none;
}
.share-type-wrapper .tabsList .tabsListItem:is(:hover, :focus) {
    color: var(--clr-red-98);
}
.share-type-wrapper .tabsList .tabsListItem:is(.active, :active) {
    color: var(--clr-red-98);
    border-bottom: 4px solid var(--clr-red-98);
}

.share-type-container .connected-users-data {
    height: 250px;
    max-height: 250px;
    min-height: 175px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.shareMap-user-item-wrapper .custom-label .share-map-btn {
    width: 70px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 0;
    color: var(--clr-blue);
    font: 600 12px/10px var(--fontArial);
    border: 2px solid var(--clr-blue);
    border-radius: 28px;
    letter-spacing: 1px;
    -webkit-box-shadow: -2px 2px 2px #105d8d33;
    box-shadow: -2px 2px 2px #105d8d33;
}
/* .shareMap-user-item-wrapper .custom-label .share-map-btn:hover {
    box-shadow: none;
} */
.shareMap-user-item-wrapper .custom-label .share-map-dots-btn {
    position: absolute;
    right: 0;
    top: 0;
    color: var(--grey-99);
    font: 600 12px/12px var(--fontArial);
    border-radius: 28px;
    letter-spacing: 1px;
    background-color: transparent !important;
    border: 2px solid var(--clr-red-98);
    width: 70px;
    height: 30px;
}
.shareMap-user-item-wrapper .custom-label .moveMarker-map-detail .title {
    font: 700 13px/14px var(--fontArial);
    letter-spacing: 0.5px;
}
.shareMap-user-item-wrapper .custom-label .moveMarker-map-detail .count {
    font: 500 12px/18px var(--fontArial);
}
.shareMap-user-item-wrapper .custom-label .share-disable-btn {
    position: absolute;
    right: 0;
    top: 0;
    color: var(--grey-99);
    font: 700 14px/10px var(--fontArial);
    border-radius: 28px;
    padding: 10px 14px 10px 14px;
}
.shareMap-user-item-wrapper .custom-label .share-newUser-btn {
    position: relative;
    left: 0;
    top: 0;
    color: var(--grey-99);
    font: 700 14px/14px var(--fontArial);
    border-radius: 28px;
    padding: 10px 14px 10px 14px;
}
/* ////////////////////Pagination CSSS */
.bulk-sharing-footer-container .bulk-sharing-wrapper {
    gap: 70px;
}
/* .bulk-sharing-footer-container .bulk-sharing-wrapper  */

.share-type-footer-container {
    border-top: 2px solid var(--grey-b7);
    display: flex;
    justify-content: end;
}
.bulk-sharing-footer-container {
    border-top: 2px solid var(--grey-b7);
    padding: 8px;
}
.share-type-footer-container .share-type-footer-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
}

.share-type-footer-container .share-type-footer-wrapper .btn {
    font-size: 12px;
    color: var(--blk-70);
}
.share-type-footer-container .share-type-footer-wrapper .pagination-indexes p {
    font: 400 12px/12px var(--fontArial);
}

/* /////////////////////bulk Sharing Csss */

.connected-users-data .bulk-sharing-dropzone-wrapper {
    width: auto;
    height: 100%;
    border: 2px dotted var(--grey-99);
    margin: 0 10px;
}

.connected-users-data .bulk-sharing-dropzone-wrapper h2 {
    font: 700 18px/20px var(--fontArial);
    color: var(--clr-blue);
    text-align: center;
}
.connected-users-data .bulk-sharing-dropzone-wrapper p {
    font: 700 11px/8px var(--fontArial);
    color: var(--clr-blue);
    text-align: center;
    padding-top: 10px;
}
.connected-users-data .bulk-sharing-data-wrapper {
    width: auto;
    min-height: 175px;
    max-height: 200px;
    margin-top: -16px;
}
.connected-users-data .bulk-sharing-data-wrapper .title {
    font: 700 14px/14px var(--fontArial);
    padding: 6px;
    background-color: var(--white-fa);
    margin: 4px 0px 0px 0px;
}
.connected-users-data .bulk-sharing-data-wrapper ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.connected-users-data .bulk-sharing-data-wrapper ul {
    padding: 0 15px;
}
.connected-users-data .bulk-sharing-data-wrapper .emailList {
    font: 400 14px/14px var(--fontArial);
    margin: 10px 0;
    letter-spacing: 1px;
}

/* //////////bulk MOdal Css */

.bulk-modal-wrapper {
    position: relative;
    background-color: var(--grey-ee);
    border-radius: 5px;
    padding: 10px 15px 20px;
    pointer-events: auto;
    min-width: 400px;
}

.bulk-modal-wrapper p {
    margin: 0;
    font: 500 17px/24px var(--fontArial);
}

.bulk-modal-wrapper .bulk-modal-wrapper-title {
    font: normal normal 600 20px/24px var(--fontArial);
    color: var(--clr-blue);
    text-align: center;
    margin-bottom: 10px;
}

.bulk-modal-wrapper .content {
    font: 500 15px/24px var(--fontArial);
    margin-top: 13px;
}
.bulk-modal-wrapper .bulk-modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 24px;
}

.bulk-modal-wrapper .share-close-icon {
    text-align: right;
}
.bulk-modal-wrapper h5 {
    margin-top: -10px;
}
.bulk-modal-wrapper .share-close-icon {
    text-align: right;
}

.bulk-modal-wrapper .share-close-icon button {
    color: var(--grey-b7);
    cursor: pointer;
    z-index: 999999;
    border: none;
}

.del-bulk-outline-btn {
    background-color: transparent;
    color: var(--red-90) !important;
    font: normal normal normal 16px/24px var(--fontArial);
    width: auto;
    border: 2px solid var(--red-90) !important;
    padding: 6px 30px;
    -webkit-box-shadow: none;
    border-radius: 50px;
    box-shadow: none;
    min-height: 40px;
    min-width: 120px;
}

/* //////////bulk MOdal Css */
