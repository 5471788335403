.tabsList {
    display: flex;
    align-items: center;
    gap: 1rem;
    list-style: none;
    padding: 0;
    border-bottom: 2px solid #e0e0e0;
}

.tabsListItem {
    padding: 6px 14px;
    text-transform: capitalize;
    cursor: pointer;
    outline: none;
    transition: 0.1s ease all;
    background: transparent;
    color: #000;
    border: none;
    border-bottom: 2px solid transparent;
}
.tabsListItem:is(.active, :hover, :active, :focus) {
    color: var(--clr-red-98);
    border-bottom: 4px solid var(--clr-red-98);
}
