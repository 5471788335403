.notification-container {
    pointer-events: auto;
    width: 100%;
    max-width: 350px;
    position: fixed;
    left: 93px;
    bottom: 45px;
    z-index: 9999999;
    -webkit-box-shadow: 0px 0px 18px var(--clr-box-shadow);
    box-shadow: 0px 0px 18px var(--clr-box-shadow);
}

.notification-container .spinner-container .loading-spinner {
    border: 5px solid var(--white-fff) !important ;
    border-top: 5px solid var(--clr-red-98) !important ;
}
.notification-container .notification-wrapper {
    position: relative;
    display: block;
    background: #eee;
    border-radius: 2px;
    z-index: 2;
    width: 100%;
    -webkit-box-shadow: 0px 0px 18px var(--clr-box-shadow);
    box-shadow: 0px 0px 18px var(--clr-box-shadow);
}

.notification-container .notification-wrapper .notification-header {
    position: relative;
    padding: 5px 10px 7px;
    margin-bottom: 5px;
}
.notification-container .notification-wrapper .notification-header h6 {
    font: normal normal 600 18px/28px var(--fontArial);
    margin-bottom: 5px;
    color: var(--clr-blue);
    text-align: center;
    margin-top: -10px;
}

.notification-container .notification-wrapper .notification-header .notification-search-box input {
    font: normal normal normal 13px/18px var(--fontArial);
    border: 1px solid var(--grey-99);
    color: var(--clr-blue);
    border-radius: 50px;
    outline: none;
    background-color: transparent;
    height: 35px;
    padding: 0 10px;
    width: 90%;
}

.notification-container
    .notification-wrapper
    .notification-header
    .notification-search-box
    input:focus {
    outline: 2px solid var(--clr-red-98);
    background: var(--blue-d9);
    border: none;
}

.notification-container .notification-wrapper .notification-header .share-close-icon {
    text-align: right;
}
.notification-container .notification-wrapper .notification-header .share-close-icon button {
    color: var(--grey-b7);
    cursor: pointer;
    z-index: 999999;
    border: none;
}

.notification-container .notification-wrapper .notification-header .notification-search-box span {
    margin-left: -20px;
    color: var(--grey-99);
}

.notification-container .notification-wrapper .notification-search-box .notification-images {
    margin: 2px;
    position: absolute;
    color: var(--white-fff);
    right: -2px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.notification-container .notification-wrapper .notification-search-box .notification-images img {
    padding: 0px 6px 0px 6px;
}

.notification-search-box .notification-images img:first-child {
    width: 32px;
    border-right: 1px solid var(--grey-ee);
    height: 100%;
}
.notification-search-box .notification-images img:last-child {
    width: 37px;
}

.notification-container .notification-wrapper .notification-search-box input {
    font: normal normal normal 13px/18px var(--fontArial);
    border: none;
    color: var(--white-fff);
    background-color: var(--blue-8d);
    outline: none;
    height: 26px;
    padding: 0 10px;
    width: 90%;
}

.notification-container .notification-wrapper .notification-search-box input:focus {
    border: none;
}
.notification-container .notification-wrapper .notification-search-box input::placeholder {
    color: var(--grey-ee);
}

.notification-container .notification-wrapper .tabsList {
    margin: 0;
    display: flex;
    align-items: center;
    font: 700 14px/10px var(--fontArial);
    gap: 5px;
    list-style: none;
    padding: 0 8px 0 8px;
    box-shadow: 0 10px 10px -10px #105d8d33;
}

.notification-container .notification-wrapper .tabsList .tabsListItem {
    font: 700 14px/10px var(--fontArial);
    color: var(--grey-99);
    padding: 6px 12px;
    text-transform: capitalize;
    cursor: pointer;
    outline: none;
    transition: 0.1s ease all;
    background: transparent;
    border: none;
}
.notification-container .notification-wrapper .tabsList .tabsListItem:is(:hover, :focus) {
    color: var(--clr-red-98);
}
.notification-container .notification-wrapper .tabsList .tabsListItem:is(.active, :active) {
    color: var(--clr-red-98);
    border-bottom: 4px solid var(--clr-red-98);
}

/* ///////////////////////////////Notifications User CSS */
.notification-Loader {
    height: 300px;
    max-height: 300px;
    min-height: 250px;
    background-color: var(--grey-ee);
}
.notifications-user-parent-container {
    overflow: auto;
    height: 300px;
    max-height: 300px;
    min-height: 250px;
    background-color: var(--grey-ee);
}

.notifications-user-parent-container .no-data {
    height: 100%;
    font: 400 16px/20px var(--fontArial);
    color: var(--clr-red-98);
}
.notifications-user-parent-container .spinner-container {
    min-height: 10px !important;
}
.notifications-user-parent-container .spinner-container .loading-spinner {
    width: 25px !important;
    height: 25px !important;
    border: 3px solid var(--white-fff) !important;
    border-top: 3px solid var(--clr-red-98) !important ;
}
.notifications-user-parent-container .notification-user-container {
    background-color: var(--white-fff);
    /* position: relative;
    overflow: visible; */
}

.notifications-user-parent-container .notification-user-container .notification-user-wrapper {
    background-color: var(--grey-ee);

    padding: 10px;
}

.notifications-user-parent-container .notification-user-container .notification-user-wrapper:hover {
    background-color: var(--white-fff);
}

.notification-user-container .notification-user-wrapper .notification-user-img {
    gap: 7px;
}
.notification-user-container .notification-user-wrapper .notification-user-img img {
    --size: 35px;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
}
.notification-user-img .notifcation-user-text-wrapper p {
    font: 600 15px/18px var(--fontArial);
    color: var(--dark-blue);
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: var(--notifications-characters);
}
.notification-user-img .notifcation-user-text-wrapper p span {
    font: 500 15px/14px var(--fontArial);
    margin-left: 5px;
    color: var(--dark-blue);
}
.notification-user-img .notifcation-user-text-wrapper .notifcation-user-time {
    margin-top: 3px;
}
.notification-user-img .notifcation-user-text-wrapper .notifcation-user-time p {
    font: 500 10px/12px var(--fontArial);
    color: var(--blk-70);
}
.notification-user-img .notifcation-user-text-wrapper .notifcation-user-time span {
    font: 500 10px/12px var(--fontArial);
    color: var(--blk-70);
}
.notification-user-container .notification-user-wrapper .notification-threeDot {
    position: relative;
}

.notification-user-container .notification-user-wrapper .notification-threeDot div {
    display: none;
    position: absolute;
    right: 12px;
    top: 16px;
    z-index: 15;
    border-radius: 6px;
    -webkit-box-shadow: 0px -1px 5px -1px var(--greyc80-32);
    box-shadow: 0px -1px 5px -1px var(--greyc80-32);
    background-color: var(--white-fff);
    /* border-top: 4px solid var(--grey-ee);
    border-bottom: 4px solid var(--grey-ee); */
}
.notification-user-container .notification-user-wrapper .notification-threeDot div button {
    font: 500 14px/14px var(--fontArial);
    width: 90px;
    height: 30px;
    color: var(--clr-blue);
}
.notification-user-container .notification-user-wrapper .notification-threeDot div .disableBtn {
    color: var(--grey-d6);
    border: none;
    background-color: transparent;
}
/* .notification-user-container
    .notification-user-wrapper
    .notification-threeDot
    div
    .disableBtn:hover {
    background-color: transparent;
    color: var(--grey-d6);
} */
/* .notification-user-container .notification-user-wrapper .notification-threeDot div button:hover {
    background-color: #d9e9f0;
    color: var(--clr-red-98);
} */
.notification-user-container
    .notification-user-wrapper
    .notification-threeDot
    div
    button:last-child:hover {
    /* background-color: #d9e9f0; */
    color: var(--red-90);
}
.notification-user-container .notification-user-wrapper .notification-threeDot:hover div {
    display: block;
}
/* .notification-user-container
    .notification-user-wrapper
    .notification-threeDot
    div
    button:nth-child(2) {
    color: var(--grey-99);
    border: none;
    background-color: transparent;
} */

/* ///////////////////////////////Notifications User CSS */

/* ////////Notification Footer  */
.notification-footer-container {
    background-color: var(--grey-ee);
    border-top: 2px solid var(--grey-b7);
    display: flex;
    justify-content: end;
}

.notification-footer-container .notification-footer-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
}
.notification-footer-container .notification-footer-wrapper.btn {
    font-size: 12px;
    color: var(--blk-70);
}
.notification-footer-container .notification-footer-wrapper .pagination-indexes p {
    font: 400 12px/12px var(--fontArial);
}

/* .notification-user-wrapper:after {
    content: attr(data-info);
    position: absolute;
    bottom: -1.6em;
    left: 100%;
    padding: 4px 4px 4px 8px;
    color: #222;
    white-space: nowrap;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 4px #222;
    box-shadow: 0px 0px 4px #222;
    background-image: -o-linear-gradient(top, #f8f8f8, #cccccc);
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
} */
/* 
div[data-info] {
    position: relative;
    display: inline-block;
    padding: 10px;
    margin: 10px;
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    cursor: pointer;
} */

/* Tooltip style - initially hidden */
/* .notification-user-container {
    position: relative;
    overflow: visible !important;
} */
.notification-user-container[data-info]:hover:after {
    opacity: 1;
    visibility: visible;
    transition: all 0.1s ease 0.3s;
}

.notification-user-container[data-info]:after {
    content: attr(data-info); /* Uses the content of the 'title' attribute */
    position: absolute;
    top: 0;
    left: 100%;
    transform: translateX(-50%);
    background-color: var(--white-fff);
    color: var(--blk-70);
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    z-index: 999;
}
/* .notification-user-wrapper {
    position: relative;
} */

/* .notification-user-wrapper:hover:after {
    opacity: 1;
    -webkit-transition: all 0.1s ease 0.5s;
    -o-transition: all 0.1s ease 0.5s;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
} */
