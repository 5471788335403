@import "../../../styles/app.css";
.edit-warning-model {
    background-color: var(--grey-ee);
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 350px;

    position: relative;
}
.group-checkbox-field {
    padding: 8px 15px;
    display: flex;
    align-items: center;
    background-color: var(--grey-d6);
    border: 1px solid var(--grey-b7);
}
.group-checkbox-field.pin-data {
    background-color: transparent;
    border: none;
}
label {
    margin: 0 !important;
}
.edit-warning-header {
    display: flex;
    flex-direction: column;
}
.edit-warning-header .warn-heading {
    padding: 15px;
}
.edit-warning-header .warn-heading h2 {
    font: 600 15px/24px var(--fontArial);
    color: var(--blk-85);
    margin: 0;
}
.edit-warning-header .close {
    font-size: 20px;
    cursor: pointer;
}
.edit-warning-body {
    max-height: 180px;
    overflow-y: auto;
    margin-bottom: 15px;
}
.edit-warning-body label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.edit-warning-body input[type="checkbox"] {
    margin-right: 10px;
}
.edit-warning-footer {
    padding: 15px;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    border-top: 1px solid var(--grey-b7);
}
.edit-warning-footer button {
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 20px;
    border: none;
}
.edit-warning-footer .cancel {
    background-color: transparent;
    border: 1px solid var(--grey-b7);
    font: 600 16px/18px var(--blk-85);
}
.edit-warning-footer .save {
    background-color: var(--clr-red-98);
    color: var(--white-fc);
}
