/* ------------------ */
/* SHARED START */
/* ------------------ */
.userprofile-container .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.userprofile-container .form-group select,
.userprofile-container .form-group option,
.userprofile-container .form-group input {
    padding: 10px 15px;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid var(--grey-99);
    border-radius: 5px;
    background-color: transparent;
    color: var(--clr-blue);
}
.userprofile-container .form-group input:disabled {
    color: var(--grey-99);
    border: 1px solid var(--grey-99);
}
.userprofile-container .form-group option,
.userprofile-container .form-group select {
    cursor: pointer;
}

.userprofile-container .form-group-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0.8rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.userprofile-container .form-group-phone {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0.8rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.userprofile-container .form-group-phone input:last-child {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.userprofile-container .form-actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    gap: 1rem;
}

.profile-changePassword-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    border: 1px solid var(--grey-99);
    border-radius: 5px;
    padding: 10px 15px;
    font: normal normal normal 14px/20px var(--fontArial);
    color: var(--clr-blue);
    background-color: transparent;
}

.profile-changePassword-btn img {
    width: 1.2rem;
}

.form-group-checkbox {
    position: relative;
    border: 1px solid var(--grey-99);
    border-radius: 5px;
    padding: 4px 15px;
    padding-right: 25px;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
}

.form-group-checkbox > div {
    border: 1px solid var(--grey-99);
    border-radius: 2rem;
    height: 2rem;
    width: 4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.form-group-checkbox label {
    font-size: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0;
    font: normal normal normal 14px/20px var(--fontArial);
    color: var(--clr-blue);
}

.form-group > .user-profile-info {
    font: normal normal normal 10px/16px var(--fontArial);
    color: var(--clr-blue);
    padding-left: 15px;
    margin-top: 5px;
}
.form-group > .user-profile-info img {
    cursor: pointer;
}

.form-group-checkbox input ~ label {
    width: 3rem;
    height: 4px;
    background-color: #dceffa;
    position: relative;
    cursor: pointer;
    -webkit-transition: 0.3s ease all;
    -o-transition: 0.3s ease all;
    transition: 0.3s ease all;

    -webkit-box-shadow: inset 1px 1px 1px #105d8d27;

    box-shadow: inset 1px 1px 1px #105d8d27;
    border-radius: 5px;
}

.form-group-checkbox input ~ label::after {
    --size: 25px;
    content: "";
    position: absolute;
    left: -1px;
    top: 0;
    bottom: 0;
    width: var(--size);
    height: var(--size);
    -webkit-transform: translate(-20%, -40%);
    -ms-transform: translate(-20%, -40%);
    transform: translate(-20%, -40%);
    border-radius: 50%;
    background-color: #eee;
    -webkit-box-shadow: 1px -1px 5px 0px #c9c9c9;
    box-shadow: 1px -1px 5px 0px #c9c9c9;
    -webkit-transition: 0.3s ease all;
    -o-transition: 0.3s ease all;
    transition: 0.3s ease all;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    background-image: url(../../assets/images/svg/closeIconGrey.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80% 80%;
}
.form-group-checkbox input:checked ~ label {
    background-color: #4bbb57;
}
.form-group-checkbox input:checked ~ label::after {
    left: 100%;
    -webkit-transform: translate(-80%, -40%);
    -ms-transform: translate(-80%, -40%);
    transform: translate(-80%, -40%);
    background-color: #4bbb57;
    background-image: url(../../assets/images/svg/check-small.svg);
}
/* ------------------ */
/* SHARED HEADER START */
/* ------------------ */
.userprofile-content header {
    position: relative;
    margin-bottom: 4rem;
}
.userprofile-content .user-coverimage-container {
    position: relative;
}
.user-coverimage-container figure {
    height: 30vh;
}
.user-coverimage-container .cover-shadow {
    position: absolute;
    width: 100%;
    display: block;
    background: transparent -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#00000000),
            to(#00000080)
        ) 0% 0% no-repeat padding-box;
    background: transparent -o-linear-gradient(top, #00000000 0%, #00000080 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #00000000 0%, #00000080 100%) 0% 0% no-repeat
        padding-box;
    bottom: 0;
    left: 0;
    height: 70px;
    z-index: 0;
}

.user-coverimage-container figure img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}

.user-coverimage-container .cover-image-action {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    z-index: 1;
}
.user-coverimage-container .cover-image-action button {
    font-family: inherit;
    text-decoration: none;
    border: 1px solid var(--white-fff);
    border-radius: 5rem;
    background-color: transparent;
    color: var(--white-fff);
    font-size: 1rem;
    cursor: pointer;
    padding: 0.6rem 1.5rem;
    -webkit-transition: 0.25s;
    -o-transition: 0.25s;
    transition: 0.25s;
}

.profile-preference-tip p {
    font: normal normal normal 10px/16px var(--fontArial);
    color: var(--clr-blue);
}
.profile-preference-tip img {
    max-width: 125px;
    width: 100%;
    height: auto;
}

.userprofile-content .user-profileimage-container {
    position: absolute;
    bottom: 0;
    left: 1rem;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;

    -webkit-transform: translateY(45%);

    -ms-transform: translateY(45%);

    transform: translateY(45%);
}
.user-profileimage-container figure {
    --size: 3.5rem;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid var(--white-fff);
    cursor: pointer;
}
.user-profileimage-container figure img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
.user-profileimage-container h3,
.user-profileimage-container h6 {
    font: normal normal normal 16px/20px var(--fontArial);
    margin: 0;
    text-transform: capitalize;
}
.user-profileimage-container h3 {
    margin-bottom: 20px;
    color: var(--white-fff);
    font: normal normal 600 26px/30px var(--fontArial);
}

/* ------------------ */
/* SHARED HEADER END */
/* ------------------ */

.userSetting-container {
    padding-inline: 1rem;
    margin-bottom: 2rem;
}

.userSetting-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 2rem;
    -ms-flex-wrap: wrap;
    /* flex-wrap: wrap; */
}
.userSetting-content > *:first-child {
    -webkit-box-flex: 2;
    -ms-flex: 2;
    flex: 2;
}

.userSetting-content .userPlan-container {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    color: var(--clr-blue);
}
.userSetting-content .userPlan-container .userPlan-wrap h6 {
    font: normal normal 600 16px/22px var(--fontArial);
}
.userSetting-content .userPlan-container .userPlan-wrap h3 {
    font: normal normal 600 28px/34px var(--fontArial);
}
.userSetting-content .userPlan-container header .subscription-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 1rem;
}

.userSetting-content .userPlan-container header .subscription-wrap .cancel-subscription {
    background: none;
    border: none;
    color: var(--red-90);
}

.userSetting-content .userPlan-container header .subscription-wrap .cancel-subscription:hover {
    text-decoration: underline;
}

/* ------------------ */
/* SHARED END */
/* ------------------ */

/* --------------------------- */
/* CONTACT START */
/* --------------------------- */

.userSetting-content #contact form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    /* flex-wrap: wrap; */
    gap: 1rem;
}

.userSetting-content #contact form > * {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
/* --------------------------- */
/* CONTACT END */
/* --------------------------- */

/* --------------------------- */
/* PREFERENCE START */
/* --------------------------- */
#preference {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 1rem;
}
#preference > * {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
#preference .left {
    display: -ms-grid;
    display: grid;
    grid-gap: 0;
}

#preference .right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 0.2rem;
}

/* --------------------------- */
/* PREFERENCE END */
/* --------------------------- */

/* --------------------------- */
/* USER PROFILE CONTENT START */
/* --------------------------- */
.userPlan-container header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;

    margin-bottom: 1rem;
}
.userPlan-container .user-invites {
    margin-bottom: 2rem;
}
.userPlan-container .user-invites h6 {
    font: normal normal 600 14px/18px var(--fontArial);
}
.user-invites ul {
    padding-left: 0;
}
.user-invites ul li {
    position: relative;
    border-radius: 50%;

    background-color: gray;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: var(--white-fff);
    font-size: 12px;

    --size: 2rem;
    width: var(--size);
    height: var(--size);
}
.user-invites ul li::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;

    --size: 0.5rem;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
}
.user-invites ul li.online::after {
    background-color: var(--clr-danger);
    border: 1px solid #fff;
}
.user-invites ul li.offline::after {
    background-color: var(--clr-success);
    border: 1px solid #fff;
}

.user-invites ul li img {
    --size: 2rem;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    overflow: hidden;
}

.userPlan-container .user-storage h6 {
    margin-bottom: 0.4rem;
}

.user-storage-content {
    display: -ms-grid;
    display: grid;
    grid-gap: 0.5rem;
}
.user-storage .storage-block {
    display: -ms-inline-grid;
    display: inline-grid;
    grid-gap: 1rem;
    -ms-grid-columns: 1fr 1rem 2fr;
    grid-template-columns: 1fr 2fr;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.user-storage .storage-block .left p {
    color: var(--clr-blue);
    font: normal normal 400 14px/22px var(--fontArial);
    margin-bottom: 0;
}
.user-storage .storage-block .left span {
    color: var(--clr-blue);
    font: normal normal 400 12px/18px var(--fontArial);
}
.user-storage .storage-block .right {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.user-storage .storage-block .right div {
    font-size: 0.75rem;
}
.user-storage .storage-block .right progress {
    width: 100%;
}

.user-storage .storage-block .right .progress-bar {
    position: relative;
    background-color: var(--blue-d9);
    height: 5px;
}
.user-storage .storage-block .right .progress-bar span {
    content: "";
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    max-width: 100% !important;
}

.whiteLable-popup--wrapper {
    display: block;
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 75px;
    -webkit-box-shadow: -2px 1px 5px #105d8d40, 2px 0px 5px #105d8d40;
    box-shadow: -2px 1px 5px #105d8d40, 2px 0px 5px #105d8d40;
    max-width: 320px;
    border-radius: 10px;
}
.whiteLable-popup--wrapper .header,
.whiteLable-popup--wrapper .footer {
    background-color: var(--white-fff);
}
.whiteLable-popup--wrapper .header {
    background-color: var(--white-fff);
    border-radius: 10px 10px 0 0;
}
.whiteLable-popup--wrapper .footer {
    padding-top: 10px;
    padding-bottom: 20px;
    border-radius: 0 0 10px 10px;
}
.whiteLable-popup--wrapper .header h6 {
    font: normal normal 600 16px/20px var(--fontArial);
    color: var(--clr-blue);
    margin-top: -10px;
    margin-bottom: 0;
    padding-bottom: 5px;
}
.whiteLable-popup--wrapper .footer p {
    font: normal normal normal 13px/16px var(--fontArial);
    color: var(--clr-blue);
    margin: 0;
}
.whiteLable-popup--wrapper .logo-img {
    height: 175px;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
}
.profileNotification--wrapper .content-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 3rem;
}
.profileNotification--wrapper .image-wrapper img {
    max-width: 500px;
    width: 100%;
}
.profileNotification--wrapper h4 {
    font: normal normal 600 22px/30px var(--fontArial);
    color: var(--clr-blue);
}
.profileNotification--wrapper p {
    font: normal normal normal 15px/20px var(--fontArial);
    margin: 10px 0 15px;
}

.profile-support--wrapper textarea,
.profile-support--wrapper textarea:focus-visible {
    width: 100%;
    background: none;
    border: none;
    resize: none;
    outline: none;
}
.profile-support--wrapper h4 {
    font: normal normal 600 16px/20px var(--fontArial);
}
.profile-support--wrapper p {
    font: normal normal normal 15px/20px var(--fontArial);
    margin: 5px 0;
}
.profile-support--wrapper .support-input-wrapper {
    border: 1px solid var(--grey-99);
    padding: 10px;
}
.profile-support--wrapper .file-upload-wrapper {
    position: relative;
    border-top: 1px solid var(--grey-99);
}
.profile-support--wrapper .file-upload-wrapper .custom-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 1rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 1;
    padding-top: 5px;
}
.profile-support--wrapper .file-upload-wrapper .custom-text p {
    margin: 0;
}

.cancel-subscription-modal-wrapper {
    background-color: var(--grey-ee);
    border-radius: 5px;
    max-width: 550px;
    min-width: 320px;
    padding: 20px;
    pointer-events: auto;
    position: relative;
    max-height: 90%;
    overflow-y: auto;
}
.cancel-subscription-modal-wrapper .title-wrapper h5,
.image-cropper--container .header h5 {
    color: var(--clr-blue);
    font: normal normal 600 20px/24px var(--fontArial);
    margin-bottom: 10px;
    margin-top: 0;
    text-align: center;
}
.cancel-subscription-modal-wrapper .close-wrapper,
.image-cropper--container .close-wrapper {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}
.cancel-subscription-modal-wrapper .para-one {
    font: normal normal normal 16px/20px var(--fontArial);
    color: var(--clr-blue);
    margin-bottom: 10px;
}
.cancel-subscription-modal-wrapper .user-plan-count--list {
    padding-left: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 7px;
}
.cancel-subscription-modal-wrapper .user-plan-count--list li {
    font: normal normal normal 14px/18px var(--fontArial);
    color: var(--clr-blue);
}
.cancel-subscription-modal-wrapper .user-plan-count--list li span {
    text-decoration: underline;
    font-weight: 600;
    padding-right: 10px;
}
.cancel-subscription-modal-wrapper .feedback-input-wrapper textarea {
    resize: none;
    width: 100%;
    font: normal normal normal 14px/20px var(--fontArial);
    color: var(--clr-blue);
    width: 100%;
    margin: 5px 0;
    padding: 10px;
    background: transparent;
    border: 1px solid var(--grey-99);
    border-radius: 5px;
}
.cancel-subscription-modal-wrapper .custom-label {
    margin-bottom: 10px;
}

/* Image crop modal  */
.advanced-cropper {
    background: var(--grey-99) !important;
}
.image-cropper--container {
    background-color: var(--grey-ee);
    border-radius: 5px;
    max-width: 650px;
    min-width: 650px;
    pointer-events: auto;
    position: relative;
    max-height: 90%;
    overflow-y: auto;
}
.image-cropper--container .header {
    padding: 15px 0;
}
.image-cropper--container .image-cropper-wrapper > .advanced-cropper {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 300px;
    overflow: hidden;
}

.image-cropper--container .image-cropper-wrapper > .image-cropper-dropzone {
    height: 310px;
    overflow: hidden;
    background-color: var(--grey-99);
    padding: 30px;
    display: block;
}
.image-cropper--container .image-cropper-wrapper > .image-cropper-dropzone .upload-highlighter {
    text-align: center;
    width: 100%;
    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.image-cropper--container .image-cropper-wrapper > .image-cropper-dropzone .drop-box {
    /* background-color: var(--white-fc); */
    color: var(--clr-blue);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: calc(100% - 20px);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.image-cropper--container .image-cropper-wrapper > .image-cropper-dropzone .drop-box label {
    font: normal normal 600 14px/19px var(--fontArial);
    color: var(--clr-blue);
    padding-inline: 5px;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 0;
}
.uploadMapFiles label {
    font: normal normal 500 17px/19px var(--fontArial);
    color: var(--white-fff);
    padding-inline: 5px;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 0;
}
.image-cropper-dropzone span {
    color: var(--white-fc) !important;
}

.image-cropper-dropzone label {
    font: normal 16px/16px var(--fontArial);
    margin-bottom: 0;
    text-decoration: underline;
    color: var(--white-fc) !important;
    cursor: pointer;
}
.image-cropper--container .image-cropper-wrapper {
    position: relative;
}
.image-cropper--container .functionality-wrapper {
    padding: 15px;
}
.image-cropper--container .functionality-wrapper .action-tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
}
.image-cropper--container .functionality-wrapper .action-tabs button {
    background-color: transparent;
    border: none;
    border-bottom: 5px solid var(--grey-ee);
    font: normal normal normal 14px/20px var(--fontArial);
    color: var(--clr-blue);
    padding-left: 0;
}
.image-cropper--container .functionality-wrapper .action-tabs button img {
    padding-right: 5px;
}
.image-cropper--container .functionality-wrapper .action-tabs button.active {
    border-bottom: 5px solid var(--clr-red-98);
}
.image-cropper--container .functionality-wrapper .settings-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 1rem;
    padding: 20px 0;
}
.image-cropper--container .functionality-wrapper .settings-wrapper .item-wrapper {
    -webkit-box-flex: 1;
    -ms-flex: 1 1;
    flex: 1 1;
}
.image-cropper--container .functionality-wrapper .settings-wrapper .item-wrapper label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.image-cropper--container .functionality-wrapper .settings-wrapper .item-wrapper label span {
    font: normal normal normal 13px/20px var(--fontArial);
    color: var(--clr-blue);
}
.image-cropper--container
    .functionality-wrapper
    .settings-wrapper
    .item-wrapper
    label
    span:first-child {
    font-weight: 600;
}

.image-cropper--container .range-slider,
.image-cropper--container .range-slider:focus-visible {
    -webkit-appearance: none;
    width: 100%;
    height: 12px;
    border-radius: 5px;
    background: var(--blue-d9);
    border: 1px solid var(--grey-99);
    outline: none;
}

.image-cropper--container .range-slider::-webkit-slider-thumb {
    --size: 22px;
    width: var(--size);
    height: var(--size);
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50%;
    background: var(--clr-red-98);
    cursor: pointer;
}

.image-cropper--container .range-slider::-moz-range-thumb {
    --size: 22px;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    background: var(--clr-red-98);
    cursor: pointer;
}
.image-cropper--container .actions-listener-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
}
.danger-underline-cta {
    background-color: transparent;
    border: none;
    color: var(--red-90);
    padding: 5px 15px;
    text-decoration: underline;
    font: normal normal normal 16px/24px var(--fontArial);
    display: inline-block;
}
.danger-underline-cta:hover {
    text-decoration: none;
    border: 2px solid var(--red-90);
    border-radius: 40px;
}
.image-cropper--container .action-items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
}

.image-cropper--container .info-highlighter {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
}
.image-cropper--container .info-highlighter p {
    color: var(--white-fc);
}

.adjustable-image-source {
    display: none;
}
.user-cropper-img-display {
    width: 100%;
    height: 100%;
    max-height: 300px;
    -o-object-fit: cover;
    object-fit: cover;
}
