.fallback-error-container {
    background-image: url("../../assets/images/404-screen.png");
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    gap: 30px;
    padding: 100px;
}
.fallback-error-container h2 {
    font: normal normal 900 66px/70px var(--fontArial);
    color: var(--blue-8d);
    margin: 0;
}
.fallback-error-container h2 span {
    display: inline-block;
    --size: 20px;
    height: var(--size);
    width: var(--size);
    background-color: var(--blue-8d);
    margin-inline: -8px;
    border-radius: 50px;
}
.fallback-error-container h2 span:first-child {
    margin-left: -12px;
}
.fallback-error-container p {
    font: normal normal normal 22px/28px var(--fontArial);
    color: var(--blue-8d);
}
.fallback-error-container a {
    display: inline-block;
    background-color: var(--clr-red-98);
    padding: 10px;
    border-radius: 100px;
    font: normal normal normal 18px/22px var(--fontArial);
    color: var(--white-fff);
    width: 140px;
    text-align: center;
}
