:root {
    --light-blue: #6497ba;
    --clr-red-98: #981029;
    --grey-99: #999b9e;
    --clr-blue: #011c4d;
    --greyc-32: #32373ccc;
    --greyc9-32: #32373c99;
    --greyc80-32: #32373c80;
    --dark-blue: #082836;
    --blk-70: #707070;
    --blk-96: #000000f0;
    --blk-85: #000000cc;
    --clr-danger: #ff0000;
    --clr-warning: #ffc043;
    --clr-warning-dark: #ef6c00;
    --clr-success: #4bbb57;
    --scrollbar-light-blue: #c3d4df;
    --grey-ee: #eeeeee;
    --grey-d6: #d6d7d8;
    --blue-d9: #d9e9f0;
    --grey-b7: #b7b7b7;
    --blue-10: #105d8d40;
    --blue-8d: #105d8d;
    --blue-90: #90b4cd;
    --red-90: #ff0000;
    --red-90-light: #ff0000c4;
    --white-fa: #fafafa;
    --white-fc: #fcfcfc;
    --white-fff: #ffffff;
    --clr-green: #4bbb57;
    --clr-green-light: #4bbb56bb;
    --clr-box-shadow: #105d8d60;
    --width-mapList: 355px;
    --width-mapGroupList: 355px;
    --top-mapGroupList: 135px;
    --width-collapseBar: -355px;
    --mapList-characters: 20ch;
    --billingCard-width: 165px;
    --fontArial: arial, sans-serif;
    --fontArialBold: Arial-Bold, sans-serif;
    --notifications-characters: 30ch;
}
/* RedTheme Root */

html {
    scroll-behavior: smooth;
}

/* -------------------------- */
/* General Typography */
/* -------------------------- */

@font-face {
    font-family: SourceSansPro-Italic;
    src: url("/src/assets/fonts/SourceSansPro-Italic.ttf") format("ttf");
    src: url("/src/assets/fonts/SourceSansPro-Italic.otf") format("otf");
    src: url("/src/assets/fonts/SourceSansPro-Italic.woff") format("woff");
}
@font-face {
    font-family: SourceSansPro-Light;
    src: url("/src/assets/fonts/SourceSansPro-Light.ttf") format("ttf");
    src: url("/src/assets/fonts/SourceSansPro-Light.otf") format("otf");
    src: url("/src/assets/fonts/SourceSansPro-Light.woff") format("woff");
}
@font-face {
    font-family: SourceSansPro-SemiBold;
    src: url("/src/assets/fonts/SourceSansPro-SemiBold.ttf") format("truetype");
    src: url("/src/assets/fonts/SourceSansPro-SemiBold.otf") format("otf");
    src: url("/src/assets/fonts/SourceSansPro-SemiBold.woff") format("woff");
}
@font-face {
    font-family: Lato-Regular;
    src: url("/src/assets/fonts/Lato-Regular.ttf") format("ttf");
    src: url("/src/assets/fonts/Lato-Regular.otf") format("otf");
    src: url("/src/assets/fonts/Lato-Regular.woff") format("woff");
}

h1 {
    font: normal normal normal 36px/42px var(--fontArial);
    margin: 5px 0;
    text-transform: capitalize;
}
h2 {
    font: normal normal normal 30px/34px var(--fontArial);
    font-weight: 300;
    margin: 5px 0;
}
h3 {
    font: normal normal normal 24px/28px var(--fontArial);
    margin: 5px 0;
}
h4 {
    font: normal normal normal 18px/22px var(--fontArial);
    margin: 5px 0;
}
h5 {
    font-size: 14px;
    line-height: 16px;
    font-family: var(--fontArial);
    margin: 5px 0;
}
h6 {
    font: normal normal normal 12px/15px var(--fontArial);
    margin: 5px 0;
}
p {
    font: normal normal normal 14px/22px var(--fontArial);
    color: var(--clr-blue);
    margin: 5px 0;
}

label {
    font: normal normal normal 14px/16px var(--fontArial);
    color: var(--clr-blue);
    margin-bottom: 5px;
    display: inline-block;
}
span,
a {
    font-family: var(--fontArial);
}

input[type="text"],
input[type="textarea"] {
    caret-color: var(--light-blue);
}
.manageusers-call-modal--container input::-webkit-input-placeholder,
input::-webkit-input-placeholder {
    color: rgba(153, 155, 158, 0.8);
    opacity: 1;
}
.manageusers-call-modal--container input::-moz-placeholder,
input::-moz-placeholder {
    color: rgba(153, 155, 158, 0.8);
    opacity: 1;
}
.manageusers-call-modal--container input:-ms-input-placeholder,
input:-ms-input-placeholder {
    color: rgba(153, 155, 158, 0.8);
    opacity: 1;
}
.manageusers-call-modal--container input::-ms-input-placeholder,
input::-ms-input-placeholder {
    color: rgba(153, 155, 158, 0.8);
    opacity: 1;
}
.manageusers-call-modal--container input::placeholder,
input::placeholder,
.userprofile-container .form-group select,
.userprofile-container .form-group option,
.userprofile-container .form-group input::placeholder {
    color: var(--grey-99);
    opacity: 1;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: var(--clr-blue);
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: none;
    padding: 8px;
    font-size: 1rem;
    line-height: 1.5;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
}
.btn-tertiary {
    color: #ffffff !important;
    background-color: var(--light-blue) !important;
}
.btn-darkBlue {
    color: #ffffff !important;
    background-color: var(--light-blue) !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

button:disabled,
button[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
}
/* -------------------------- */
/* Generic Styling */
/* -------------------------- */
.pos-rel {
    position: relative;
}
.pb-10 {
    padding-bottom: 10px;
}
.mx-10 {
    margin: 0 10px;
}
.mb-10 {
    margin-bottom: 10px;
}
.mb-0 {
    margin-bottom: 0px;
}
.mb-15 {
    margin-bottom: 15px;
}
.mb-30 {
    margin-bottom: 30px;
}
.mb-60 {
    margin-bottom: 60px;
}
.m-10 {
    margin: 10px;
}
.mt-10 {
    margin-top: 10px;
}
.mt-20 {
    margin-top: 10px;
}
.mt-15 {
    margin-top: 15px;
}
.mt-20 {
    margin-top: 20px;
}
.width-full {
    width: 100% !important;
}
.width .btn-custom {
    background-color: var(--light-blue) !important;
    color: white !important;
}
.text-blue {
    color: var(--light-blue) !important;
}
.home-sidebar-flex {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.flex-gap-y5 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0 10px;
}
.bg-trans {
    background-color: transparent !important;
    backdrop-filter: none !important;
}
.no-border {
    border: none !important;
}
.no-box-shadow {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
.bg-eee {
    background-color: #eee !important;
}
.text-delete {
    color: #ff5a54 !important;
}
.border-delete {
    border: 1px solid #ff5a54 !important;
}
.pad-btn-custom {
    padding: 7px 25px !important;
}
.border-grey {
    border: 1px solid #32373c4d !important;
}
.visibile-hidden {
    visibility: hidden;
}
.font-white {
    color: var(--white-fff);
}

.filled-dot {
    display: block;
    height: 6px;
    width: 6px;
    background-color: var(--grey-99);
    border-radius: 50px;
    margin: 0 1px;
}

.overflowYunset {
    overflow: unset !important;
}

.cursor-pointer {
    cursor: pointer;
}

.clickable-none {
    pointer-events: none;
}

/* Style the hover title , needs to convert title to data-title */
[data-title]:hover:after {
    opacity: 1;
    -webkit-transition: all 0.1s ease 0.5s;
    -o-transition: all 0.1s ease 0.5s;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}
[data-title]:after {
    content: attr(data-title);
    position: absolute;
    bottom: -1.6em;
    left: 100%;
    padding: 4px 4px 4px 8px;
    color: #222;
    white-space: nowrap;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 4px #222;
    box-shadow: 0px 0px 4px #222;
    background-image: -o-linear-gradient(top, #f8f8f8, #cccccc);
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
}
[data-title] {
    position: relative;
}

@media print {
    .gm-style .gmnoprint {
        display: none !important;
    }
}

/* customized toast style */
.Toastify__toast-theme--colored.Toastify__toast--default {
}
.Toastify__toast-text-custom {
    font: normal normal normal 14px/18px var(--fontArial);
    color: var(--white-fff);
}

.Toastify__toast-container {
    width: auto;
}
.Toastify__toast-theme--light.Toastify__toast--success {
    background: var(--clr-green);
    color: var(--grey-ee);
    border-radius: 12px;
    padding: 0 10px;
}
.Toastify__toast-theme--light.Toastify__toast--warning {
    background: var(--clr-warning);
    color: var(--clr-blue);
    border-radius: 12px;
    padding: 0 10px;
}
.Toastify__toast-theme--light.Toastify__toast--error {
    background: var(--clr-danger);
    color: var(--grey-ee);
    border-radius: 12px;
    padding: 0 10px;
}
.Toastify__close-button {
    opacity: 1;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
}
.Toastify__close-button svg {
    fill: var(--grey-ee);
}
.Toastify__toast-theme--light.Toastify__toast--warning .Toastify__close-button svg {
    fill: var(--clr-blue);
}

.Toastify__toast-body {
    width: 100%;
}

.Toastify__toast-icon {
    width: unset;
}

.custom_warning_box {
    display: flex;
    gap: 0.2rem;
    align-items: center;
}
.custom_warning_box img {
    height: 1.5rem;
    width: 1.5rem;
}
.custom_warning_box button {
    background-color: transparent;
    font: 700 1rem/1rem var(--fontArial);
    color: var(--clr-blue);
    border: none;
    text-decoration: underline;
}
.custom_warning_box button:hover {
    color: var(--light-blue);
}

/* -------------------------- */
/* customize scroll bar */
/* -------------------------- */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
::-webkit-scrollbar-track {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background: rgba(238, 238, 238, 0.8);
    border-radius: 2px;
}
::-webkit-scrollbar-thumb {
    background: var(--grey-99);
    border-radius: 10px;
}
.flex-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.flex-between {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.flex-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

/* -------------------------- */
/* Hiding arrow buttons in input[type: number] */
/* -------------------------- */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

.animate-dots-red .load,
.animate-dots-blue .load {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 5px auto 5px;
}

.animate-dots-red .load div {
    width: 5px;
    height: 5px;
    background-color: var(--red-90);
    border-radius: 50%;
    margin: 0 2px;
    -webkit-animation-name: up-and-down;
    animation-name: up-and-down;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}
.animate-dots-blue .load div {
    width: 5px;
    height: 5px;
    background-color: var(--light-blue);
    border-radius: 50%;
    margin: 0 2px;
    -webkit-animation-name: up-and-down;
    animation-name: up-and-down;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}

.animate-dots-red .load .two,
.animate-dots-blue .load .two {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.animate-dots-red .load .three,
.animate-dots-blue .load .three {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
}

@-webkit-keyframes up-and-down {
    0%,
    100% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }
    50% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
}

@keyframes up-and-down {
    0%,
    100% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }
    50% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
}

/* Google map built-in functionality buttons */
/* full view button */
.gm-fullscreen-control {
    display: none !important;
}

.gm-style .gmnoprint[role="menubar"] {
    left: unset !important;
    right: 50px !important;
    box-shadow: 0px 0px 10px 0px #105d8d80 !important;
}
.gm-style > :nth-child(5) > div:first-child {
    display: none !important;
}

.gm-style-mtc button {
    height: 40px !important;
    font-size: 16px !important;
    background-color: var(--grey-ee) !important;
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px) !important;
}
.gm-style .gmnoprint button {
    color: var(--clr-blue) !important;
    font: normal normal normal 13px/13px arial, sans-serif !important;
}
.gm-style .gmnoprint button[aria-checked="true"] {
    color: var(--clr-red-98) !important;
    font-weight: 400 !important;
}
.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    margin: 10px;
    bottom: unset !important;
    right: 0px;
    /* right: 40px !important;
    display: flex; */
}
.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom .gm-svpc {
    left: unset !important;
    right: 160px !important;
    background-color: var(--grey-ee) !important;
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px) !important;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2) !important;
    border-radius: 5px !important;
}
.gm-style .gmnoprint button[aria-label="Show street map"] {
    border-radius: 5px 0 0 5px !important;
}
.gm-style .gmnoprint button[aria-label="Show satellite imagery"] {
    border-radius: 0 5px 5px 0 !important;
}
.gm-style .gmnoprint {
    border-radius: 5px !important;
    box-shadow: -5px 0 12px rgba(0, 0, 0, 0.2) !important;
}
.gm-style .gmnoprint > div {
    border-radius: 5px !important;
}
.gm-style .gmnoprint .gm-control-active {
    background-color: var(--grey-ee) !important;
}
.gm-style .gmnoprint .gm-control-active:first-child {
    border-radius: 5px 5px 0 0;
}
.gm-style .gmnoprint .gm-control-active:last-child {
    border-radius: 0 0 5px 5px;
    border-top: 1px solid var(--grey-99) !important;
}
.gm-style .gmnoprint .gm-control-active img {
    height: 12px !important;
    width: 12px !important;
}

.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom div.gmnoprint:last-child {
    top: 0 !important;
}

/* hide text data at bottom right bottom of map */
#my_google_map img[alt="Google"],
#my_google_map .gm-style div:last-child .gm-style-cc {
    display: none !important;
}
input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
}
.gm-style-iw .gm-style-iw-chr .gm-style-iw-ch {
    display: none !important;
}
.gm-style-iw .gm-style-iw-chr .gm-ui-hover-effect {
    display: none !important;
    width: none !important;
    height: none !important;
}
