.footer-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 25px;
    padding: 5px 10px 5px 50px;
    width: 100%;
    background-color: var(--grey-ee);
    border: 1px solid var(--grey-99);
    /* background: transparent linear-gradient(180deg, #6197ba 0%, #105d8d 100%) 0% 0% no-repeat
        padding-box; */
    box-shadow: 0px 3px 5px #105d8d66;
    z-index: 2;
}
.footer-container .copyright p {
    display: flex;
    align-items: center;
    color: var(--clr-blue);
    font: normal normal normal 13px/26px var(--fontArial);
    margin: 5px 0;
}
.footer-container .copyright p span {
    padding-right: 10px;
    font-size: 24px;
}
.footer-container .links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 25px;
}
.footer-container .links a {
    color: var(--clr-blue);
    font: 700 13px/26px var(--fontArial);
    text-decoration: none;
}
.footer-container .links a:hover {
    color: var(--clr-red-98);
}
