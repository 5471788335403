.box-model-how-to-conatiner {
    display: flex;
    justify-content: center;
}
.box-model-how-to-wrapper {
    width: 90%;
    height: 90%;
}

.how-to-header-container {
    background-color: var(--clr-blue);
    padding: 6px 18px 10px 20px;
    border-radius: 7px 7px 0px 0px;
}
.how-to-header-container .how-to-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.how-to-header-container .how-to-header-wrapper h3 {
    color: white;
    font: 400 35px / 37px var(--fontArial);
}
.how-to-header-container .how-to-header-wrapper p {
    color: white;
    font: 400 14px / 15px var(--fontArial);
}
.how-to-header-container .how-to-header-wrapper img {
    margin-bottom: 20px;
    cursor: pointer;
}

.box-model-how-to-wrapper .how-to-content-container {
    background-color: rgba(238, 238, 238, 0.8);
    border-radius: 0 0 5px 5px;
    padding: 55px 18px 12px;
    backdrop-filter: blur(5px);
}
.box-model-how-to-wrapper .how-to-content-container .how-to-content-wrapper {
    display: flex;
    gap: 65px;
}

.how-to-content-container .how-to-content-wrapper .how-to-content-left {
    width: 100%;
    max-width: 175px;
}

.how-to-content-container .how-to-content-wrapper .how-to-content-left h4 {
    color: var(--dark-blue);
    font: 700 19px / 23px var(--fontArial);
}

.how-to-content-container .how-to-content-wrapper .how-to-content-left ul {
    list-style-type: none;
    text-decoration: underline;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.how-to-content-container .how-to-content-wrapper .how-to-content-left ul li p {
    color: var(--dark-blue);
    font: 500 15px / 20px var(--fontArial);
    text-decoration: underline;
    cursor: pointer;
}
.how-to-content-container .how-to-content-wrapper .how-to-content-left ul li:hover p {
    color: var(--clr-red-98);
}

.how-to-content-container .how-to-content-wrapper .how-to-content-right {
    display: flex;
    gap: 15px;
    max-height: 500px;
    overflow-y: auto;
}

.video-container .video-wrapper {
    border-radius: 5px;
    background-color: var(--grey-ee);
    box-shadow: -6px 1px 10px -6px #105d8d33, 6px 4px 10px -7px #000;
}
.video-container .video-wrapper .how-to-video {
    background-color: var(--white-fc);
    border-radius: 5px;
}

.video-container .video-wrapper .how-to-video .title {
    border-radius: 0px 0px 5px 5px;
    background-color: white;
    padding: 5px 5px 5px 10px;
    font: 500 13px / 18px var(--fontArial);
}
.video-container .video-wrapper .how-to-video .title a {
    color: var(--dark-blue);
    text-decoration: underline;
}
.video-container .video-wrapper .how-to-video iframe {
    width: 100%;
    height: auto;
}
.video-container .video-wrapper .how-to-video .title:hover a {
    color: var(--clr-red-98);
}

.how-to-content-right .active {
    border: 4px solid var(--clr-red-98);
    border-radius: 5px;
    height: fit-content;
}

@media (max-height: 650px) {
    .how-to-content-container .how-to-content-wrapper .how-to-content-right {
        max-height: 400px;
    }
}
