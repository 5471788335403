.nocdashboard-main {
    position: absolute;
    top: 80px;
    z-index: 4;
    text-align: center;
    display: flex;
    align-items: flex-start;
    transition: all 0.5s ease;
}
.nocdashboard-main .collapse-btn-guage {
    position: relative;
    background-color: var(--grey-ee);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: none;
    color: var(--white-fa);
    font-size: 12px;
    width: 22px;
    height: 43px;
    top: 0;
    border-radius: 0px 4px 4px 0px;
}

.nocdashboard-main .collapse-btn-guage img {
    position: relative;
    width: 12px;
    height: 12px;
}

.nocdashboard-main .collapse-btn-guage img:last-child {
    display: none;
}
.nocdashboard-main .collapse-btn-guage:hover img:first-child {
    display: none;
}

.nocdashboard-main .collapse-btn-guage:hover img:last-child {
    display: block;
}

.nocdashboard-main .collapse-btn-guage:hover img:last-child {
    transform: scale(1.8);
}
.nocdashboard-pane-wrapper {
    background-color: var(--grey-ee);
    box-shadow: 1px 1px 10px 0 #105d8d80;
    width: var(--width-mapList);
}
.noctabsList {
    display: flex;
    align-items: center;
    gap: 1rem;
    list-style: none;
    padding: 0;
    margin-bottom: 0px;
    width: 100%;
    justify-content: center;
}
.noctabsList li {
    width: 100%;
}
.noctabsList li button {
    font: normal 14px/14px var(--fontArial);
    padding: 10px 14px;
    transition: 0.1s ease all;
    width: 100%;
    background: transparent;
    color: var(--grey-99);
    border: none;
    border-bottom: 4px solid transparent;
}
.noctabsList li button:is(.active, :hover, :active, :focus) {
    color: var(--clr-red-98);
    border-bottom: 4px solid var(--clr-red-98);
}
.count-style-one {
    font: normal normal normal 12px/12px var(--fontArial);
    color: var(--clr-blue);
}

.nocdashboard-main .window-pane-title {
    color: var(--clr-blue);
    font: 700 22px/19px var(--fontArial);
    padding: 8px 0px 10px 0px;
    border-bottom: 1px solid var(--grey-d6);
}
.nocdashboard-gauges-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: 0.5s ease all;
    -o-transition: 0.5s ease all;
    transition: 0.5s ease all;
}
.nocList-container {
    top: 165px;
    max-height: 300px;
    position: absolute;
    width: var(--width-mapList);
    overflow: hidden scroll;
    left: 0px;
    transition: all 0.5s ease;
    backdrop-filter: blur(5px);
    background: hsla(0, 0%, 93%, 0.8);
}

.nocList-wrapper {
    padding-left: 0;
    z-index: 4;
    margin-bottom: 0;
}

.nocitem-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    list-style-type: none;
    padding: 7px 10px;
}

.nocitem-wrapper.active,
.nocitem-wrapper:hover {
    background-color: var(--white-fc);
}

.nocitem-wrapper .noc-singlemap-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;
}
.nocitem-wrapper .noc-singlemap-item .content,
.nocitem-wrapper .noc-group-lists-item .content {
    display: flex;
    align-items: center;
    gap: 10px;
}
.nocitem-wrapper .noc-group-lists-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;
    padding: 0px 12px 4px;
}
.nocitem-wrapper .noc-group-lists-item .title {
    font: normal 14px/20px var(--fontArial);
    text-transform: capitalize;
    color: var(--clr-blue);
    margin: 0;
    form: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: var(--mapList-characters);
}

.nocitem-wrapper .noc-group-lists-item .count {
    font: normal 12px/12px var(--fontArial);
    color: var(--grey-99);
    margin: 0;
}
.nocitem-wrapper .noc-group-lists-item .custom-label {
    padding-left: 15px;
}

.nocitem-wrapper .mapimage {
    --size: 35px;
    width: var(--size);
    height: var(--size);
    border-radius: 8px;
}
/* ////////////NocMarkers */
.custom-marker {
    cursor: pointer;
}

.custom-marker .overlay {
    z-index: 2;
    width: auto;
    height: 100%;
    padding: 2px;
    background-color: transparent;
    transition: background-color 0.3s ease;
    border-radius: 20px 8px 8px 20px;
    display: flex;
    gap: 5px;
    justify-content: center;
}

.custom-marker .overlay .groupHoverContainer {
    color: white;
    height: 100%;
}

.custom-marker .overlay .groupHoverContainer .subgroup {
    font: 600px 12px/12px var(--fontArial);
    list-style-type: none;
    margin-right: 5px;
    overflow: visible;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0;
    margin: 3px 5px 3px 2px;
    display: flex;
    justify-content: flex-start;
}
.custom-marker .overlay .groupHoverContainer .subgroup img {
    margin-top: 3px;
    margin-right: 5px;
}
.custom-marker .overlay .groupHoverContainer .subgroup div {
    font: normal normal 600 11px/11px var(--fontArial);
    color: var(--grey-ee);
    margin: 0;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-marker .overlay .pin {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #00cae9;
    transform: rotate(-45deg);
    z-index: 999999;
}

.custom-marker .overlay .pin .countCircle {
    position: absolute;
    top: -4px;
    left: -16px;
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: red;
    color: white;
    font-size: 10px;
    /* text-align: center; */
    line-height: 12px;
}
/* ////////////NocMarkers */
