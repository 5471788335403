/* .loadingAnimation--container * {
    transition: all 0.3s;
} */

.loadingAnimation--container {
    position: relative;
    top: -6px;
    /* background-image: linear-gradient(45deg, #000000 4%, #352f49 100%); */
    background-image: linear-gradient(45deg, #3f3251 2%, #002025 100%);
    /* padding-top: 100px; */
    overflow: hidden;
    margin: 0px;
    width: 100%;
    height: 100vh;
    z-index: -3;
}
.createMap--container .loadingAnimation--container {
    z-index: 1 !important;
}

.vertical-centered-box {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
}
.vertical-centered-box:after {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em;
}
.vertical-centered-box .content {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    font-size: 0;
}

.loader-circle {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 4px rgba(255, 255, 255, 0.1);
    margin-left: -60px;
    margin-top: -60px;
}

.loader-line-mask {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60px;
    height: 120px;
    margin-left: -60px;
    margin-top: -60px;
    overflow: hidden;
    transform-origin: 60px 60px;
    -webkit-mask-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    animation: rotate 1.2s infinite linear;
}
.loader-line-mask .loader-line {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 4px rgba(255, 255, 255, 0.5);
}

#particles-background,
#particles-foreground {
    left: -51%;
    top: -51%;
    width: 202%;
    height: 202%;
    transform: scale3d(0.5, 0.5, 1);
}

#tsparticles {
    position: absolute;
    z-index: -2;
    height: 100% !important;
    width: 100%;
}

#tsparticles .tsparticles-canvas-el {
    height: 100% !important;
}
.animation-text--wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-top: 124px;
}
.animation-text--wrapper .animation_wrapper_details {
}
.animation-text--wrapper .animation_wrapper_details span {
    color: var(--white-fff);
    font: normal normal 600 14px/22px var(--fontArial);
}
.animation-text--wrapper button {
    margin-top: 10px;
    color: var(--white-fff);
    padding: 10px 24px;
    border-radius: 25px;
    background-color: transparent;
    text-decoration: underline;
    position: relative;
    z-index: 100;
}
.animation-text--wrapper button:hover {
    border: 1px solid var(--white-fff);
}
.animation-text--wrapper .uploading_file_name {
    font: 500 13px/13px var(--fontArial);
    color: var(--white-fff);
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes fade {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.25;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
